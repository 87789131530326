import { Index } from '$/lib/typeormExt';

import Permissions           from '$/entities/lib/Permissions';
import { ChargeableProp }    from '$/entities/Package';
import { BaseOrgEntity }     from '$/entities/Organization';
import type { Discount }     from '$/entities/billing/Discount';
import type { Organization } from '$/entities/Organization';
import { Column, BaseEntity, CommonEntity, CollectionName, ManyToOne } from '$/entities/BaseEntity';

export enum ChargeInterval {
	OneTime = 'oneTime',
	Monthly = 'monthly',
	Yearly  = 'yearly',
}

@CommonEntity()
@CollectionName('charge')
@Permissions({
	create : Permissions.serverOnly,
	update : Permissions.serverOnly,
	delete : Permissions.serverOnly,
})
export abstract class BaseCharge extends BaseOrgEntity {

	/**
	 * The chargeable to check organization's package against when calling ensureCharge/ensureChargeArchived
	 */
	static readonly chargeable: ChargeableProp = null;

	/**
	 * Specifies the period for which a charge of this type for the same org & reference that can exist.
	 * Override in subclasses.
	 */
	static readonly interval: ChargeInterval = ChargeInterval.OneTime;

	/**
	 * the stripe plan this charge should be added to
	 */
	@Column({ default : '' })
	@Permissions({ read : Permissions.serverOnly })
	stripePriceId: string = '';

	/**
	 * The date/time at which this charge was synced with Stripe
	 */
	@Column({ nullable : true })
	syncedOn: Date = null;

	@ManyToOne('Discount', { onDelete : 'SET NULL' })
	discount: Discount = undefined;

	/**
	 * The entity that this file is in reference to.
	 * Format: <entity $class>/<entity id>
	 */
	// SHOULDDO: re-factor this with identical code in Comments into a mixin to share between both entities
	@Column()
	@Index()
	reference: string = undefined;
	private referenceEntity: BaseEntity = null;

	setReference(newValue: string | BaseEntity) {
		if (newValue instanceof BaseEntity) {
			this.referenceEntity = newValue;
			if (newValue.isNew) {
				return;
			}

			newValue = newValue.getReferenceString();
		}
		this.reference = newValue as string;
	}

	save(...args) {
		if (this.referenceEntity) {
			if (this.referenceEntity.isNew) {
				throw new Error('cannot save BaseCharge with a new referenced entity.  Save the referenced entity first');
			}
			this.setReference(this.referenceEntity);
		}

		return super.save.apply(this, args);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static async ensureCharge(reference: BaseOrgEntity, { save, org }: {save?: boolean; org?: Organization} = {}): Promise<BaseCharge> {
		throw new Error('not implemented');
	}

}
