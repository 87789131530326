
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"21.118","height":"25.571","viewBox":"0 0 21.118 25.571"}, attrs),
              ...rest,
            },
            children.concat([_c('g',{attrs:{"id":"Group_3104","data-name":"Group 3104","transform":"translate(0.75 0.75)"}},[_c('path',{attrs:{"id":"Path_7484","data-name":"Path 7484","d":"M-8625,7956.411v-15.367l9.945-8.7,9.673,8.7v15.367Z","transform":"translate(8625 -7932.34)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}}),_c('path',{attrs:{"id":"Path_7659","data-name":"Path 7659","d":"M6.983,13.195a1.341,1.341,0,0,0-.333-.956,3.021,3.021,0,0,0-1.132-.656,13.35,13.35,0,0,1-1.377-.6,4.387,4.387,0,0,1-.992-.681A2.737,2.737,0,0,1,2.5,9.4a3.036,3.036,0,0,1-.231-1.238,2.747,2.747,0,0,1,.791-2.029,3.338,3.338,0,0,1,2.1-.923V3.644H6.339V5.233a3.059,3.059,0,0,1,2.029,1.08A3.56,3.56,0,0,1,9.1,8.639H6.983a2.021,2.021,0,0,0-.363-1.315,1.2,1.2,0,0,0-.97-.436,1.234,1.234,0,0,0-.93.341,1.3,1.3,0,0,0-.33.941,1.239,1.239,0,0,0,.322.894,3.551,3.551,0,0,0,1.2.688,13.3,13.3,0,0,1,1.439.663,4.154,4.154,0,0,1,.952.7,2.723,2.723,0,0,1,.593.894A3.072,3.072,0,0,1,9.1,13.18,2.734,2.734,0,0,1,8.323,15.2a3.4,3.4,0,0,1-2.139.916v1.458H5.02v-1.45a3.523,3.523,0,0,1-2.325-1.066,3.435,3.435,0,0,1-.824-2.406H3.988A1.947,1.947,0,0,0,4.4,13.99a1.51,1.51,0,0,0,1.19.465,1.454,1.454,0,0,0,1.018-.341A1.183,1.183,0,0,0,6.983,13.195Z","transform":"translate(3.545 4.07)","fill":"#152951","stroke":"#152951","stroke-width":"0.5"}})])])
          )
        }
      }
    