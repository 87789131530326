import Errors from '$/lib/Errors';
import { escapeSQL, Index, OneToMany, SelectQueryBuilder } from '$/lib/typeormExt';

import { entity }                  from '$/entities/runtimeEntities';
import { Package, PackageFeature } from '$/entities/Package';
import Permissions, { Context }    from '$/entities/lib/Permissions';
import { RolePermission }          from '$/entities/roles/RolePermission';
import { EmailPreferences }        from '$/entities/roles/RolePreferences';
import { RoleAgreements }          from '$/entities/Agreement';
import { StartupActions }          from '$/entities/StartupActions';
import { TypeOfBusiness }          from '$/entities/OrganizationExt';
import { Verification }            from '$/entities/verifications/Verification';
import type { Organization }       from '$/entities/Organization';
import BasePreferences             from '$/entities/lib/BasePreferences';
import type { Preference }         from '$/entities/lib/BasePreferences';
import { getFullName, onlySelfOrSupportRead, ProgressStatus }                              from '$/entities/User';
import type { User, VerificationRequirements, WaitForVerificationStatusUpdateOptions }     from '$/entities/User';
import { BaseEntity, CommonEntity, CollectionName, Column, ManyToOne, Validate, EntityID } from '$/entities/BaseEntity';

export enum ReferralMethod {
	Email    = 'email',
	Facebook = 'facebook',
}

@CommonEntity()
@CollectionName('role')
@Permissions({
	create : Permissions.roleHasPermission(RolePermission.RoleCreate),
	read   : checkReadPermissions,
	update : onlySelfUpdate,
	delete : Permissions.roleHasPermission(RolePermission.RoleDelete),
})
export abstract class BaseRole extends BaseEntity {

	/**
	 * Determines if the role is verified (does not verify the identity of the user behind the role)
	 */
	@Column() @Index()
	@Permissions({ write : Permissions.anyOf(Permissions.serverOnly, Permissions.roleHasPermission(RolePermission.IdentityVerification)) })
	verificationStatus: ProgressStatus = ProgressStatus.Incomplete;

	/**
	 * True if this role is verified AND the identity of the user behind the role is also verified
	 */
	get isVerified() {
		return this.user.isVerified && this.verificationStatus === ProgressStatus.Approved;
	}

	/**
	 * True if this role or user is waiting approval (submitted but not approved)
	 */
	get isWaitingApproval() {
		const completedStates = [ ProgressStatus.Submitted, ProgressStatus.Approved ];
		const roleStatus      = this.verificationStatus;
		const userStatus      = this.user.verificationStatus;
		return this.user.emailVerified
		    && this.isContactInfoComplete
		    && completedStates.includes(roleStatus)
		    && completedStates.includes(userStatus)
			&& [ roleStatus, userStatus ].includes(ProgressStatus.Submitted);
	}

	get isContactInfoComplete() {
		return !this.org.isMemberContactInfoRequired || this.user.isContactInfoComplete;
	}

	get isApproved() {
		return this.user.emailVerified && this.isContactInfoComplete && this.isVerified;
	}

	@ManyToOne('User', { onDelete : 'CASCADE', eager : true })
	@Permissions({ write : Permissions.roleHasPermission(RolePermission.RoleCreate) })
	@Validate({ requiredRelation : true })
	user: User = undefined;

	/**
	 * The Organization to which this role belongs.
	 * Note that not all roles belong to an org so this can easily be null/undefined.
	 */
	@ManyToOne('Organization', { onDelete : 'CASCADE' })
	@Permissions({ write : Permissions.roleHasPermission(RolePermission.RoleCreate) })
	@Validate({ requiredRelation : true })
	org: Organization = undefined;

	/**
	 * Any additional permissions granted to this role. Must be added through the DB.
	 */
	@Column({ type : 'simple-array' })
	@Permissions({
		read  : Permissions.anyOf(Permissions.roleHasPermission(RolePermission.RoleAdditionalPermissionsRead), onlySelfRead),
		write : Permissions.roleHasPermission(RolePermission.RoleAdditionalPermissionsWrite),
	})
	additionalPermissions: RolePermission[] = [];

	@Column({ type : 'json', nullable : true })
	emailPreferences: EmailPreferences = new EmailPreferences();

	@Column({ type : 'json', nullable : true })
	interests: BasePreferences = new BasePreferences();

	@Column({ type : 'json', nullable : true, default : () => "('{}')"  })
	agreements: RoleAgreements = new RoleAgreements();

	@Column({ type : 'json', default : () => "('{}')" })
	startupActions = new StartupActions();

	/**
	 * @deprecated use this.verifications instead
	 */
	@Column({ type : 'json', default : () => "('[]')" })
	@Permissions({ read : Permissions.serverOnly, write : Permissions.serverOnly })
	verificationResults: any[] = [];

	@OneToMany('Verification', 'role', { persistence : false })
	@Permissions({ write : Permissions.serverOnly })
	verifications: Verification[] = undefined;

	@Column()
	@Validate({ required : true, enum : TypeOfBusiness })
	@Permissions({ read : onlySelfOrSupportRead, write : ifRoleNotVerified })
	typeOfRole: TypeOfBusiness = '' as TypeOfBusiness;

	get fullName() {
		return getFullName(this.user);
	}

	get isCanadian() {
		return this.user.isCanadian;
	}

	get isOrgCanadian() {
		return this.org.isCanadian;
	}

	get isLandlord() {
		return this.instanceof('Landlord');
	}

	get isSupport() {
		return this.instanceof('Support');
	}

	get isRenter() {
		return this.instanceof('Renter');
	}

	get isApplicant() {
		return this.instanceof('Applicant');
	}

	get isCollections() {
		return this.instanceof('Collections');
	}

	get isFraudulent() {
		return this.verificationStatus === ProgressStatus.Fraudulent || this.user.verificationStatus === ProgressStatus.Fraudulent;
	}

	get interest() {
		return _.findKey(this.interests, (pref: Preference) => pref === true);
	}

	/**
	 * @returns default role permissions if `onlyDefaultPermissions` is true.
	 * Otherwise, returns all permissions not included in `defaultRolePermissions`.
	 */
	getPermissions({ onlyDefaultPermissions = true } = {}): RolePermission[] {
		const defaultPermissions = _.filter(RolePermission,
			permission => this.hasPermission(permission) && !this.additionalPermissions.includes(permission)
		);

		return onlyDefaultPermissions ? defaultPermissions : _.difference(Object.values(RolePermission), defaultPermissions);
	}

	/**
	 * Returns whether this role has the given role permission.
	 */
	hasPermission(permission: RolePermission): boolean {
		return this.additionalPermissions.includes(permission);
	}

	/**
	 * Returns the status of the given feature for this role.
	 * @returns true if this role has a truthy value for the given feature, returns false otherwise
	 */
	hasFeature(feature: PackageFeature): boolean {
		return this.org.hasFeature(feature);
	}

	hasEmailPreference(preference: keyof EmailPreferences): boolean {
		// undefined => implicit "true" to allow for new emailPreferences not previously recorded
		// null => explicit "don't know" assumed to be true
		// true => explicit "yes" subscribe to this type of email
		return [ undefined, null, true ].includes(this.emailPreferences[preference] as any);
	}

	hasInterest(interest: string): boolean {
		// undefined => implicit "false" primarily for new interests not previously recorded
		// null => explicit "don't know" assumed to be false
		// true => explicit "yes"
		return !!this.interests[interest];
	}

	/**
	 * @returns this role's current package (if any)
	 */
	getCurrentPackage() {
		if ((!this.org && (this as any).orgId) || (!this.org.package && (this.org as any).packageId)) {
			throw new Error('org/org.package relation not loaded');
		}
		return this.org?.package ?? Package.getDefaultFor(this as any);
	}

	/**
	 * @returns a list of all verification requirements (docs or urls) needed for this role (intended to be overridden by subclasses)
	 */
	getVerificationRequirements(roleType?: TypeOfBusiness): VerificationRequirements[] { // eslint-disable-line @typescript-eslint/no-unused-vars
		return [];
	}

	/**
	 * @see User.waitForVerificationStatusUpdate
	 */
	waitForVerificationStatusUpdate(options: WaitForVerificationStatusUpdateOptions) {
		return entity.User.waitForVerificationStatusUpdate(this, options);
	}

	/**
	 * @returns information about the various referral methods and their coupons
	 */
	async getReferralInfo(): Promise<Record<ReferralMethod, ReferralInfo>> {
		throw new Errors.NotImplemented();
	}

	/**
	 * @returns the ID of the BaseRole entity for the current login (if one is defined)
	 */
	static get currentID(): EntityID {
		return '';
	}

	/**
	 * @returns the role of the currently logged in user (if any).
	 */
	static async loadCurrent(): Promise<BaseRole> {
		return undefined;
	}

}

function onlySelfRead(context: Context, role: BaseRole) {
	if (role.id !== context.role.id) {
		return 'insufficient permissions to read other roles';
	}
}

function onlySelfUpdate(context: Context, role: BaseRole) {
	// only allow roles to write their own record
	if (role.id !== context.role.id && !context.role.hasPermission(RolePermission.RoleCrossOrgWrite)) {
		return 'insufficient permissions to update other roles';
	}
}

async function checkReadPermissions(context: Context, role: BaseRole, query: SelectQueryBuilder<BaseRole>) {
	if (context.role.hasPermission(RolePermission.CrossOrgRead)) {
		// do nothing (no additional constrains need to be added)
		return;
	}

	if (query) {
		const alias       = query.expressionMap.mainAlias.name;
		const params: any = {};

		// others are allowed to see their own roles
		let queryString = `${alias}.userId = :userID`;
		params.userID   = context.user.id;

		// also roles in an org are allowed to see other roles in the same org
		queryString  += ` OR ${alias}.orgId = :orgID`;
		params.orgID  = context.org.id;

		// for Landlords, allow access to the renters of the LL organization's
		if (context.role instanceof entity.Landlord) {
			queryString += ` OR ${alias}.id IN (SELECT renterId FROM tenant WHERE tenant.orgId = :orgID)`;	// SHOULDDO PERFORMANCE: convert these to joins instead of subqueries
		}

		// if you're a renter, you can also access members of any org for which you are a tenant
		// or other Renters that share a lease with you through Tenant
		if (context.role instanceof entity.Renter) {
			query.leftJoin(entity.Tenant, 'contextTenant', `contextTenant.renterId = '${escapeSQL(context.role.id)}'`)
			     .leftJoin(entity.Tenant, 'otherTenant',   'otherTenant.leaseId = contextTenant.leaseId');
			queryString += ` OR ${alias}.orgId IN (SELECT orgId FROM tenant WHERE tenant.renterId = '${escapeSQL(context.role.id)}') OR otherTenant.renterId = ${alias}.id`;
		}

		query.andWhere(`(${queryString})`, params);
	}
	else {
		// others are allowed to see their own roles
		if ((role as any).userId === context.user.id) {
			return;
		}

		// also roles in an org are allowed to see other roles in the same org
		if ((role as any).orgId === context.org.id) {
			return;
		}

		// for Landlords, allow access to the Renters of the LL organization's
		if (context.role instanceof entity.Landlord && role instanceof entity.Renter) {
			if (await entity.Tenant.doesExist({ where : { org : context.org, renter : role } })) {
				return;
			}
		}

		if (context.role instanceof entity.Renter && role instanceof entity.Landlord) {
			// if you're a renter, you can also access members of any org for which you are a tenant
			// or other Renters that share a lease with you through Tenant
			if (await entity.Tenant.doesExist({ where : { orgId : (role as any).orgId, renter : context.role } })) {
				return;
			}
		}

		if (context.role instanceof entity.Renter && role instanceof entity.Renter) {
			// if you're a renter, you can also access other renters with whom you share a lease with
			const contextTenants = await entity.Tenant.find({ where : { renter : context.role } });
			const targetTenants  = await entity.Tenant.find({ where : { renter : role } });

			if (contextTenants.some((contextTenant: any) => targetTenants.some((targetTenant: any) => targetTenant.leaseId === contextTenant.leaseId))) {
				return;
			}
		}

		return 'insufficient permissions';
	}
}

function ifRoleNotVerified(this: BaseRole, context: Context): string {
	return context.role.isVerified ? 'cannot edit after role has been verified' : '';
}

export interface CreateReferralResponse {
	discountApplied: boolean;
	url?: string; // social platform url to refer another user on
}

export interface ReferralInfo {
	id: EntityID; 	// couponID
	description: string;
	canApply: boolean;
	hasApplied: boolean;
}
