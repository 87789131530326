import { columns, container, outlinedContent } from '$/lib/pdfmake';
import { TenantCheck }                         from '$/entities/tenantScreening/TenantCheck';

export class TenantCheckPdf {

	sectionTitle(title, svgContent?) {
		if (svgContent) {
			svgContent = columns(
				{
					text             : 'Powered By',
					alignment        : 'right',
					relativePosition : { x : -5, y : 0 },
					style            : {
						bold : true,
					},
				},
				svgContent
			);
		}

		return Object.assign(
			columns(
				{
					text  : _.upperCase(title),
					style : 'title',
				},
				svgContent),
			{ margin : [ 0, 12, 0, 0 ] }
		);
	}

	error(check: TenantCheck) {
		return container(
			{
				title   : 'There was an error retrieving the report:',
				margin  : [ 0, 8 ],
				wrapper : outlinedContent,
			},
			{ text : `${check.label} - ${check.error}`, color : '#ff0000' },
			{ text : 'There will not be a charge for this.', color : '#ff0000' }
		);
	}

}
