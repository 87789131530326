import Errors from '$/lib/Errors';

import { ChildEntity, CommonEntity } from '$/entities/BaseEntity';
import { Verification }              from '$/entities/verifications/Verification';

export { VerificationStatus } from '$/entities/verifications/Verification';

/**
 * Identity verification by visiting a URL and verifying some information.
 */
@ChildEntity() @CommonEntity()
export class UrlVerification extends Verification {

	get additionalData() {
		return this.additionalDataValue as { url: string };
	}

	get url() {
		return this.additionalData?.url ?? '';
	}
	set url(newUrl: string) {
		if (_.isNil(this.additionalDataValue)) {
			this.additionalDataValue = {};
		}
		this.additionalDataValue.url = newUrl;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static async postUrl(url: string) {
		throw new Errors.NotImplemented();
	}

	static getURL(suffix = ''): string {
		return `/api/role/verification/url${_.ensureStartsWith(suffix, '/')}`;
	}

}

