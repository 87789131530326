import Moment    from 'moment';
import { Mixin } from '$/lib/utils';

import { JSONable, Field }          from '$/entities/lib/JSONable';
import type { BaseRole }            from '$/entities/roles/BaseRole';
import type { User }                from '$/entities/User';
import { EntityID, getEntityClass } from '$/entities/BaseEntity';

/**
 * Represent any form of agreement or consent made by an individual within an organization
 * Organization is accessible via role
 */
@Mixin(JSONable)
export class Agreement {

	@Field()
	userId: string = undefined;

	@Field()
	roleId: string = undefined;

	@Field()
	agreed: boolean = undefined;

	@Field()
	date: Date;

	/**
	 * History of changes to the Agreement in descending chronological order.
	 * The first element matches the current state of the Agreement.
	 */
	@Field(Agreement)
	history: Omit<Agreement, 'history'>[];

	constructor(role?: BaseRole | EntityID, user?: User | EntityID, agreed?: boolean, date?: string | Date | Moment.Moment) {
		if (role || !_.isNil(agreed)) {
			this.update(role, user, agreed, Moment(date).toDate());
		}
	}

	update(role?: BaseRole | EntityID, user?: User | EntityID, agreed?: boolean, date?: Date) {
		this.ensureHistory();
		this.roleId = role instanceof getEntityClass('BaseRole') ? role?.id : role;
		this.userId = user instanceof getEntityClass('User') ? user?.id : user;
		this.agreed = agreed;
		this.date   = date ?? new Date();
		this.ensureHistory();
	}

	ensureHistory() {
		if (_.isNil(this.agreed) || _.isNil(this.date)
			|| (this.history?.length > 0 && _.isEqual(_.omit(this.history[0], 'history'), _.omit(this, 'history')))) {
			return;
		}
		this.history ??= [];
		this.history.unshift(_.omit(this, 'history'));
	}

}

@Mixin(JSONable)
export class RoleAgreements {

	@Field()
	eula: Agreement = new Agreement();

	@Field()
	permissionToActAsLandlord: Agreement;

}

// SHOULD DO: add OrganizationAgreements here
