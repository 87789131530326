import { Vue, Component, Inject, Prop }    from '$/lib/vueExt';
import { hasMixin }                        from '$/lib/utils';
import { ValidationMixin }                 from '$/lib/Validate';
import { ValidationIssue, ValidationType } from '$/lib/validation/ValidationIssue';

export interface HasValidMethod {
	isValid(): boolean | Promise<boolean>;
}

export type SetFeedback = (feedbackMessage: string, origin?: Vue, type?: 'valid' | 'invalid' | 'warning') => void;

/**
 * This class is intended to be mixed into Vue widgets that want to participate in the Validation framework.
 */
@Component
export default class VueValidationMixin extends Vue {

	@Prop({ default : null })
	readonly state: boolean;

	@Prop({ default : null })
	readonly validationObject: any;

	@Prop({ default : null })
	readonly validationFieldName: any;

	get computedState() {
		return this.state === null ? this.localState : this.state; // manually overridden state takes precedence over localState
	}

	private unwatch           = null;
	private validationPath    = null;
	private validationField   = null;
	protected validationError = null;

	localState: boolean = null;

	// reports validation feedback up to a possible ancestor
	@Inject({ default : null, from : 'setFeedback' })
	readonly setFeedbackCallback: SetFeedback;

	// registers this form input with a possible ancestor
	@Inject({ default : null })
	readonly registerInput: (formInput: HasValidMethod) => void;

	@Inject({ default : null })
	readonly unregisterInput: (formInput: HasValidMethod) => void;

	mounted() {
		const fragments      = (this.$vnode.data as any).model?.expression?.split('.') || [];
		this.validationField = this.validationFieldName ?? fragments.pop();
		this.validationPath  = fragments.join('.');

		const validationObject = this.localValidationObject;

		if (!this.validationField || !validationObject || !hasMixin(validationObject.constructor, ValidationMixin)) {
			return;
		}

		// watch the v-model expression's field for changes
		const expression = this.$options.model?.prop || 'value';
		this.unwatch     = this.$watch(expression, _.debounce((value, oldValue) => {
			// for object types that are passed by reference, value = oldValue since it might not have been mutated so run validation anyway
			if (value !== oldValue || (value && typeof value === 'object')) {
				void this.isValid();
			}
		}, 200), { deep : true });

		// also register with a possible ancestor form
		this.registerInput?.(this as any);
	}

	/**
	 * Sets the validation feedback message for this component.
	 */
	setFeedback(feedbackMessage: string, type?: 'valid' | 'invalid' | 'warning') {
		this.setFeedbackCallback?.(feedbackMessage, this, type);
		if (!type) {
			type = feedbackMessage ? 'invalid' : 'valid';
		}
		// also need to set the state of this widget
		this.localState = {
			valid   : null,
			invalid : false,
			warning : null,	// warnings should not change the state of the widget
		}[type];
	}

	async isValid() {
		const validationObject                        = this.localValidationObject;
		let validationIssue: string | ValidationIssue = '';

		if (this.validationField && validationObject && hasMixin(validationObject.constructor, ValidationMixin)) {
			validationIssue = (await validationObject.getValidationIssues(this.validationField, { firstErrorOnly : true }))?.shift() || '';
		}

		const message = typeof validationIssue === 'string' ? validationIssue : validationIssue.message;
		const type    = (validationIssue as ValidationIssue)?.type === ValidationType.Warning ? 'warning' : undefined;

		// keep a copy of the last validation error for components that don't use b-form-group's feedback
		this.validationError = message;
		this.setFeedback(message, type);
		return !message || type === ValidationType.Warning;
	}

	async isNotValid() {
		return !(await this.isValid());
	}

	// SHOULDDO: figure out a way to register for this lifecycle event hook more dynamically
	beforeDestroy() {
		this.unregisterInput?.(this);

		if (this.unwatch) {
			this.unwatch();
			this.unwatch = null;
		}
	}

	private get localValidationObject() {
		if (this.validationObject) {
			return this.validationObject;
		}

		return this.validationPath ? _.get(this.$vnode.context, this.validationPath) : this.$vnode.context;
	}

	/**
	* Static version of the isValid method.
	* Helps when calling super.isValid from subclassed widgets since super doesn't seem to work well.
	*/
	static isValid(widget: VueValidationMixin): Promise<boolean> {
		return (VueValidationMixin as any).options.methods.isValid.call(widget);
	}

}
