



















import { Vue, Component, Prop } from '$/lib/vueExt';

type VerificationType = 'idv' | 'full';

/**
 * This component displays a popup with a prompt to Verify Identity
 */
@Component
export default class IdentityVerificationPrompt extends Vue {

	/**
	 * Show redirect to upgrade button only on table view
	 */
	@Prop({ type : Boolean, default : true })
	readonly showButton: boolean;

	@Prop({ type : String as () => VerificationType, default : null })
	readonly verificationType: VerificationType;

	get showIdentityVerification() {
		return [ 'idv', 'full' ].includes(this.verificationType);
	}

	get popoverClass() {
		return this.$role.isWaitingApproval ? 'text-left' : 'text-center';
	}

	get verificationLink() {
		return `/dashboard?verification=true${this.showIdentityVerification ? '&idv=true' : ''}`;
	}

}
