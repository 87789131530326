import { CommonEntity, ChildEntity, Column } from '$/entities/BaseEntity';
import { BaseRole  }                         from '$/entities/roles/BaseRole';
import { RolePermission }                    from '$/entities/roles/RolePermission';
import { LandlordInterests }                 from '$/entities/roles/LandlordInterests';
import { OrgID, TypeOfBusinessLandlord }     from '$/entities/Organization';
import { DocumentType }                      from '$/entities/FileExt';
import { User, ProgressStatus, VerificationRequirements } from '$/entities/User';

export interface EquifaxLandlordRegistrationInfo {
	orgId: string;
	name: string;
	address: string;
	phoneNumber: string;
	location: string;
}

@ChildEntity() @CommonEntity()
export class Landlord extends BaseRole {

	@Column({ type : 'json', default : () => "('{}')", nullable : false })
	interests: LandlordInterests = new LandlordInterests();

	constructor() {
		super();
		if (this.isNew) {
			this.emailPreferences.LandlordMonthlyEmail = true;
		}
	}

	hasPermission(permission: RolePermission): boolean {
		switch (permission) {
			case RolePermission.LeaseWrite:
			case RolePermission.OrgView:
			case RolePermission.OrgManageInvitations:
				return true;
			case RolePermission.RentReportingToggleWrite:
			case RolePermission.EnterpriseThresholdWrite:
			case RolePermission.ConnectionSettingsWrite:
				// TriCon landlords can't change these settings as per request by TriCon
				// SHOULDDO: move this to a Landlord subclass that has fewer permissions
				return (this as any).orgId !== OrgID.Tricon;
		}
		return super.hasPermission(permission);
	}

	getVerificationRequirements(roleType?: TypeOfBusinessLandlord): VerificationRequirements[] {
		const results = super.getVerificationRequirements();

		if (this.verificationStatus === ProgressStatus.Approved) {
			return results;
		}

		const urlTitle = 'Please provide a link to your website, LinkedIn profile or similar resource showing your picture';

		return results.concat({
			[TypeOfBusinessLandlord.Landlord] : [
				{
					referenceType : 'role.landlord',
					type          : 'document',
					docType       : DocumentType.BuildingOwnership,
					title         : 'Proof of ownership of at least 1 rental property',
					desc          : '(Must be property tax record, insurance policy, OR title document for the rental property in your name)',
				},
				{
					referenceType : 'role.landlord',
					type          : 'document',
					docType       : DocumentType.OrgCompanyProof,
					title         : `
						If the property is owned by a company, provide documentation showing you are a director or employee of that company
						`.trim(),
					optional : true,
				},
			],
			[TypeOfBusinessLandlord.PropertyManager] : [
				{
					referenceType : 'role.propertyManager',
					type          : 'document',
					docType       : DocumentType.PMManagementAgreement,
					title         : 'Copy of your property management agreement template',
				},
				{
					referenceType : 'role.propertyManager',
					type          : 'url',
					title         : urlTitle,
				},
			],
			[TypeOfBusinessLandlord.RealtorOrLeasingAgent] : [
				{
					referenceType : 'role.realtorOrLeasingAgent',
					type          : 'document',
					docType       : DocumentType.RealtorOrLeasingAgentProof,
					title         : `
						Copy of your real estate license, brokerage license, executed property management agreement
						or agreement to screen applicants for your clients.`.trim(),
				},
				{
					referenceType : 'role.realtorOrLeasingAgent',
					type          : 'url',
					title         : urlTitle,
					optional      : true,
				},
			],
			[TypeOfBusinessLandlord.LawyerOrParalegal] : [
				{
					referenceType : 'role.lawyerOrParalegal',
					type          : 'url',
					title         : urlTitle,
				},
			],
			[TypeOfBusinessLandlord.Advisor] : [
				{
					referenceType : 'role.advisor',
					type          : 'document',
					docType       : DocumentType.AdvisorLicenseProof,
					title         : 'Copy of your agreement, license or similar resource whereby you are able to act under agency.',
				},
				{
					referenceType : 'role.advisor',
					type          : 'url',
					title         : urlTitle,
					optional      : true,
				},
			],
		}[roleType] as VerificationRequirements[] ?? []);
	}

	async getEquifaxRegistrationInfo(): Promise<EquifaxLandlordRegistrationInfo> {
		await this.loadRelation('org', { required : true });

		return {
			orgId       : this.org.id,
			name        : this.org.name || this.fullName,
			address     : this.user.address.format(),
			phoneNumber : this.user.phoneNumber,
			location    : _.compact([ this.org.province,  this.org.country ]).join(',')
			           || _.compact([ this.user.province, this.user.country ]).join(', '),
		};
	}

	static getSample<T extends typeof Landlord>(this: T): InstanceType<T> {
		const sample         = new this() as InstanceType<T>;
		sample.user          = User.getSample();
		sample.verifications = [];
		return sample;
	}

}
