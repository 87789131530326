import Moment                           from 'moment';
import { Country }                      from '$/lib/Address';
import Errors                           from '$/lib/Errors';
import { AccountStatus, Metro2Segment } from '$/lib/Metro2';
import { SelectQueryBuilder }           from '$/lib/typeormExt';

import Permissions, { Context } from '$/entities/lib/Permissions';
import { RolePermission }       from '$/entities/roles/RolePermission';
import { Column, ManyToOne }    from '$/entities/BaseEntity';
import { LeaseBalance }         from '$/entities/LeaseBalance';
import type { Tenant }          from '$/entities/Tenant';
import { BaseEntity, CollectionName, CommonEntity, getEntityClass } from '$/entities/BaseEntity';

export enum CreditBureau {
	EquifaxCA  = 'equifaxCA',
	EquifaxUS  = 'equifaxUS',
	LcbCA      = 'lcbCA',
	LcbUS      = 'lcbUS',
	ExperianUS = 'experianUS',
}

export enum Metro2Status {
	Uploaded = 'uploaded',    // Metro2Report sent to all credit bureaus.
	Success  = 'success',     // processing succeeded
	Failure  = 'failure',     // processing throw an unexpected error (reason field contains the error)
	Skipped  = 'skipped',     // tenant report could not be generated (reason field contains more info)
}

export enum ReportingGroup {
	Tradeline  = 'tradeline',
	Collection = 'collection',
}

export enum Metro2Reasons {
	FirstMonth                  = 'lease did not exist last month',
	FutureTenant                = 'tenant was not on lease last month',
	NoConsent                   = 'renter has not consented to be reported',
	Archived                    = 'tenant is archived',
	Excluded                    = 'tenant is excluded',
	Old                         = "tenant's lease's end date is too old",
	Suspended                   = 'landlord organization is suspended',
	Inactive                    = 'landlord organization is inactive',
	NotVerified                 = 'landlord organization is not verified',
	Arrears                     = 'landlord organization is not in good standing',
	Country                     = 'tenant not in supported country',
	MissingField                = 'tenant record is missing date of birth',
	EquifaxDisabled             = "the current lease does not have the 'Rent Reporting' toggle enabled",
	DebtReportingDisabled       = "the former lease does not have the 'Debt Reporting' toggle enabled",
	EquifaxFeature              = "neither landlord org nor the renter have the 'Rent Reporting' feature",
	DebtReportingFeature        = "landlord org does not have the 'Debt Reporting' feature",
	RenterIdentityNotVerified   = 'renter has signed up but not verified their identity',
	RenterSuspended             = 'renter is suspended',
	WithdrawnConsent            = 'renter has withdrawn consent',
	NotReporting                = 'not reporting',
	DoNotEngage                 = 'tenant is in the "do not engage" list',
	PlacedInError               = 'collections was placed in error',
	DataContributorAgreement    = 'landlord has not yet agreed to the latest data contributor agreement',
	PositiveReportingOnly       = 'landlord organization is configured to only report positive information to credit bureaus',
	CommercialTenant            = 'reporting of commercial tenants is not currently supported',
	GracePeriod                 = 'tenant is in the grace period before debts can be reported',
	MissingData                 = 'no lease balance amount was entered this month',
	SubmittedToCollections      = 'tenant has been submitted to an external collections agency',
	CannotReopen                = 'cannot reopen reporting once it has been reported as closed',
	ReportingGroupNotApplicable = 'reporting group is not applicable for this tenant',
	NullBalance                 = 'a value was not provided for the balance this month',
	BalanceNotUpdated           = 'the balance has not been updated for several months in a row',
	InvalidAddress              = 'the building does not have a valid address',
	MinimumAmount               = 'small amounts of debt cannot be reported to this bureau',
	ReportingAfterFinalized     = "reporting was not enabled until after this month's data was finalized",
	MissingDocuments            = 'documents required for reporting are missing',
	LeaseBecameFormer           = 'the lease has become a former lease, activate debt reporting to continue reporting',
	CaliforniaPausedReporting   = 'tenant reporting is paused for 6 months after tenant opts out',
	// legacy reasons. DO NOT USE
	CollectEnabled              = "the former lease does not have the 'Collect' toggle enabled",
	CollectFeature              = "landlord org does not have the 'ReportToCollections' feature",
}

function getHumanReadableReason(reason: AccountStatus | Metro2Reasons | string): { status: string; tooltip?: string } {
	switch (reason) {
		case AccountStatus.SeriouslyPastDue:
		case AccountStatus.PastDue180Days  :
		case AccountStatus.PastDue150Days  :
		case AccountStatus.PastDue120Days  :
		case AccountStatus.PastDue90Days   :
		case AccountStatus.PastDue60Days   :
		case AccountStatus.PastDue30Days   :
			return {
				status  : 'Reported Debt Owed',
				tooltip : 'A debt has been recorded for the indicated month and it has been reported to Credit Bureaus.',
			};
		case AccountStatus.PaidOrClosedAccount:
			return {
				status  : 'Reported and Closed',
				tooltip : 'Rent for the indicated month has been reported as paid in full to Credit Bureaus and the tradeline was successfully closed. The account for this tenant is now no longer active and will show no outstanding balance.',
			};
		case Metro2Reasons.Archived:
		case AccountStatus.DeleteEntireAccount:
			return {
				status  : 'Renter Has Been Deleted',
				tooltip : "The Tenants' entire record with the Credit Bureaus has been successfully deleted.",
			};
		case AccountStatus.AccountInGoodStanding    : return {
			status  : 'Reported In Good Standing',
			tooltip : 'Rent for the indicated month has been reported as paid in full to Credit Bureaus.',
		};
		case Metro2Reasons.FirstMonth :
			return {
				status  : 'Renter Did Not Exist Last Month',
				tooltip : 'This record did not exist in your Lease Records for the indicated month, therefore it has not been reported to Credit Bureaus. Please ensure Rent Reporting or Debt Reporting remains on and all relevant fields are filled out so next month the reporting can occur.',
			};
		case Metro2Reasons.FutureTenant :
			return {
				status  : 'Renter Was Not On Lease Last Month',
				tooltip : 'Tenant was not active in the indicated month, therefore it has not been reported to Credit Bureaus. Please ensure Rent Reporting or Debt Reporting remains on and all relevant fields are filled out so next month the reporting can occur.',
			};
		case Metro2Reasons.NoConsent:
		case Metro2Reasons.DoNotEngage:
		case Metro2Reasons.RenterIdentityNotVerified:
			return {
				status  : 'Renter Did Not Consent',
				tooltip : 'If there is not a debt owed, the tenant must opt-in by logging in and clicking the consent box in their Lease Record. Therefore it has not been reported to Credit Bureaus for the indicated month. Remind your Tenant they need to opt-in for the benefits by providing consent on their Lease Record.',
			};
		case Metro2Reasons.Excluded :
			return {
				status  : 'Renter Has Been Excluded',
				tooltip : 'The Tenants has been excluded from reporting. Any prior reporting record with Credit Bureaus is deleted.',
			};
		case Metro2Reasons.Old:
			return {
				status  : 'Tenancy Date is Too Old',
				tooltip : 'No information was shared with Credit Bureaus because rental information is older than 6 years in Canada or 7 years in the US. If this is a mistake, please update the move out date on the Lease Record.',
			};
		case Metro2Reasons.Suspended :
		case Metro2Reasons.Inactive :
			return {
				status  : 'Landlord Account Inactive',
				tooltip : 'No information was shared with Credit Bureaus as the Landlord had not logged in for 60 days to keep their account active. If you get this message please make sure you are logging into your account at least every 60 days so Rent Reporting can occur successfully.',
			};
		case Metro2Reasons.NotVerified :
			return {
				status  : 'Landlord Account Not Verified',
				tooltip : 'No information was reported to Credit Bureaus because you have not completed ID or role verification. To complete your ID verification, click on Complete My Profile under the actions required on your dashboard page. Please follow the steps to verify your ID and role in FrontLobby.',
			};
		case Metro2Reasons.Country :
			return {
				status : 'Unsupported Country',
			};
		case Metro2Reasons.MissingField :
			return {
				status  : 'Missing Date of Birth, SSN or SIN',
				tooltip : 'Rent for the indicated month was not reported to Credit Bureaus because there is no date of birth, SIN or SSN for the Tenant. To fix this, click on the Tenants\' name under the My Leases tab. Add the date of birth, SIN or SSN to their record and click save. Alternatively, you can have the Tenant sign in, complete their profile and add these details themselves to benefit from rent reporting.',
			};
		case Metro2Reasons.EquifaxDisabled :
		case Metro2Reasons.EquifaxFeature :
			return {
				status  : 'Credit Reporting Disabled',
				tooltip : 'The Rent Reporting feature is not turned on, therefore it has not been reported to Credit Bureaus. To turn this feature on, please find their Lease Record under the My Leases tab and turn on the Rent Reporting toggle.',
			};
		case Metro2Reasons.DebtReportingDisabled :
		case Metro2Reasons.DebtReportingFeature :
		case Metro2Reasons.CollectEnabled :	// Legacy Reasons DO NO USE
		case Metro2Reasons.CollectFeature :// Legacy Reasons DO NO USE
			return {
				status  : 'Debt Reporting Feature Disabled',
				tooltip : 'The Debt Reporting feature is not turned on, therefore it has not been reported to Credit Bureaus. To turn this feature on, please find their Lease Record under the My Leases tab and turn on the Debt Reporting toggle.',
			};
		case Metro2Reasons.RenterSuspended :
			return {
				status  : 'Renter Account Suspended',
				tooltip : 'No information was reported to Credit Bureaus because the Tenants\' account has been suspended. If you receive this message you may reach out to support for more details.',
			};
		case Metro2Reasons.WithdrawnConsent :
			return {
				status  : 'Renter Withdrew Consent',
				tooltip : 'If there is not a debt owed, the tenant must opt-in by logging in and clicking the consent box in their Lease Record. Therefore it has not been reported to Credit Bureaus for the indicated month. Remind your Tenant they need to opt-in for the benefits by providing consent on their Lease Record.',
			};
		case Metro2Reasons.CommercialTenant :
			return {
				status  : 'Commercial Tenant Not Supported',
				tooltip : 'Reporting of Commercial tenants to credit bureaus is not currently supported.',
			};
		case Metro2Reasons.DataContributorAgreement:
			return {
				status  : 'Data Contributor Not Agreed',
				tooltip : 'No information was shared with Credit Bureaus because the Landlord has not yet agreed to the latest data contributor agreement.',
			};
		case Metro2Reasons.CaliforniaPausedReporting:
			return {
				status  : 'Not Reported (Pending)',
				tooltip : 'When a tenant opts out of reporting, California Bill 2747 requires Rent Reporting to be paused for a minimum of 6 months.',
			};
	}
}

/**
 * This entity records the history of Metro2 results for a tenant.
 */
@CommonEntity()
@CollectionName('tenantMetro2')
@Permissions({
	create : Permissions.serverOnly,
	read   : associatedTenantOrPermission,
	update : Permissions.serverOnly,
	delete : Permissions.serverOnly,
})
export class TenantMetro2 extends BaseEntity {

	@ManyToOne('Tenant', { nullable : false })
	tenant: Tenant = undefined;

	/**
	 * The month that this Metro2 segment reports on. (usually prior month to this.createdOn)
	 * The day should always be set to 1st of the month.
	 */
	@Column({ type : 'date' })
	month: Date = null;

	/**
	 * The type of result
	 */
	@Column()
	status: Metro2Status = '' as Metro2Status;

	/**
	 * The Metro2 segment for this tenant if the processing succeeded.
	 */
	// MUST DO use JSONABLE here to correctly serialize/deserialize
	@Column({ type : 'json', nullable : true })
	segment: Metro2Segment;

	/**
	 * An description message if the processing did not yield a segment.
	 */
	@Column({ default : '' })
	reason: string = '';

	/**
	 * TenantMetro2 are included in different groups which are used to generate different Metro2 files even for the same bureau and same month.
	 */
	@Column({ default : ReportingGroup.Tradeline, nullable : false })
	group: ReportingGroup = ReportingGroup.Tradeline;

	constructor();
	constructor(tenant: Tenant,  status: Metro2Status.Success, reason: string, segments?: Metro2Segment);
	constructor(tenant: Tenant,  status: Metro2Status.Failure, reason: Error|string);
	constructor(tenant: Tenant,  status: Metro2Status.Skipped, reason: string);
	constructor(tenant?: Tenant, status?: Metro2Status, reason?: string|Error, segments?: Metro2Segment) {
		super();

		if (tenant) {
			this.tenant = tenant;
		}

		if (status !== undefined) {
			this.status = status;
		}
		if (segments instanceof Metro2Segment) {
			this.segment = segments;
		}

		if (reason instanceof Error) {
			this.reason = reason.message || String(reason);
		}
		else if (typeof reason === 'string') {
			this.reason = reason;
		}

		this.month = Moment().startOf('month').subtract(1, 'month').toDate();
	}

	get customerFacingReason(): { status: string; tooltip?: string } {
		switch (this.status) {
			case Metro2Status.Success:
			case Metro2Status.Uploaded:
				return getHumanReadableReason(this.segment.base.accountStatus);
			case Metro2Status.Skipped:
				return getHumanReadableReason(this.reason) ?? { status : '' };
			case Metro2Status.Failure:
			default:
				return { status : 'Error' };
		}
	}

	async getAssociatedLeaseBalance() {
		await this.loadRelation('tenant');
		return LeaseBalance.findOne({ where : { leaseId : (this.tenant as any).leaseId, month : this.month } });
	}

	/**
	 * @returns the credit bureau associated with this class
	 */
	static getCreditBureau(): CreditBureau {
		throw new Errors.NotImplemented();
	}

	/**
	 * @returns the country associated with the credit bureau
	 */
	static getCreditBureauCountry(): Country {
		switch (this.getCreditBureau()) {
			case CreditBureau.EquifaxCA:
			case CreditBureau.LcbCA:
				return Country.CA;
			case CreditBureau.EquifaxUS:
			case CreditBureau.LcbUS:
			case CreditBureau.ExperianUS:
				return Country.US;
			default:
				throw new Error(`unknown credit bureau: ${this.getCreditBureau()}`);
		}
	}

	/**
	 * @returns true if the given Metro2 header segment belongs to this class
	 */
	static getReportingGroup(programID: string): ReportingGroup | void {			// eslint-disable-line @typescript-eslint/no-unused-vars
	}

	/**
	 * The first month for which to expose records to users.
	 */
	static get minMonth() {
		return new Date(2021, 7, 1);
	}

	async save(...args): Promise<this> {
		if (!this.isEdited('status') && this.status === Metro2Status.Uploaded) {
			throw new Error('cannot save when status is Uploaded');
		}
		return super.save.apply(this, args);
	}

	static getSample() {
		return new this((getEntityClass('Tenant') as unknown as typeof Tenant).getSample(), Metro2Status.Skipped, '');
	}

}

async function associatedTenantOrPermission(context: Context, tenantMetro2: TenantMetro2, query: SelectQueryBuilder<TenantMetro2>): Promise<string> {
	if (context.role.hasPermission(RolePermission.Metro2Read)) {
		return '';
	}

	if (query) {
		const alias = query.expressionMap.mainAlias.name;
		query.innerJoin('tenant', 'tenant', `tenant.id = \`${alias}\`.tenantId`);
		if (context.role.isLandlord) {
			query.andWhere(`tenant.orgId = :orgId AND \`${alias}\`.month >= :minMonth`, { orgId : context.org.id, minMonth : TenantMetro2.minMonth });
		}
		if (context.role.isRenter) {
			query.andWhere(`tenant.renterId = :roleId AND \`${alias}\`.month >= :minMonth`, { roleId : context.role.id, minMonth : TenantMetro2.minMonth });
		}
		return;
	}

	await tenantMetro2.loadRelation('tenant');
	if (Moment(tenantMetro2.month).isSameOrAfter(TenantMetro2.minMonth) && tenantMetro2.tenant) {
		if (tenantMetro2.tenant.orgId === context.org.id) {
			return;
		}

		await tenantMetro2.tenant.loadRelation('renter');
		if (tenantMetro2.tenant.renter?.id === context.role.id) {
			return;
		}
	}

	return 'insufficient permission';
}
