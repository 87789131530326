


























import { Vue, Component, Prop } from '$/lib/vueExt';

export enum UnavailableVariants {
	Unverified = 'unverified',
}

/**
 * A simple widget that display a text message indicating that something is not available.
 */
@Component
export default class Unavailable extends Vue {

	@Prop({ default : undefined })
	readonly title: string;

	@Prop({ default : undefined })
	readonly text: string;

	@Prop({ default : undefined })
	readonly subText: string;

	@Prop({ default : undefined })
	readonly infoTip: string;

	@Prop({ default : undefined })
	readonly icon: string;

	@Prop({ default : false })
	readonly vertical: boolean;

	@Prop({ default : undefined })
	readonly orientation: string;

	/**
	 * Optional variant that sets the title, text, and icon to various pre-sets.
	 */
	@Prop({ default : undefined })
	readonly variant: UnavailableVariants;

	get variantOptions() {
		return {
			[UnavailableVariants.Unverified] : {
				title : '',
				icon  : 'lock-fill',
				text  : this.$role.isWaitingApproval
					? 'Profile in Review: This usually takes 1 full business day.'
					: 'Security and privacy are our priority. To unlock this report you must first verify your identity',
				subText : '', // not strictly needed here, but TS complains in localSubText if it's not defined
				infoTip : '', // same here
			},
		};
	}

	get localTitle() {
		return (this.title ?? this.variantOptions[this.variant]?.title) || '';
	}

	get localIcon() {
		return (this.icon ?? this.variantOptions[this.variant]?.icon) || 'lock-fill';
	}

	get localText() {
		return (this.text ?? this.variantOptions[this.variant]?.text) || '';
	}

	get localSubText() {
		return (this.subText ?? this.variantOptions[this.variant]?.subText) || '';
	}

	get localInfoTip() {
		return (this.infoTip ?? this.variantOptions[this.variant]?.infoTip) || '';
	}

	created() {
		if (this.variant && !this.variantOptions[this.variant]) {
			throw new Error(`unknown Unavailable variant: ${this.variant}`);
		}
	}

}
