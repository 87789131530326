import { Chart, registerables, defaults, DoughnutController } from 'chart.js';
import type { Color, ArcProps } from 'chart.js';

export class GaugeController extends DoughnutController {

	static id = 'gauge';

	static overrides = {
		cutout        : '90%',
		circumference : 180,
		rotation      : -90,
		animation     : {
			duration : 0,
		},
		layout : {
			padding : 10,
		},
		plugins : {
			title : {
				display : false,
			},
			tooltip : {
				enabled : false,
			},
			legend : {
				display : false,
			},
			datalabels : {
				display : false,
			},
		},
	};

	draw(...args) {
		this.drawRoundedCorners();
		super.draw.apply(this, args);
		this.drawValue();
		this.drawLabel();
	}

	drawRoundedCorners() {
		const ctx      = this.chart.ctx;
		const metadata = this.getMeta().data;

		const { x, y, startAngle, outerRadius, innerRadius } = metadata[0] as unknown as ArcProps;

		const startBackgroundColor = metadata[0].options.backgroundColor as Color;

		const { endAngle } = metadata[metadata.length - 1] as unknown as ArcProps;

		const endBackgroundColor = metadata[metadata.length - 1].options.backgroundColor as Color;

		const radius    = (outerRadius + innerRadius) / 2;
		const thickness = (outerRadius - innerRadius) / 2;

		ctx.save();
		ctx.translate(x, y);
		ctx.beginPath();
		ctx.fillStyle = startBackgroundColor;
		ctx.arc(radius * Math.cos(startAngle), radius * Math.sin(startAngle), thickness, 0, 2 * Math.PI);
		ctx.fill();
		ctx.closePath();
		ctx.beginPath();
		ctx.fillStyle = endBackgroundColor;
		ctx.arc(radius * Math.cos(endAngle), radius * Math.sin(endAngle), thickness, 0, 2 * Math.PI);
		ctx.fill();
		ctx.closePath();
		ctx.restore();
	}

	drawValue() {
		const ctx  = this.chart.ctx;
		const meta = this.getMeta();
		const { x, y, outerRadius, innerRadius } = meta.data[0] as unknown as ArcProps;
		const { angle, backgroundColor }         = this.getScoreMeta();
		const radius                             = (outerRadius + innerRadius) / 2;
		const thickness                          = (outerRadius - innerRadius) / 2;

		ctx.save();
		ctx.fillStyle = backgroundColor;
		ctx.translate(x, y);
		ctx.beginPath();
		ctx.arc(radius * Math.cos(angle), radius * Math.sin(angle), thickness * 1.5, 0, 2 * Math.PI);
		ctx.closePath();
		ctx.fill();
		ctx.fillStyle = '#fff';
		ctx.beginPath();
		ctx.arc(radius * Math.cos(angle), radius * Math.sin(angle), thickness, 0, 2 * Math.PI);
		ctx.closePath();
		ctx.fill();
		ctx.restore();
	}

	getScoreMeta() {
		const score          = this.chart.data.datasets[0].score;
		const percentage     = Math.min(1, Math.max(score / this.calculateTotal(), 0));
		const metadata       = this.getMeta().data;
		const { startAngle } = metadata[0] as unknown as ArcProps;
		const angle          = startAngle + Math.PI * percentage;

		const index = metadata.findIndex(data => angle < (data as any).endAngle);
		const label = this.chart.data.labels[index];
		return { score, angle, label, backgroundColor : metadata[index].options.backgroundColor as Color };
	}

	drawLabel() {
		const chartArea                = this.chart.chartArea;
		const ctx                      = this.chart.ctx;
		const { score, label }         = this.getScoreMeta();
		const hasScore                 = score >= 0;
		const text                     = hasScore ? String(score + 300) : 'N/A';
		const centerX                  = (chartArea.left + chartArea.right) / 2;
		const centerY                  = (chartArea.top + chartArea.bottom) / 2;
		const { fontSize, fontFamily } = this.calculateFont(text, defaults.font.family);
		const smallFontSize            = fontSize / 4;

		ctx.save();
		ctx.textAlign    = 'center';
		ctx.textBaseline = 'bottom';
		ctx.fillStyle    = defaults.color.toString();
		if (hasScore) {
			ctx.font = `${smallFontSize}px ${fontFamily}`;
			ctx.fillText(label as string, centerX + this.offsetX, centerY + this.offsetY + Math.min(smallFontSize, 10));
		}
		ctx.font = `${fontSize}px ${fontFamily}`;
		ctx.fillText(text, centerX + this.offsetX, centerY + this.offsetY);
		ctx.restore();
	}

	calculateFont(text, fontFamily) {
		const ctx             = this.chart.ctx;
		const innerDiameter   = this.innerRadius * 2;
		const padding         = innerDiameter / 4;
		const elementWidth    = innerDiameter - padding;
		const initialFontSize = 30;

		ctx.save();
		ctx.font            = `${initialFontSize}px ${fontFamily}`;
		const maxWidthRatio = elementWidth / ctx.measureText('100%').width;
		const widthRatio    = elementWidth / ctx.measureText(text).width;
		ctx.restore();

		return { fontFamily, fontSize : Math.min(Math.floor(initialFontSize * widthRatio), Math.floor(initialFontSize * maxWidthRatio)) };
	}

}

Chart.register(...registerables);
Chart.register(GaugeController);
