import Moment                         from 'moment';
import Validate, { Validators }       from '$/lib/Validate';
import Errors                         from '$/lib/Errors';
import { SelectQueryBuilder, Unique } from '$/lib/typeormExt';

import { Discount }                              from '$/entities/billing/Discount';
import Permissions, { Context }                  from '$/entities/lib/Permissions';
import { RolePermission }                        from '$/entities/roles/RolePermission';
import { ChargeableProp, Package }               from '$/entities/Package';
import { BaseEntity, Column, Entity, OneToMany } from '$/entities/BaseEntity';
import { DiscountType }                          from '$/entities/billing/CouponExt';
export * from '$/entities/billing/CouponExt';

const promoCodeChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

export interface AutomaticDiscounts {
	label?: string;
	discounts: Partial<Record<ChargeableProp, Discount>>;
}
export interface AvailableAutomaticCoupons {
	label?: string;
	coupons: Partial<Record<ChargeableProp, Coupon>>;
}

/**
 * A Coupon represents a pattern of rules for creating Discounts for a given organization.
 */
@Entity({ common : true })
@Unique([ 'promoCode' ])
@Permissions({
	create : Permissions.roleHasPermission(RolePermission.CouponWrite),
	read   : checkReadPermission,
	update : Permissions.roleHasPermission(RolePermission.CouponWrite),
	delete : Permissions.roleHasPermission(RolePermission.CouponWrite),
})
export class Coupon extends BaseEntity {

	@Column()
	@Validate({ required : true })
	name: string = '';

	@Column()
	@Validate({ required : true })
	description: string = '';

	@Column({ nullable : true })
	@Validate({ maxLength : 25, custom : checkPromoCode })
	promoCode: string = null;

	/**
	 * Number of times this coupon can be redeemed across orgs
	 * 0 = unlimited
	 */
	@Column({ default : 0 })
	@Validate({ required : true, min : 0 })
	limitTotal: number = 0;

	/**
	 *	Number of times this coupon can be redeemed by a single org
	 * 0 = unlimited
	 */
	@Column({ default : 1 })
	@Validate({ required : true, min : 0 })
	limitPerOrg: number = 1;

	/**
	 * If true, allows only new users to to apply this coupon.
	 */
	@Column()
	onlyNewUsers: boolean = false;

	/**
	 * Coupon can only be applied by these roles.
	 * Values must be BaseRole class names.
	 */
	@Column('simple-array')
	roles: string[] = [];

	@Column('simple-array')
	@Validate({ custom : checkPackageIds })
	packages: string[] = [];

	@Column('simple-array')
	@Validate({ required : true, minLength : 1 })
	chargeables: ChargeableProp[] = [];

	@Column('enum', { enum : Object.values(DiscountType), default : DiscountType.Fixed })
	@Validate({ required : true })
	discountType: DiscountType = DiscountType.Fixed;

	/**
	 * Stores the discount amount, percentage is stored as int so 10 is 10%.
	 */
	@Column('decimal', { precision : 10, scale : 2 })
	@Validate({ required : true, custom : checkDiscountAmount })
	discountAmount: number;

	@OneToMany('Discount', 'coupon', { persistence : false })
	discounts: Discount[] = undefined;

	@Column({ nullable : true })
	expiresOn: Date = null;

	@Column({ nullable : true })
	startsOn: Date = null;

	/**
	 * If true, this coupon should be applied automatically if a discount for it exists for the user's account.
	 * If there is more than one such discount, the earliest created should be applied first.
	 *
	 * This is distinct from autoCoupons in Package. Those coupons are used to represent changes to a price that aren't explicitly exposed to the user
	 * (such as the bundle discount). They apply every time the price is displayed (e.g. report selection) and only show the price with the discount.
	 * They also support multiple chargeables each with their own discount.
	 *
	 * This also supports only a single chargeable at a time, since it works through the same logic that applies coupons at checkout.
	 */
	@Column({ default : false })
	autoApply: boolean = false;

	get isExpired() {
		return this.expiresOn && Moment().isAfter(Moment(this.expiresOn));
	}

	get hasStarted() {
		return !this.startsOn || Moment().isAfter(Moment(this.startsOn));
	}

	get isActive() {
		return this.hasStarted && !this.isExpired;
	}

	constructor(initialValues: Partial<Coupon> = null) {
		super();
		if (initialValues) {
			this.mergeColumns(initialValues);
		}
	}

	/**
	 * @returns the amount after this coupon's discount value is applied in dollars
	 */
	applyDiscountTo(amount: number): number {
		if (this.discountType === DiscountType.Percentage) {
			// Discount stored as int, so divide by 100 to get actual percent.
			return Math.max(0, Number((amount * (1 - this.discountAmount / 100)).toFixed(2)));
		}
		if (this.discountType === DiscountType.Fixed) {
			return Math.max(0, amount - this.discountAmount);
		}

		throw new Error(`invalid discountType: ${this.discountType}`);
	}

	static get automaticCoupons(): AvailableAutomaticCoupons[] {
		throw new Errors.NotImplemented();
	}

	static getAutoCoupon(chargeables: ChargeableProp[], pkg: Package) {
		// SHOULDDO: handle coupons for countries that don't match the package (e.g. Canadian LL pulling US credit reports)
		return this.automaticCoupons.find(autoCoupon =>
			_.xor(chargeables, Object.keys(autoCoupon.coupons)).length === 0
			&& Object.values(autoCoupon.coupons).some(coupon => coupon.packages.includes(pkg.id))
			&& Object.values(autoCoupon.coupons).every(coupon => coupon.isActive)
		);
	}

}

async function checkReadPermission(context: Context, coupon: Coupon, query: SelectQueryBuilder<Coupon>): Promise<string> {
	if (context.role.hasPermission(RolePermission.CouponRead)) {
		return;
	}

	// allow reading only if the role's org has an active Discount for this coupon
	if (query) {
		const alias = query.expressionMap.mainAlias.name;
		query.innerJoin(Discount, 'discount', `discount.couponId = ${alias}.id AND discount.orgId = :orgID`, { orgID : context.org.id });
	}
	else if (!(await Discount.doesExist({ where : { org : context.org, coupon } }))) {
		return 'invalid coupon';
	}
}

async function checkPromoCode(this: Coupon, promoCode?: string) {
	if (promoCode === null) {
		return '';
	}

	if (promoCode.length < 6) {
		return 'Must be at least 6 characters';
	}

	if (promoCode) {
		const error = Validators.allowChars({ value : promoCodeChars })(promoCode); // Using this in the normal way doesn't work with null values
		if (error) {
			return error;
		}
	}

	if (this.isNew && await Coupon.doesExist({ where : { promoCode } })) {
		return 'Redemption code already applied to your account';
	}

	return '';
}

async function checkDiscountAmount(this: Coupon, amount: number) {
	if (this.discountType === DiscountType.Percentage && (amount < 0 || amount > 100)) {
		return 'Percentage needs to be between 0 and 100';
	}

	if (this.discountType === DiscountType.Fixed && amount < 0) {
		return 'Amount must be greater than 0';
	}

	return '';
}

async function checkPackageIds(ids: string[]) {
	for (const id of ids) {
		if (!(await Package.doesExist({ where : { id } }))) {
			return `Packages must be a valid package ID, received ${id}`;
		}
	}

	return '';
}
