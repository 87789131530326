























































import { Vue, Component, Ref, Prop, Watch, Inject } from '$/lib/vueExt';
import type { HasValidMethod, SetFeedback }         from '$/lib/mixins/VueValidationMixin';
import { File as FileEntity, FileTypes, FileType }  from '$/entities/File';

/**
 * Allows the user to upload a file by either dragging and dropping or selecting a file from the file browser.
 * SHOULDDO: add tooltip that explains the file types accepted.
 */
@Component({
	inheritAttrs : false,
	model        : {
		prop  : 'file',
		event : 'update:file',
	},
})
export default class FormDropzone extends Vue {

	@Prop()
	readonly file: File | FileEntity;		// accepts both native DOM File objects and FL's File Entity objects

	/**
	 * The list of acceptable file types. By default, all are accepted.
	 */
	@Prop({ default : undefined })
	readonly accept: FileType[];

	@Prop({ default : false })
	readonly disabled: boolean;

	@Prop({ default : false })
	readonly required: boolean;

	@Prop({ default : undefined })
	readonly icon: string;

	@Prop({ default : undefined })
	readonly iconVariant: string;

	@Ref()
	readonly input: HTMLInputElement;

	// reports validation feedback up to a possible ancestor
	@Inject({ default : null, from : 'setFeedback' })
	readonly setFeedbackCallback: SetFeedback;

	// registers this form input with a possible ancestor
	@Inject({ default : null })
	readonly registerInput: (formInput: HasValidMethod) => void;

	@Inject({ default : null })
	readonly unregisterInput: (formInput: HasValidMethod) => void;

	// Can't just toggle the dragging class on dragenter/dragleave
	// The drag styling would get removed when dragging over a child element in the dropzone
	// See: https://stackoverflow.com/a/21002544
	dragCounter = 0;

	localError = '';

	get localNativeFile(): File {
		return this.file instanceof FileEntity ? this.file.nativeFile : this.file as File;
	}

	get errorMessage() {
		return this.file instanceof FileEntity && this.file.errorMessage || this.localError;
	}

	get fileIcon() {
		if (this.icon) {
			return this.icon;
		}
		const mimeType = this.file instanceof FileEntity && this.file.mimeType ||  this.localNativeFile?.type || '';

		if (!mimeType) {
			return 'cloud-upload';
		}
		if (mimeType.startsWith('image/')) {
			return 'file-earmark-image';
		}
		if (mimeType.startsWith('application/pdf')) {
			return 'file-earmark-pdf';
		}

		return 'file-earmark';
	}

	get isDragging() {
		return !this.disabled && this.dragCounter > 0;
	}

	get filename() {
		return this.$format.middleEllipsis(this.localNativeFile?.name ?? this.file?.name);
	}

	get fileSize() {
		if (this.file instanceof FileEntity) {
			return this.file.nativeFile?.size ?? this.file.size.valueOf();
		}
		return this.file?.size ?? 0;
	}

	get dropText() {
		return this.localNativeFile ? `Replace ${this.filename}` : 'Drop your file here';
	}

	get acceptMimeTypes() {
		return this.accept ? this.accept.map(fileType => fileType.mimeTypes).join(',') : '*';
	}

	mounted() {
		// register with a possible ancestor form
		this.registerInput?.(this);
	}

	beforeDestroy() {
		this.unregisterInput?.(this);
	}

	drop(event: DragEvent) {
		this.dragCounter--;

		if (this.disabled) {
			return;
		}

		const files = event.dataTransfer.files;
		if (this.areFilesValid(files)) {
			this.input.files = files;
			this.input.dispatchEvent(new Event('change'));
		}
	}

	async isValid() {
		return this.areFilesValid(this.input.files);
	}

	areFilesValid(files: FileList) {
		const file = files?.[0];

		if (_.isEmpty(files) && this.fileSize === 0) {
			this.localError = this.required ? 'This field is required.' : '';
		}
		else if (files?.length > 1) {
			// COULDDO: support for multiple files
			this.localError = 'Only select a single file.';
		}
		else if (file && this.accept && !this.accept.some(fileType => fileType.mimeTypes.includes(file.type))) {
			this.clearFile();
			const acceptedTypes = _.flatMap(this.accept, fileType => fileType.extensions).map(ext => _.upperCase(ext));
			this.localError     = `This file type is not acceptable. File type must be one of: ${acceptedTypes.join(', ')}`;
		}
		else if (file && _.flatMap(FileTypes.Blacklist, fileType => fileType.mimeTypes).some(mimeType => mimeType === file.type)) {
			this.clearFile();
			const unacceptableFiles = _.flatMap(FileTypes.Blacklist, fileType => fileType.extensions).map(ext => _.upperCase(ext));
			this.localError         = `This file type is not acceptable. File type cannot be any of: ${unacceptableFiles.join(', ')}`;
		}
		else {
			this.localError = '';
		}

		this.setFeedbackCallback?.(this.localError);
		return !this.localError;
	}

	@Watch('file')
	onFilePropChange() {
		if (!this.localNativeFile) {
			// When the file prop is set to a falsy value, clear the file input.
			// This prevents an issue when the file is cleared outside of the component, it doesn't actually clear the file.
			this.clearFile();
		}

		if (this.file instanceof FileEntity && !this.file.nativeFile) {
			this.localError = this.file.errorMessage;
			this.setFeedbackCallback?.(this.file.errorMessage);
		}
	}

	@Watch('file.nativeFile')
	test() {
		if (this.file instanceof FileEntity && this.file.nativeFile) {
			this.file.errorMessage = this.localError;
		}
	}

	clearFile() {
		this.input.value = '';
		this.input.dispatchEvent(new Event('change'));
	}

	onFilesInputChange() {
		const newFiles = this.input.files;
		if (_.isNotEmpty(newFiles) && !this.areFilesValid(newFiles)) {
			return;
		}

		if (!this.file || this.file instanceof File) {
			this.$emit('update:file', newFiles[0] ?? null);
		}
		else if (this.file instanceof FileEntity) {
			this.file.nativeFile = newFiles[0] ?? null;
			this.$emit('file-changed', this.file.nativeFile);
		}
	}

	openFileBrowser() {
		this.input.click();
	}

}

