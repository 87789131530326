import env    from '$/lib/env';
import Errors from '$/lib/Errors';
import { PersonaCheck, warningOnlyChecks } from '$/lib/personaClient';

import { ChildEntity, CommonEntity } from '$/entities/BaseEntity';
import { Verification }              from '$/entities/verifications/Verification';

export { VerificationStatus } from '$/entities/verifications/Verification';

/**
 * A type of identity verification that uses the Persona 3rd party service.
 */
@ChildEntity() @CommonEntity()
export class PersonaVerification extends Verification {

	// redefine the type of reasons to be more specific
	reasons: PersonaCheck[];

	get additionalData() {
		return this.additionalDataValue as PersonaData;
	}

	get inquiryId() {
		return this.additionalData.inquiryId;
	}
	set inquiryId(newInquiryId: string) {
		if (_.isNil(this.additionalDataValue)) {
			this.additionalDataValue = {};
		}
		this.additionalDataValue.inquiryId = newInquiryId;
	}

	/**
	 * Composes humanly-readable failure messages based on the verification result for a particular end user.
	 * @param forSupport - if true, composes messages for support staff
	 */
	getFailureMessages({ forSupport = false } = {}): { message: string; isWarning?: boolean }[] {
		const reasons = this.reasons ?? [];
		const piiData = _.compactObject(_.pick(this.additionalData, 'firstName', 'lastName', 'birthDate'));

		let messages: { message: string; isWarning?: boolean }[] = [];

		if (forSupport) {
			messages = reasons.map(reason => {
				const message = failureMessages(reason, piiData);
				return { message : message ? message.supportMessage : reason, isWarning : warningOnlyChecks.includes(reason) };
			});
		}
		else {
			messages = reasons.map(reason => {
				const message = failureMessages(reason, piiData);
				return { message : message ? message.userMessage || failureMessages(PersonaCheck.Generic, piiData).userMessage : '' };
			});
		}

		return messages.filter(m => m.message);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static checkInquiryStatus(inquiryId: string): Promise<{ status: string; sessionToken: string }> {
		throw new Errors.NotImplemented();
	}

	static getURL(suffix = ''): string {
		return `/api/user/verification/persona${_.ensureStartsWith(suffix, '/')}`;
	}

	static get personaEnvironment(): string {
		return env.config('persona.env', { required : true });
	}

	static get personaTemplate(): string {
		return env.config('persona.templateID', { required : true });
	}

}

export interface PersonaData {
	persona: IdentityData;
	user: IdentityData;
	inquiryId: string;
}

export interface IdentityData {
	firstName: string;
	middleName?: string;
	lastName: string;
	birthDate?: string;
}

/* eslint-disable max-len */
export function failureMessages(check: PersonaCheck, { firstName = '', lastName = '', birthDate = '' } = {}): { userMessage?: string; supportMessage: string } {
	return {
		[PersonaCheck.IDColour] : {
			userMessage    : 'The color of your ID does not match the photo on your ID. Please use a valid ID that matches the photo on your ID.',
			supportMessage : 'ID color does not match photo.',
		},
		[PersonaCheck.Blur] : {
			userMessage    : 'The image of your ID is too blurry. Please retake the photo with a clearer focus.',
			supportMessage : 'ID image is blurry.',
		},
		[PersonaCheck.IDDisallowedCountry] : {
			userMessage    : 'The country of your ID is not supported. Please use a valid ID from a supported country.',
			supportMessage : 'ID from unsupported country.',
		},
		[PersonaCheck.IDDisallowedType] : {
			userMessage    : 'The type of ID you submitted does not match the selected ID type. Please ensure you submit the correct ID type.',
			supportMessage : 'Submitted ID type does not match selected ID type.',
		},
		[PersonaCheck.Expired] : {
			userMessage    : 'The ID you submitted has expired. Please provide a valid, non-expired ID.',
			supportMessage : 'Submitted ID is expired.',
		},
		[PersonaCheck.IDMRZ] : {
			userMessage    : 'The Machine Readable Zone (MRZ) on your ID could not be detected. Please ensure the entire ID is visible and clear.',
			supportMessage : 'Missing MRZ on ID.',
		},
		[PersonaCheck.IDPOBox] : {
			userMessage    : 'A PO Box address was detected on your ID. Please provide an ID with a physical residential address.',
			supportMessage : 'PO Box address detected on ID.',
		},
		[PersonaCheck.IDPortraitClarity] : {
			userMessage    : 'The portrait on your ID is not clear enough. Please retake the photo ensuring the portrait is clearly visible.',
			supportMessage : 'Low clarity of portrait on ID.',
		},
		[PersonaCheck.IDPortrait] : {
			userMessage    : 'No portrait was detected on your ID. Please ensure your ID includes a clearly visible portrait.',
			supportMessage : 'No portrait detected on ID.',
		},
		[PersonaCheck.Generic] : {
			userMessage    : 'There was an issue with your verification. Please try again, ensuring your ID is clear and your face is clearly visible, well-lit, and matches the photo on your ID.',
			supportMessage : 'Verification issue detected.',
		},
		[PersonaCheck.MissingFirstName] : {
			userMessage    : 'The first name on your ID could not be extracted. Please ensure your first name is clearly visible on your ID.',
			supportMessage : 'Missing first name on ID.',
		},
		[PersonaCheck.MissingLastName] : {
			userMessage    : 'The last name on your ID could not be extracted. Please ensure your last name is clearly visible on your ID.',
			supportMessage : 'Missing last name on ID.',
		},
		[PersonaCheck.MissingDateOfBirth] : {
			userMessage    : 'The date of birth on your ID could not be extracted. Please ensure your date of birth is clearly visible on your ID.',
			supportMessage : 'Missing date of birth on ID.',
		},
		[PersonaCheck.MismatchFirstName] : {
			userMessage    : `The first name on your ID ${firstName} does not match the first name you provided. Please ensure your first name matches the name on your ID.`,
			supportMessage : `First name on ID (${firstName}) does not match provided first name.`,
		},
		[PersonaCheck.MismatchLastName] : {
			userMessage    : `The last name on your ID ${lastName} does not match the last name you provided. Please ensure your last name matches the name on your ID.`,
			supportMessage : `Last name on ID (${lastName}) does not match provided last name.`,
		},
		[PersonaCheck.MismatchDateOfBirth] : {
			userMessage    : `The date of birth on your ID ${birthDate} does not match the date of birth you provided. Please ensure your date of birth matches the date on your ID.`,
			supportMessage : `Date of birth on ID (${birthDate}) does not match provided date of birth.`,
		},
		// the following are only shown to support so no user message is needed
		[PersonaCheck.IDElectronicReplicaDetection]    : { supportMessage : 'Digital replica ID detected.' },
		[PersonaCheck.IDRepeat]                        : { supportMessage : 'Duplicate ID across multiple accounts detected.' },
		[PersonaCheck.IDInquiryComparison]             : { supportMessage : 'Inquiry comparison failed.' },
		[PersonaCheck.IDPaperDetection]                : { supportMessage : 'Paper ID detected.' },
		[PersonaCheck.SelfieIDComparison]              : { supportMessage : 'Selfie ID comparison failed.' },
		[PersonaCheck.SelfiePoseDetection]             : { supportMessage : 'Selfie pose detection failed.' },
		[PersonaCheck.SelfieMultipleFacesDetection]    : { supportMessage : 'Selfie multiple faces detection failed.' },
		[PersonaCheck.SelfiePoseRepeatDetection]       : { supportMessage : 'Selfie pose repeat detection failed.' },
		[PersonaCheck.SelfieSuspiciousEntityDetection] : { supportMessage : 'Selfie suspicious entity detection failed.' },
		[PersonaCheck.SelfieLivenessDetection]         : { supportMessage : 'Selfie liveness detection failed.' },
		[PersonaCheck.SelfiePublicFigureDetection]     : { supportMessage : 'Selfie public figure detection failed.' },
		[PersonaCheck.SelfieFaceCoveringDetection]     : { supportMessage : 'Selfie face covering detection failed.' },
	}[check];
}
