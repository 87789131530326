/**
 * All role permissions for the system.
 * Factored out into it's own file to minimize chances of circular dependency chains.
 */
export enum RolePermission {
	LeaseWrite                     = 'leaseWrite',            // allows creation/update/deletion of Lease and related entities (eg: LeaseBalance, Tenant, etc..)

	OrgView                        = 'orgView',               // allows the role to view and possibly change it's organization
	OrgManageInvitations           = 'orgManageInvitation',   // allows landlords to invite other landlords to their organization

	LogsRead                       = 'logsRead',              // allows reading of in-app system logs
	IdentityVerification           = 'identityVerification',  // allows the role to change the verificationStatus field on BaseRole and User
	EmailRead                      = 'emailRead',             // allows the role to read emails
	Metro2Read                     = 'metro2Read',            // allows viewing of Metro2 results
	Metro2Write                    = 'metro2Write',           // allows trigger of Metro2 processing and marking Uploaded
	FinancialsRead                 = 'financialsRead',        // allows the role to read financial graphs

	RoleCreate                     = 'roleCreate',            // allows creating new roles
	RoleAssumeOther                = 'roleAssumeOther',       // allows switching to any another role
	RoleCrossOrgWrite              = 'roleCrossOrgWrite',     // allows updating roles in other Organizations
	RoleDelete                     = 'roleDelete',            // allows the user to archive/delete roles
	RoleAdditionalPermissionsRead  = 'roleAdditionalPermissionsRead', // allows the role to read additional permissions
	RoleAdditionalPermissionsWrite = 'roleAdditionalPermissionsWrite', // allows the role to modify additional permissions
	PackageWrite                   = 'packageWrite',          // allows creating new packages and editing existing ones
	CrossOrgRead                   = 'crossOrgRead',          // allows reading of entities that belong to other Organizations
	CrossOrgSetPackage             = 'crossOrgSetPackage',    // allows the role to modify packages for organizations other than theirs
	CrossOrgImport                 = 'crossOrgImport',        // allows the role to import data (e.g. Tenant records) to other organizations
	CrossOrgWrite                  = 'crossOrgWrite',         // allows the role to modify fields in other Organizations
	CrossOrgDelete                 = 'crossOrgDelete',        // allows the role to delete other organizations
	ReportingRead                  = 'reportingRead',         // allows the role to read reporting charts data
	ReportingWrite                 = 'reportingWrite',        // allows the role to write reporting charts data
	UserSuspend                    = 'userSuspend',           // suspend a user, disables emails etc.
	CreateApiKey                   = 'createApiKey',          // allows the role to create API Key Auth for other roles/users
	CouponRead                     = 'couponRead',            // allows reading of coupons
	CouponWrite                    = 'couponWrite',           // allows writing of coupons
	AffiliateInternalRead          = 'affiliateInternalRead', // allows reading of internal affiliate fields
	AffiliateWrite                 = 'affiliateWrite',        // allows writing of affiliates
	RentReportingToggleWrite       = 'rentReportingToggleWrite', // allows the role to toggle rent reporting for a tenant
	EnterpriseThresholdWrite       = 'enterpriseThresholdWrite', // allows the role to modify enterprise rent/debt reporting thresholds
	ConnectionSettingsWrite        = 'connectionSettingsWrite',  // allows the role to modify Enterprise connection settings

	HubSpotAccess                  = 'hubSpotAccess',         // allows full access to HubSpot

	EquifaxCustomerInfo            = 'equifaxCustomerInfo',   // allows access to equifaxConsumerID

	SecurityFreeze                 = 'securityFreeze',        // allows the role to apply security freeze to a tenant record

	TenantDisputes                 = 'tenantDisputes',        // allows the role to set/unset the tenant dispute flag that marks the tenant's data as being disputed by the tenant

	CollectFileCrossOrgAccess      = 'collectFileCrossOrgAccess', // allows collection roles to access files share with them
}
