import { ChildEntity, CommonEntity } from '$/entities/BaseEntity';
import { Verification }              from '$/entities/verifications/Verification';

export { VerificationStatus } from '$/entities/verifications/Verification';

/**
 * Implementation of identity verification using Equifax's EID verification service (knowledge-based test)
 * @deprecated
 */
@ChildEntity() @CommonEntity()
export class EIDVerification extends Verification {

	get additionalData() {
		return this.additionalDataValue as { score: number };
	}

}
