import Moment from 'moment';

import Validate, { ValidationMixin }   from '$/lib/Validate';
import { Country }                     from '$/lib/Address';
import { getSampleNameEmail, waitFor } from '$/lib/utils';
import { nameChars }                   from '$/lib/Validate';
import { Index }                       from '$/lib/typeormExt';

import { entity }                          from '$/entities/runtimeEntities';
import Permissions, { Context }            from '$/entities/lib/Permissions';
import { RolePermission }                  from '$/entities/roles/RolePermission';
import { Email }                           from '$/entities/emails/Email';
import { StartupActions }                  from '$/entities/StartupActions';
import { Address }                         from '$/entities/Address';
import { DocumentType }                    from '$/entities/FileExt';
import type { NationalID }                 from '$/entities/NationalID';
import type { BaseRole }                   from '$/entities/roles/BaseRole';
import type { BaseRole as BaseRoleCommon } from '$/common/entities/roles/BaseRole';
import type { Authentication }             from '$/entities/Authentication';
import type { Verification }               from '$/entities/verifications/Verification';
import { BaseEntity, Column, EntityID, isEntityID, CommonEntity, OneToOne, OneToMany } from '$/entities/BaseEntity';

import { NameSuffixOptions, ProgressStatus, VerificationDoc } from '$/entities/UserExt';
export * from '$/entities/UserExt';

/**
 * An User represents a specific person and it's main concern is authenticating that person.
 * This is separate from any User entity which concerns itself with preferences and settings for a specific product.
 */
@CommonEntity()
export abstract class User extends BaseEntity {

	@Column()
	@Permissions({ read : isOrgMemberOrSupportRead, write : [ notVerified, onlySelfOrSupportWrite ] })
	@Validate({ allowChars : { value : nameChars }, required : true, trimmed : true })
	firstName: string = '';

	@Column({ default : '' })
	@Permissions({ read : isOrgMemberOrSupportRead, write : [ notVerified, onlySelfOrSupportWrite ] })
	@Validate({ allowChars : { value : nameChars }, trimmed : true })
	middleName: string = '';

	@Column()
	@Index()
	@Permissions({ read : isOrgMemberOrSupportRead, write : [ notVerified, onlySelfOrSupportWrite ] })
	@Validate({ allowChars : { value : nameChars }, required : true, trimmed : true })
	lastName: string = '';

	@Column({ type : 'enum', enum : Object.values(NameSuffixOptions), default : '' })
	@Permissions({ read : onlySelfOrSupportRead, write : [ notVerified, onlySelfOrSupportWrite ] })
	suffix: NameSuffixOptions = '' as NameSuffixOptions;

	/**
	 * The unique email that this User is associated with.
	 */
	@Column({ type : 'varchar' })
	@Index({ unique : true })
	@Permissions({ write : Permissions.serverOnly })
	@Validate({ required : true, email : true, trimmed : true, custom : value => value ? Email.validateAddress(value) : '' })
	email: EmailAddress = '';

	/**
	 * True if the email has been verified by the user.
	 */
	@Column()
	@Permissions({ read : onlySelfOrSupportRead, write : Permissions.serverOnly })
	emailVerified: boolean = false;

	@Column() @Index()
	@Permissions({ write : [ Permissions.roleHasPermission(RolePermission.IdentityVerification), Permissions.stopChecks ] })
	verificationStatus: ProgressStatus = ProgressStatus.Incomplete;

	@Column({ default : '' })
	@Permissions({ read : onlySelfOrSupportRead })
	@Validate({ phoneNumber : { strict : true } })
	phoneNumber: string = '';

	@Column({ type : 'date', nullable : true })
	@Permissions({ read : onlySelfOrSupportRead, write : [ notVerified, onlySelfOrSupportWrite ] })
	@Validate({ custom : value => value > User.dateOfBirthMax ? `Minimum age on FrontLobby is: ${User.minAge}` : '' })
	dateOfBirth: Date = null;

	@OneToOne('NationalID', 'user')
	nationalID: NationalID = undefined;

	@Column({ type : 'json', default : () => "('{}')" })
	@Permissions({ read : onlySelfOrSupportRead })
	@Validate({ required : true, recursive : true })
	address: Address = new Address();

	@Column({ asExpression : "address->>'$.street'", nullable : true })
	get street() {
		return this.address.street;
	}
	set street(value: string) {
		this.address.street = value;
	}

	@Column({ asExpression : "address->>'$.city'", nullable : true })
	get city() {
		return this.address.city;
	}
	set city(value: string) {
		this.address.city = value;
	}

	@Column({ asExpression : "address->>'$.province'", nullable : true })
	get province() {
		return this.address.province;
	}
	set province(value: string) {
		this.address.province = value;
	}

	@Column({ length : '2', asExpression : "address->>'$.country'", nullable : true })
	get country() {
		return this.address.country;
	}
	set country(value: Country) {
		this.address.country = value;
	}

	@Column({ asExpression : "address->>'$.postalCode'", nullable : true })
	get postalCode() {
		return this.address.postalCode;
	}
	set postalCode(value: string) {
		this.address.postalCode = value;
	}

	@Column({ asExpression : "address->>'$.unitNumber'", nullable : true })
	get unitNumber() {
		return this.address.unitNumber;
	}
	set unitNumber(value: string) {
		this.address.unitNumber = value;
	}

	get isCanadian() {
		return this.address.isCanadian;
	}

	get isAmerican() {
		return this.address.isAmerican;
	}

	@Index()
	@Column({ nullable : true })
	@Permissions({ read : onlySelfOrSupportRead, write : Permissions.serverOnly })
	lastLoginOn: Date = null;

	// #region suspension

	/**
	 * The date/time after which the user is suspended.
	 * Suspension means that the user:
	 * - cannot login
	 * - does not receive emails
	 *
	 * Do not modify this field directly. Rather, set the suspension reason to a descriptor why the user was suspended.
	 * Set the suspension reason to '' to unsuspend the user.
	 */
	@Column({ nullable : true })
	@Permissions({
		read  : [ Permissions.roleHasPermission(RolePermission.UserSuspend), Permissions.stopChecks ],
		write : [ Permissions.serverOnly ],		// this value is written by setting this.suspensionReason
	})
	@Index()
	suspendedOn: Date = null;

	@Column()
	@Permissions([ Permissions.roleHasPermission(RolePermission.UserSuspend), Permissions.stopChecks ])
	suspensionReason: string = '';

	/**
	 * @returns true if this user has been suspended
	 */
	get isSuspended(): boolean {
		return !!(this.suspendedOn && Moment().isSameOrAfter(this.suspendedOn, 'minute'));
	}
	// #endregion suspension

	@Column()
	@Permissions(Permissions.roleHasPermission(RolePermission.HubSpotAccess))
	hubSpotID: string = '';

	@OneToMany('Authentication', 'user', { persistence : false })
	@Permissions({ write : Permissions.serverOnly })
	authentications: Authentication[];

	@OneToMany('BaseRole', 'user', { persistence : false })
	@Permissions({ write : Permissions.serverOnly })
	roles: BaseRole[] = undefined;

	@Column({ type : 'json', default : () => "('{}')" })
	startupActions: StartupActions = new StartupActions();

	/**
	 * @deprecated use this.verifications instead
	 */
	@Column({ type : 'json', default : () => "('[]')" })
	@Permissions({ read : Permissions.serverOnly, write : Permissions.serverOnly })
	verificationResults: any[] = [];

	/**
	 * The results of the user's identity verification attempts.
	 */
	@OneToMany('Verification', 'user', { persistence : false })
	@Permissions({ write : Permissions.serverOnly })
	verifications: Verification[] = undefined;

	get fullName() {
		return getFullName(this);
	}

	get initials() {
		return [ this.firstName, this.lastName ].map(name => name.charAt(0)).join('').toLocaleUpperCase();
	}

	get isPersonalInfoComplete(): boolean {
		const contactFields = [ 'firstName*', 'middleName', 'lastName*', 'suffix', 'dateOfBirth*', 'phoneNumber*' ];
		return contactFields.every(field => checkField(field, this));
	}

	get isAddressInfoComplete(): boolean {
		const addressFields = [ 'unitNumber', 'street*', 'country*', 'city*', 'province*', 'postalCode*' ];
		return addressFields.every(field => checkField(field, this.address));
	}

	/**
	* @returns true if all of the user's contact information is valid and completed
	*/
	get isContactInfoComplete(): boolean {
		return this.isPersonalInfoComplete && this.isAddressInfoComplete;
	}

	get isVerified() {
		return this.verificationStatus === ProgressStatus.Approved;
	}

	get isVerificationSubmitted() {
		return this.verificationStatus === ProgressStatus.Submitted;
	}

	get isVerificationRejected() {
		return this.verificationStatus === ProgressStatus.Rejected;
	}

	/**
	 * @see User.waitForVerificationStatusUpdate
	 */
	waitForVerificationStatusUpdate(options?: WaitForVerificationStatusUpdateOptions) {
		return User.waitForVerificationStatusUpdate(this, options);
	}

	/**
	 * @returns the type of verification needed for this user given the user's role (or null if no verification can be used)
	 */
	async getVerificationType(role: BaseRole): Promise<typeof Verification> {
		if ([ ProgressStatus.Approved, ProgressStatus.Fraudulent ].includes(this.verificationStatus)) {
			return null;
		}

		if (role.isLandlord && !(await entity.PersonaVerification.maxAttemptsReached(this))) {
			return entity.PersonaVerification;
		}
		if (role.isRenter && this.isAmerican && !(await entity.PersonaVerification.maxAttemptsReached(this))) {
			return entity.PersonaVerification;
		}
		if (role.isRenter && this.isCanadian && !(await entity.KountVerification.maxAttemptsReached(this))) {
			return entity.KountVerification;
		}

		return entity.DocumentsVerification;
	}

	/**
	 * @returns a list of all verification documents needed for this user
	 */
	getVerificationDocs(): VerificationDoc[] {
		return this.verificationStatus === ProgressStatus.Approved ? [] : [
			{
				type          : 'document',
				referenceType : 'user',
				docType       : DocumentType.UserPhotoID,
				title         : 'Identification showing your photo, address, and birth date',
				desc          : "(i.e. driver's license OR passport)",
			},
		];
	}

	/**
	 * Sets a new password for this user.
	 */
	abstract changePassword(currentPassword: string, newPassword: string): Promise<any>

	/**
	 * Sets a new email for this user.
	 */
	abstract changeEmail(email: string): Promise<any>

	/**
	 * @returns true if the user with the given email address is currently suspended.
	 */
	static async isSuspended(emailOrID: EntityID | EmailAddress): Promise<boolean> {
		const userRaw = await this.findOneRaw({ where : { [isEntityID(emailOrID) ? 'id' : 'email'] : emailOrID } });	// SHOULDDO: add select option for suspendedOn when available
		return !!(userRaw?.suspendedOn && Moment().isSameOrAfter(userRaw.suspendedOn, 'minute'));
	}

	static getSample<T extends typeof User>(this: T): InstanceType<T> {
		const samplePerson = getSampleNameEmail();
		// @ts-ignore complains about User being an abstract class but at run-time, this will be a concrete non-abstract class
		const sample         = new this() as InstanceType<T>;
		sample.id            = 'sample';
		sample.ver           = 1;	// prevents the sample from registering as new (via isNew)
		sample.firstName     = samplePerson.firstName;
		sample.lastName      = samplePerson.lastName;
		sample.email         = samplePerson.email;
		sample.emailVerified = true;
		sample.dateOfBirth   = new Date(1, 1, 2000);
		return sample;
	}

	/**
	 * The minimum age (in years) of users.
	 */
	static get minAge() {
		return 18;
	}

	/**
	 * The maximum value of this.dateOfBirth allowed based on the minAge constant.
	 */
	static get dateOfBirthMax(): Date {
		return Moment().subtract(User.minAge, 'years').toDate();
	}

	/**
	 * @returns the ID of the current logged in user (if any)
	 */
	static get currentID(): EntityID {
		return '';
	}

	/**
	 * @returns the User entity of the currently logged in user (if any).
	 */
	static async loadCurrent(): Promise<User> {
		return undefined;
	}

	/**
	 * Waits for the user's verification status to change from Incomplete to something else.
	 */
	// HACK: using BaseRoleCommon here to avoid TS complaining about this being called with common versions of BaseRole
	static async waitForVerificationStatusUpdate(userOrRole: User | BaseRoleCommon, options: WaitForVerificationStatusUpdateOptions = {}) {
		const entity = options?.clone ? userOrRole.clone() : userOrRole;

		const success = await waitFor(async () => {
			await entity.reload();
			return options?.verificationStatus
				? entity.verificationStatus === options.verificationStatus
				: entity.verificationStatus !== ProgressStatus.Incomplete;
		}, { timeout : 10000 });

		if (!success) {
			throw new Error('timeout waiting for verification status update');
		}
	}

}

export function onlySelf(context: Context, user: User) {
	return (user.id === (context.role as any).userId || user.id === context.user.id) ? '' : 'cannot access other users';
}

export function onlySelfOrSupportWrite(context: Context, user: User) {
	if (context.role.hasPermission(RolePermission.CrossOrgWrite)) {
		context.stopChecks();
		return;
	}
	return onlySelf(context, user);
}

export function onlySelfOrSupportRead(context: Context, user: User) {
	if (context.role.hasPermission(RolePermission.CrossOrgRead)) {
		return;
	}
	return onlySelf(context, user);
}

export async function isOrgMemberOrSupportRead(context: Context, user: User) {
	if (context.user.id === user.id || context.role.hasPermission(RolePermission.CrossOrgRead)) {
		return '';
	}

	await user.loadRelation('roles');

	if ((user.roles[0] as any)?.orgId === context.org.id) {
		return '';
	}

	return 'cannot access other users';
}

// Name and date of birth shouldn't be editable after the user has passed identity verification
function notVerified(context: Context, user: User) {
	return user.isVerified ? 'cannot edit after identity verification' : '';
}

/**
 * Extracts and formats certain fields from the given object.
 */
export function getFullName(obj: { firstName?: string; middleName?: string; lastName?: string; suffix?: string}) {
	return [ obj.firstName, obj.middleName, obj.lastName, obj.suffix ].filter(value => !!value).join(' ');
}

function checkField(field: string, object: ValidationMixin) {
	const isRequired = _.last(field) === '*';
	field            = _.trim(field, '*');

	// make sure that the field exists (sanity check to catch spelling mistakes)
	if (!object.hasOwnProperty(field)) {
		throw new Error(`invalid contact field name: ${field}`);
	}

	// first check for required field
	if (isRequired && [ '', null, undefined ].includes(object[field])) {
		return false;
	}

	return object.isValid(field);
}

export interface WaitForVerificationStatusUpdateOptions {
	/**
	 * If provided, waits for the user's verification status to change to this value
	 */
	verificationStatus?: ProgressStatus;

	/**
	 * If true, this user instance won't be touched but a clone will be used instead
	 */
	clone?: boolean;
}
