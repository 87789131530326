import Axios               from 'axios';
import { Vue }             from '$/lib/vueExt';
import { DeferredPromise } from '$/lib/utils';
import Errors              from '$/lib/Errors';

import { User }                 from '$/entities/User';
import { Authentication }       from '$/entities/Authentication';
import { Entity, SyncEntities } from '$/entities/BaseEntity';

import { BaseRole as BaseRoleCommon, CreateReferralResponse, ReferralInfo, ReferralMethod } from '$/common/entities/roles/BaseRole';
export * from '$/common/entities/roles/BaseRole';

let current: BaseRole;
let currentPromise: DeferredPromise<void> = null;

@Entity()
export class BaseRole extends BaseRoleCommon {

	async getReferralInfo(): Promise<Record<ReferralMethod, ReferralInfo>> {
		return (await Axios.get(BaseRole.collectionUrl('referral'))).data;
	}

	async refer(method: ReferralMethod.Facebook): Promise<CreateReferralResponse>;
	async refer(method: ReferralMethod.Email, inviteeInfo: HasName & HasEmail): Promise<CreateReferralResponse>;
	async refer(method: ReferralMethod, inviteeInfo?: HasName & HasEmail): Promise<CreateReferralResponse> {
		return (await Axios.post(BaseRole.collectionUrl(`referral/${method}`), { inviteeInfo })).data;
	}

	/**
	 * If renter joined the app earlier than the Landlord they can send an invitation to Landlord to join the app.
	 */
	async sendInvite(to: HasName & HasEmail) {
		await Axios.post(BaseRole.collectionUrl(`${this.id}/invitation`), { landlord : to });
	}

	/**
	 * If the client is authenticated, this returns the current Role.
	 */
	@SyncEntities()
	static get current(): BaseRole {
		return current;
	}

	static get currentID() {
		return current?.id ?? '';
	}

	/**
	 * @param {boolean} [options.redirectToLogin=true] if true, redirects to login if credentials no longer valid
	 * @param {boolean} [options.force=false] if true, forces a fresh load (even if current role is already cached)
	 */
	static async loadCurrent({ redirectToLogin = true, force = false } = {}): Promise<BaseRole> {
		if (!currentPromise || force) {
			currentPromise = new DeferredPromise<void>();

			try {
				await Authentication.getFreshSession();
				const roleID = Authentication.session?.roleID;
				if (!roleID) {
					throw new Errors.HTTP.Unauthorized('no user session found');
				}

				const foundRole = await BaseRole.findOne({ where : { id : roleID }, relations : [ 'org' ] });
				if (foundRole) {
					// Make the instance Vue-reactive
					current = Vue.observable(foundRole);
					if (current.org) {
						await current.org.loadRelation('package');
					}
				}
				// NOTE: It is possible that BaseRole.current.user.id != User.current.id (when assuming another user's Role)
				await User.loadCurrent({ redirectToLogin });
				currentPromise.resolve();
			}
			catch (err) {
				if (err.status === 403 || err.status === 401) {
					if (redirectToLogin) {
						void Authentication.signOut({ routeTo : document.location.href });
					}
					current = undefined;
					currentPromise.resolve();
				}
				else {
					currentPromise.reject(err);
					throw err;
				}
			}
		}

		try {
			await currentPromise.promise;
		}
		finally {
			currentPromise = null;
		}
		return current;
	}

	static async countHousingProviders(): Promise<number> {
		return (await Axios.get(BaseRole.collectionUrl('housingProviders'))).data;
	}

}
