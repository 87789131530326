
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"id":"Group_2963","data-name":"Group 2963","xmlns":"http://www.w3.org/2000/svg","width":"20.828","height":"26","viewBox":"0 0 20.828 26"}, attrs),
              ...rest,
            },
            children.concat([_c('g',{attrs:{"id":"Rectangle_2176","data-name":"Rectangle 2176","fill":"#fff","stroke":"#152951","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}},[_c('rect',{attrs:{"width":"20.828","height":"26","rx":"2","stroke":"none"}}),_c('rect',{attrs:{"x":"0.75","y":"0.75","width":"19.328","height":"24.5","rx":"1.25","fill":"none"}})]),_c('line',{attrs:{"id":"Line_299","data-name":"Line 299","x2":"8.331","transform":"translate(8.331 7)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_300","data-name":"Line 300","x1":"2.083","transform":"translate(4.166 7)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_301","data-name":"Line 301","x2":"8.331","transform":"translate(8.331 11)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_302","data-name":"Line 302","x1":"2.083","transform":"translate(4.166 11)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_303","data-name":"Line 303","x2":"8.331","transform":"translate(8.331 15)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_304","data-name":"Line 304","x1":"2.083","transform":"translate(4.166 15)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_305","data-name":"Line 305","x2":"8.331","transform":"translate(8.331 19)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-width":"1.5"}}),_c('line',{attrs:{"id":"Line_306","data-name":"Line 306","x1":"2.083","transform":"translate(4.166 19)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-width":"1.5"}})])
          )
        }
      }
    