import Moment from 'moment';

import formatters from '$/lib/formatters';
import { createPdf, TCreatedPdf, pageBreakCheck, pageBreak, blank, banner, container, columns, outlinedContent, lightContent } from '$/lib/pdfmake';

import { CreditReportPdf }     from '$/screens/App/TenantScreening/components/PdfReport/CreditReportPdf';
import { TenantRecordsPdf }    from '$/screens/App/TenantScreening/components/PdfReport/TenantRecordsPdf';
import { BackgroundCheckPdf }  from '$/screens/App/TenantScreening/components/PdfReport/BackgroundCheckPdf';
import { TenantCheck }         from '$/entities/tenantScreening/TenantCheck';
import { CreditReport }        from '$/entities/tenantScreening/CreditReport';
import { BackgroundCheck }     from '$/entities/tenantScreening/BackgroundCheck';
import { TenantSearch }        from '$/entities/tenantScreening/TenantSearch';
import { EquifaxCreditReport } from '$/entities/tenantScreening/EquifaxCreditReport';
import { CertnCreditReport }   from '$/entities/tenantScreening/CertnCreditReport';
import { BaseRole }            from '$/entities/roles/BaseRole';
import { Address }             from '$/entities/Address';

import FrontLobbySVG from '$/assets/FrontLobby_logo_with_title_white.svg?raw';

export const pdfFormats = {
	formatDayMonthYear    : date => formatters.date(date ?? '', 'DD MMM Y'),
	formatMonthYear       : date => formatters.date(date ?? '', 'MMM Y'),
	formatterCurrency     : amount => formatters.currency(amount ?? 0),
	formatterLatePayments : latePayments => latePayments.map(payment => payment ?? 0).join('/'),
	capitalize            : value => value?.toUpperCase(),
	formatName            : value => `${value.firstName} ${value.middleName ?? ''}${value.middleName ? ' ' : ''}${value.lastName}`,
	address               : value => new Address(value).format(),
};

export interface PdfReports {
	creditReport?: CreditReport;
	backgroundCheck?: BackgroundCheck;
	tenantRecord?: TenantSearch;
}

export class TenantScreeningReport {

	private creditReport: CreditReport;
	private backgroundCheck: BackgroundCheck;
	private tenantRecord: TenantSearch;

	private reportPdf: CreditReportPdf;
	private tenantRecordsPdf: TenantRecordsPdf;
	private backgroundCheckPdf: BackgroundCheckPdf;

	private pdf: TCreatedPdf;

	constructor(reports: PdfReports) {
		this.creditReport    = reports.creditReport;
		this.backgroundCheck = reports.backgroundCheck;
		this.tenantRecord    = reports.tenantRecord;

		const content = [
			this.requestedBy(this.creditReport ?? this.backgroundCheck),
			this.applicationInfo(this.creditReport ?? this.backgroundCheck),
		];

		// Credit report
		if (this.creditReport) {
			this.reportPdf = new CreditReportPdf(this.creditReport);

			content.push(...this.reportPdf.content);

			if (this.tenantRecord || this.backgroundCheck) {
				content.push(pageBreak);
			}
		}

		if (this.tenantRecord) {
			this.tenantRecordsPdf = new TenantRecordsPdf(this.tenantRecord);
			content.push(...this.tenantRecordsPdf.content);

			if (this.backgroundCheck) {
				content.push(pageBreak);
			}
		}

		// Background check
		if (this.backgroundCheck) {
			this.backgroundCheckPdf = new BackgroundCheckPdf(this.backgroundCheck);

			content.push(...this.backgroundCheckPdf.content);
		}

		// Legal
		content.push(this.legal);

		this.pdf = createPdf({
			pageSize        : 'LETTER',
			pageMargins     : [ 16, 60, 16, 36 ],
			header          : [ this.header, blank() ],
			footer          : (currentPage, pageCount) => [ blank(), this.footer(currentPage, pageCount) ],
			pageBreakBefore : pageBreakCheck,
			content,
		});
	}

	get header() {
		return banner(columns(
			Object.assign(
				columns({
					svg              : '<svg viewBox="0 0 16 16" fill="#FFF"><path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"></path></svg>',
					height           : 16,
					width            : 16,
					relativePosition : { x : -8, y : 1 },
				}, {
					text             : _.upperCase('Tenant Screening Report'),
					relativePosition : { x : -4 },
					style            : 'pageHeader',
				}),
				{ margin : 16 }
			),
			{
				svg    : FrontLobbySVG,
				width  : 100,
				margin : [ -16, 8 ],
			}
		));
	}

	footer(currentPage, pageCount) {
		return banner({
			text   : `Page ${currentPage} of ${pageCount}`,
			style  : 'pageFooter',
			margin : [ 0, 4, 0, 4 ],
		});
	}

	requestedBy(report: TenantCheck) {
		return container({ wrapper : outlinedContent, padding : 8 },
			{ text : `${report.label} as of ${Moment(report.updatedOn).format('LL')}`, style : 'bold' },
			{ text : `Requested By: ${report.org?.name || report.requestedBy?.fullName}`, style : 'bold' }
		);
	}

	applicationInfo(report: TenantCheck) {
		const applicationInfo =  [
			{ label : 'Name', value : report.application.fullName },
			{ label : 'Current Address', value : report.application.fullAddress },
			{ label : 'Date of Birth', value : formatters.date(report.application.dateOfBirth, 'MMMM DD, YYYY') },
		].map(({ label, value }) => columns(
			{ text : `${label}:`, width : '15%', style : 'bold' },
			{ text : value, style : 'muted' }
		));

		return container(
			{
				title   : 'Applicant Information Submitted',
				wrapper : lightContent,
				padding : 8,
				spacing : 2,
				margin  : [ 0, 12, 0, 0 ],
			}, ...applicationInfo);
	}

	get legal() {
		return container(
			{
				title  : 'Legal',
				margin : [ 0, 12, 0, 0 ],
			},
			{
				text : [
					'This report is subject to the Terms of Use posted on ',
					{ text : 'www.frontlobby.com', style : 'link' },
					' as applicable, and may only be viewed with the consent of the individual\n',
					'and in connection with the entering into or renewal of a tenancy agreement or other reason authorized by the individual or applicable legislation.\n',
					'You may not distribute this report to anyone except those who require it for such valid purpose.',
				],
			}
		);
	}

	open() {
		this.pdf.open();
	}

	static getSample(role: BaseRole) {
		return new this(
			role.org.isCanadian
				? {
					creditReport    : EquifaxCreditReport.getSample(role),
					tenantRecord    : TenantSearch.getSample(role, role.org.country),
					backgroundCheck : BackgroundCheck.getSample(role),
				}
				: {
					creditReport : CertnCreditReport.getSample(role),
				}
		);
	}

}
