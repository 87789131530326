






















import { Component, Mixins, Prop } from '$/lib/vueExt';
import VueValidationMixin          from '$/lib/mixins/VueValidationMixin';

/**
 * An input field for entering URL values.
 */
@Component({
	inheritAttrs : false,
	model        : { event : 'update:value' },
})
export default class FormUrl extends Mixins(VueValidationMixin) {

	@Prop({ default : '' })
	readonly value: string;

	@Prop({ default : false })
	readonly plaintext: boolean;

	/**
	 * The size of the form is extracted from $attrs to be applied to both the InputGroup and the FormInput
	 */
	@Prop({ default : 'md' })
	readonly size: string;

	get inputValue() {
		return this.value;
	}
	set inputValue(value) {
		this.$emit('update:value', value);
	}

}
