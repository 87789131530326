import { Country } from '$/lib/Address';

// Persona inquiry check failures
export enum PersonaCheck {
	// expected user checks
	IDColour            = 'id_color_inconsistency_detection',
	Blur                = 'id_blur_detection',
	Expired             = 'id_expired_detection',
	IDDisallowedCountry = 'id_disallowed_country_detection',
	IDDisallowedType    = 'id_disallowed_type_detection',
	IDMRZ               = 'id_mrz_detection',
	IDPOBox             = 'id_po_box_detection',
	IDPortrait          = 'id_portrait_detection',
	IDPortraitClarity   = 'id_portrait_clarity_detection',

	// potential fraud checks
	IDElectronicReplicaDetection = 'id_electronic_replica_detection',
	IDRepeat                     = 'id_repeat_detection',
	IDInquiryComparison          = 'id_inquiry_comparison',
	IDPaperDetection             = 'id_paper_detection',

	// selfie checks
	SelfieIDComparison              = 'selfie_id_comparison',
	SelfiePoseDetection             = 'selfie_pose_detection',
	SelfieMultipleFacesDetection    = 'selfie_multiple_faces_detection',
	SelfiePoseRepeatDetection       = 'selfie_pose_repeat_detection',
	SelfieSuspiciousEntityDetection = 'selfie_suspicious_entity_detection',
	SelfieLivenessDetection         = 'selfie_liveness_detection',
	SelfiePublicFigureDetection     = 'selfie_public_figure_detection',
	SelfieFaceCoveringDetection     = 'selfie_face_covering_detection',

	// custom FrontLobby Persona check failures
	MissingFirstName    = 'fl_missingFirstName',
	MissingLastName     = 'fl_missingLastName',
	MissingDateOfBirth  = 'fl_missingDateOfBirth',
	MismatchFirstName   = 'fl_mismatchFirstName',
	MismatchLastName    = 'fl_mismatchLastName',
	MismatchDateOfBirth = 'fl_mismatchDateOfBirth',
	Generic             = 'fl_genericFailure',
}

export const warningOnlyChecks = [ PersonaCheck.IDElectronicReplicaDetection, PersonaCheck.IDPaperDetection ] as PersonaCheck[];

export const PersonaTestUsers = {
	ALEXANDER : {
		firstName      : 'ALEXANDER J',
		middleName     : '',
		lastName       : 'SAMPLE',
		unitNumber     : '',
		street         : '600 CALIFORNIA STREET',
		city           : 'SAN FRANCISCO',
		province       : 'CA',
		country        : Country.US,
		postalCode     : '94109',
		dateOfBirth    : new Date('1977-07-17 0:00'),
		nationalID     : '719029506',
		phoneNumber    : '+14168498284',
		assessmentPass : true,
	},
};
