


























import { Vue, Component, Prop, Watch, Ref } from '$/lib/vueExt';
import { type Resource, ResourceAction }    from '$/lib/resources';
import { DeferredPromise } from '$/lib/utils';
import log                 from '$/lib/logger';

/**
 * Resource viewer is a variant of FileViewer but uses bootstrap modal instead of the third party lightbox
 * It's mainly used for showing agreements and other documents from the resource section
 */
@Component({
	model : {
		prop  : 'resource',
		event : 'input',
	},
})
export default class ResourceViewer extends Vue {

	@Prop({ type : Object })
	readonly resource: Resource;

	/**
	 * Additional data to be logged when the resource is closed
	 */
	@Prop({ default : null })
	readonly additionalLogData: Dictionary<any>;

	/**
	 * The source of the resource (added to as a prefix to the watched log message)
	 */
	@Prop({ default : '' })
	readonly source: string;

	@Ref()
	readonly iframe: HTMLIFrameElement;

	@Ref()
	readonly video: HTMLVideoElement;

	/**
	 * The maximum duration the user has watched (so if they go back and forth we still count the max as 'viewed' content)
	 */
	videoMaxProgress = 0;

	get isVideo() {
		return this.resource?.action === ResourceAction.ShowVideo;
	}

	@Watch('resource', { immediate : true })
	async onResourceChange(resource: Resource) {
		if (!resource) {
			return;
		}

		if (resource.action === ResourceAction.Download) {
			window.open(resource.url, '_blank', 'noopener noreferrer');
			this.$emit('input', null);
		}
		else if (resource.action === ResourceAction.Visit) {
			window.open(resource.url, '_blank');
			this.$emit('input', null);
		}
		else if (resource.action === ResourceAction.HTML) {
			await _.delay(500); // add a bit of delay for the modal to render then use the $ref within the modal
			this.iframe.contentWindow.document.write(resource.html);
		}
	}

	hide(event: Event) {
		let videoStats;

		if (this.isVideo) {
			// Before hiding send some data about how far along the video the user got
			videoStats = {
				maxProgress : this.videoMaxProgress.toFixed(0),
				duration    : this.video?.duration.toFixed(0) || 0,
			};
			this.videoMaxProgress = 0;

			log.user([ this.source, 'resource.watched' ].filter(Boolean).join('.'), {
				stats    : videoStats,
				resource : this.resource.key,
				...this.additionalLogData,
			});
		}

		this.$emit('hide', event, { videoStats }); // When this event is received from the parent the resource field is still populated
		this.$emit('input', null);
	}

	onVideoProgressChanged() {
		if (this.video) {
			this.videoMaxProgress = Math.max(this.videoMaxProgress, this.video.currentTime);
		}
	}

	/**
	 * Show the given resource in a global ResourceViewer instance
	 * @param resource The resource to show
	 * @param source The source of the resource (added to as a prefix to the watched log message)
	 * @param additionalLogData Additional data to be logged when the resource is closed
	 * @returns a promise that resolves when the resource is closed
	 */
	static showResource(resource: Resource, { source = '', additionalLogData = null }: ShowResourceOptions = {}): Promise<void> {
		const deferredPromise = new DeferredPromise<void>();

		// doesn't matter what element it mounts to since b-modal moves itself to the $root element anyway
		const resourceViewerInstance = new this({ propsData : { resource, source, additionalLogData } });

		// resolve if the resource is set to null
		resourceViewerInstance.$on('input', value => {
			if (value === null) {
				deferredPromise.resolve();
			}
		});

		resourceViewerInstance.$mount(document.createElement('div'));
		return deferredPromise.promise;
	}

}

export interface ShowResourceOptions {
	source?: string;
	additionalLogData?: Dictionary<any>;
}
