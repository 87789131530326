import { getEntityClass } from '$/entities/BaseEntity';

import type { Affiliate }              from '$/entities/Affiliate';
import type { Authentication }         from '$/entities/Authentication';
import type { BaseEntity }             from '$/entities/BaseEntity';
import type { Building }               from '$/entities/Building';
import type { Comment }                from '$/entities/Comment';
import type { File }                   from '$/entities/File';
import type { Lease }                  from '$/entities/Lease';
import type { LeaseBalance }           from '$/entities/LeaseBalance';
import type { LeaseDraft }             from '$/entities/LeaseDraft';
import type { Log }                    from '$/entities/Log';
import type { NationalID }             from '$/entities/NationalID';
import type { Organization }           from '$/entities/Organization';
import type { Package }                from '$/entities/Package';
import type { ReportingGraph }         from '$/entities/ReportingGraph';
import type { Tenant }                 from '$/entities/Tenant';
import type { TestEntity }             from '$/entities/TestEntity';
import type { TriconOrganization }     from '$/entities/TriconOrganization';
import type { User }                   from '$/entities/User';
import type { EquifaxCATenantMetro2 }  from '$/entities/reporting/EquifaxCATenantMetro2';
import type { EquifaxUSTenantMetro2 }  from '$/entities/reporting/EquifaxUSTenantMetro2';
import type { ExperianUSTenantMetro2 } from '$/entities/reporting/ExperianUSTenantMetro2';
import type { LcbCATenantMetro2 }      from '$/entities/reporting/LcbCATenantMetro2';
import type { LcbUSTenantMetro2 }      from '$/entities/reporting/LcbUSTenantMetro2';
import type { TenantMetro2 }           from '$/entities/reporting/TenantMetro2';
import type { Applicant }              from '$/entities/roles/Applicant';
import type { BaseRole }               from '$/entities/roles/BaseRole';
import type { Collections }            from '$/entities/roles/Collections';
import type { Landlord }               from '$/entities/roles/Landlord';
import type { Renter }                 from '$/entities/roles/Renter';
import type { Email }                  from '$/entities/emails/Email';
import type { BaseCharge }             from '$/entities/billing/BaseCharge';
import type { Coupon }                 from '$/entities/billing/Coupon';
import type { Discount }               from '$/entities/billing/Discount';
import type { Application }            from '$/entities/tenantScreening/Application';
import type { BackgroundCheck }        from '$/entities/tenantScreening/BackgroundCheck';
import type { CertnCreditReport }      from '$/entities/tenantScreening/CertnCreditReport';
import type { EquifaxCreditReport }    from '$/entities/tenantScreening/EquifaxCreditReport';
import type { TenantCheck }            from '$/entities/tenantScreening/TenantCheck';
import type { TenantSearch }           from '$/entities/tenantScreening/TenantSearch';
import type { PersonaVerification }    from '$/entities/verifications/PersonaVerification';
import type { KountVerification }      from '$/entities/verifications/KountVerification';
import type { UrlVerification }        from '$/entities/verifications/UrlVerification';
import type { DocumentsVerification }  from '$/entities/verifications/DocumentsVerification';

/**
 * This can be used to access the entity classes at run-time (rather than importing) to solve circular dependency issues.
 * These values will all be valid shortly after startup.
 * SHOULDDO: automate building this object by scanning the entities directory at build-time.
 */
export const entity = {
	get Affiliate() {
		return getEntityClass<typeof Affiliate>('Affiliate');
	},
	get Authentication() {
		return getEntityClass<typeof Authentication>('Authentication');
	},
	get BaseEntity() {
		return getEntityClass<typeof BaseEntity>('BaseEntity');
	},
	get Building() {
		return getEntityClass<typeof Building>('Building');
	},
	get Comment() {
		return getEntityClass<typeof Comment>('Comment');
	},
	get File() {
		return getEntityClass<typeof File>('File');
	},
	get Lease() {
		return getEntityClass<typeof Lease>('Lease');
	},
	get LeaseBalance() {
		return getEntityClass<typeof LeaseBalance>('LeaseBalance');
	},
	get LeaseDraft() {
		return getEntityClass<typeof LeaseDraft>('LeaseDraft');
	},
	get Log() {
		return getEntityClass<typeof Log>('Log');
	},
	get NationalID() {
		return getEntityClass<typeof NationalID>('NationalID');
	},
	get Organization() {
		return getEntityClass<typeof Organization>('Organization');
	},
	get Package() {
		return getEntityClass<typeof Package>('Package');
	},
	get ReportingGraph() {
		return getEntityClass<typeof ReportingGraph>('ReportingGraph');
	},
	get Tenant() {
		return getEntityClass<typeof Tenant>('Tenant');
	},
	get TestEntity() {
		return getEntityClass<typeof TestEntity>('TestEntity');
	},
	get TriconOrganization() {
		return getEntityClass<typeof TriconOrganization>('TriconOrganization');
	},
	get User() {
		return getEntityClass<typeof User>('User');
	},
	get EquifaxCATenantMetro2() {
		return getEntityClass<typeof EquifaxCATenantMetro2>('EquifaxCATenantMetro2');
	},
	get EquifaxUSTenantMetro2() {
		return getEntityClass<typeof EquifaxUSTenantMetro2>('EquifaxUSTenantMetro2');
	},
	get ExperianUSTenantMetro2() {
		return getEntityClass<typeof ExperianUSTenantMetro2>('ExperianUSTenantMetro2');
	},
	get LcbCATenantMetro2() {
		return getEntityClass<typeof LcbCATenantMetro2>('LcbCATenantMetro2');
	},
	get LcbUSTenantMetro2() {
		return getEntityClass<typeof LcbUSTenantMetro2>('LcbUSTenantMetro2');
	},
	get TenantMetro2() {
		return getEntityClass<typeof TenantMetro2>('TenantMetro2');
	},
	get Applicant() {
		return getEntityClass<typeof Applicant>('Applicant');
	},
	get BaseRole() {
		return getEntityClass<typeof BaseRole>('BaseRole');
	},
	get Collections() {
		return getEntityClass<typeof Collections>('Collections');
	},
	get Landlord() {
		return getEntityClass<typeof Landlord>('Landlord');
	},
	get Renter() {
		return getEntityClass<typeof Renter>('Renter');
	},
	get Email() {
		return getEntityClass<typeof Email>('Email');
	},
	get BaseCharge() {
		return getEntityClass<typeof BaseCharge>('BaseCharge');
	},
	get Coupon() {
		return getEntityClass<typeof Coupon>('Coupon');
	},
	get Discount() {
		return getEntityClass<typeof Discount>('Discount');
	},
	get Application() {
		return getEntityClass<typeof Application>('Application');
	},
	get BackgroundCheck() {
		return getEntityClass<typeof BackgroundCheck>('BackgroundCheck');
	},
	get CertnCreditReport() {
		return getEntityClass<typeof CertnCreditReport>('CertnCreditReport');
	},
	get EquifaxCreditReport() {
		return getEntityClass<typeof EquifaxCreditReport>('EquifaxCreditReport');
	},
	get TenantCheck() {
		return getEntityClass<typeof TenantCheck>('TenantCheck');
	},
	get TenantSearch() {
		return getEntityClass<typeof TenantSearch>('TenantSearch');
	},
	get PersonaVerification() {
		return getEntityClass<typeof PersonaVerification>('PersonaVerification');
	},
	get KountVerification() {
		return getEntityClass<typeof KountVerification>('KountVerification');
	},
	get UrlVerification() {
		return getEntityClass<typeof UrlVerification>('UrlVerification');
	},
	get DocumentsVerification() {
		return getEntityClass<typeof DocumentsVerification>('DocumentsVerification');
	},
};
