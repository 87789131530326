/**
 * Generates various random values.
 */
import Moment                 from 'moment';
import { isValidPhoneNumber } from 'libphonenumber-js';
import env                    from '$/lib/env';
import { Country }            from '$/lib/Address';

export class Random {

	/**
	 * Generates a random string.
	 * @param {Number} length is the size of the string to generate
	 * @param {PossibleArray<String>} [population] is the optional, set of characters from which to compose the result string
	 *            if this is a string, then result is a random composition of these characters
	 *            if this is an array of strings, then the result is composed of alternating
	 *            characters from the set of characters of each element
	 *            if omitted, result is a composed of alternating consonants and vowels producing a roughly pronounceable word
	 */
	string(length: number, population: PossibleArray<string> = [ 'bcdfghjklmnpqrstvwxz', 'aeiouy' ]): string {
		const r = [];
		if (typeof population === 'string') {
			population = [ population ];
		}

		let j = 0;
		for (let i = 0; i < length; i++) {
			// SHOULDDO SECURITY: This should be using crypto, not _.random, since salts benefit from CRNG (https://www.owasp.org/index.php/Password_Storage_Cheat_Sheet)
			r.push(_.sample(population[j++]));
			if (j >= population.length) {
				j = 0;
			}
		}
		return r.join('');
	}

	/**
	 * @returns a valid Canadian postal code
	 */
	postalCode(country = Country.CA) {
		if (country === Country.US) {
			return (`${this.integer(0, 99999)}`).padStart(5, '0');
		}
		return [
			this.string(3, [ 'ABCEGHJKLMNPRSTVXY', '0123456789', 'ABCEGHJNPRSTVZ' ]),
			' ',
			this.string(3, [ '0123456789', 'ABCEGHJNPRSTVZ', '0123456789' ]),
		].join('');
	}

	/**
	 * @returns picks a random element from an array
	 */
	pickOne<T>(array: T[] | Dictionary<T>): T {
		return _.sample(array);
	}

	integer(min: number, max: number) {
		return _.random(min, max);
	}

	/**
	 * @returns a random date between min and max
	 */
	date({ min = new Date(0), max = new Date(2200, 0, 1) }: { min?: Date; max?: Date } = {}): Date {
		return Moment(0).add(this.integer(min.valueOf(), max.valueOf())).toDate();
	}

	/**
	 * @returns a person's first/given name
	 */
	firstName({ gender }: { gender?: 'male' | 'female' } = {}) {
		gender ||= this.pickOne([ 'male', 'female' ]);

		return this.pickOne({
			male : [
				'James', 'John', 'Robert', 'Michael', 'William', 'David', 'Richard', 'Joseph', 'Charles', 'Thomas', 'Christopher', 'Daniel',
				'Matthew', 'George', 'Donald', 'Anthony', 'Paul',
			],
			female : [
				'Mary', 'Emma', 'Elizabeth', 'Minnie', 'Margaret', 'Ida', 'Alice', 'Bertha', 'Sarah', 'Annie', 'Clara', 'Ella', 'Florence',
				'Cora', 'Martha', 'Laura', 'Nellie', 'Grace', 'Carrie', 'Maude', 'Mabel', 'Bessie',
			],
		}[gender]);
	}

	/**
	 * @returns a person's last/family name
	 */
	lastName() {
		return this.pickOne([
			'Smith', 'Johnson', 'Williams', 'Jones', 'Brown', 'Davis', 'Miller', 'Wilson', 'Moore', 'Taylor', 'Anderson', 'Thomas', 'Jackson',
			'White', 'Harris', 'Martin', 'Thompson', 'Garcia', 'Martinez', 'Robinson', 'Clark', 'Rodriguez', 'Lewis', 'Lee', 'Walker', 'Hall',
			'Allen', 'Young', 'Hernandez',
		]);
	}

	fullName({ middle = false } = { }) {
		return `${this.firstName() + (middle ? ` ${this.firstName()}` : '')} ${this.lastName()}`;
	}

	fileExtension() {
		return this.pickOne([ 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'txt' ]);
	}

	birthday({ type = 'adult' }: { type?: 'adult' | 'child' } = {}): Date {
		const age = type === 'child' ? this.integer(1, 17) : this.integer(18, 100);

		return Moment().startOf('year')
			.add(this.integer(0, 365), 'days')
			.subtract(age, 'years')
			.toDate()
		;
	}

	email({ domain = `dev.${env.domain}`, length = 10 }: { domain?: string; length?: number } = {}) {
		return `${this.string(length, 'abcdefghijklmnopqrstuvwxyz0123456789')}${_.ensureStartsWith(domain, '@')}`;
	}

	/**
	 * @returns a valid North American phone number.
	 */
	phoneNumber(): string {
		let phoneNumber: string;
		do {
			const areacode   = `${this.integer(2, 9)}${this.integer(0, 8)}${this.integer(0, 9)}`;
			const exchange   = `${this.integer(2, 9)}${this.integer(0, 9)}${this.integer(0, 9)}`;
			const subscriber = this.integer(1000, 9999);
			phoneNumber      = `+1${areacode}${exchange}${subscriber}`;
		} while (!isValidPhoneNumber(phoneNumber, 'US'));
		return phoneNumber;
	}

	/**
	 * @returns A random city name.
	 */
	city(): string {
		return _.capitalize(this.string(10));
	}

	/**
	 * @returns a random street address.
	 */
	street() {
		const streetName = this.pickOne([
			'Maple', 'Oak', 'Pine', 'Cedar', 'Elm', 'Birch', 'Spruce', 'Willow', 'Ash', 'Cherry',
			'Hickory', 'Walnut', 'Chestnut', 'Poplar', 'Sycamore', 'Beech', 'Alder', 'Hawthorn', 'Juniper', 'Magnolia',
		]);
		const streetType      = this.pickOne([ 'St', 'Ave', 'Blvd', 'Cres', 'Rd', 'Dr', 'Ct', 'Pl', 'Way', 'Ln', 'Trl', 'Cir', 'Hwy' ]);
		const streetDirection = this.pickOne([ '', this.pickOne([ 'N', 'S', 'E', 'W' ]) ]);
		return [ this.integer(1, 2000), streetName, streetType, streetDirection ].join(' ').trim();
	}

	/**
	 * @returns a random company name.
	 */
	companyName() {
		// COULDDO: compose a random company name from multiple random words for more variety
		return this.pickOne([
			'Guidant Corporation', 'Golden West Financial Corporation', 'Best Buy Co., Inc.', 'Harris Corp.', 'Arch Coal, Inc.',
			'Value City Department Stores Inc', 'Fifth Third Bancorp', 'Conseco Inc.', 'Robert Half International Inc.', 'Tribune Company',
			'Cendant Corp', 'USG Corporation', 'Western Gas Resources Inc', 'General Cable Corporation', 'Apple Computer, Inc.',
			'Alaska Air Group, Inc.', 'Mandalay Resort Group', 'First American Financial Corp.', 'Qwest Communications Intl Inc',
			"Harrah's Entertainment Inc.", 'Questar Corp', 'Landstar System Inc.', 'Convergys Corp.', 'Honeywell International Inc.',
			'Intuit Inc.',
		]);
	}

	paragraph({ sentences = 3 }: { sentences?: number} = {}) {
		return _.times(sentences, () => this.sentence()).join(' ');
	}

	sentence({ words = this.integer(10, 20) }: { words?: number } = {}) {
		return _.capitalize(`${_.times(words, () => this.string(this.integer(2, 10))).join(' ')}.`);
	}

}

export default new Random();
