







































import { Vue, Component, Prop, Watch }         from '$/lib/vueExt';
import { UrlVerification, VerificationStatus } from '$/entities/verifications/UrlVerification';

@Component
export default class UrlVerificationReviewer extends Vue {

	@Prop({ default : null })
	readonly verification: UrlVerification | null;

	feedback = '';
	feedbackState: boolean | null = null;

	get sanitizedUrl(): string {
		const url = this.verification?.url;
		if (!url) {
			return '';
		}
		return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
	}

	@Watch('verification')
	onVerificationChange() {
		this.feedback      = '';
		this.feedbackState = null;
		this.verification?.makeEditable();
	}

	async review(approved: boolean) {
		if ((!approved && !this.feedback) || (approved && this.feedback)) {
			this.feedbackState = false;
			return;
		}

		this.verification.status  = approved ? VerificationStatus.Approved : VerificationStatus.Rejected;
		this.verification.reasons = approved ? [] : [ this.feedback ];

		await this.verification.save();
		this.onHidden();
	}

	onHidden() {
		this.$emit('update:verification', null);
	}

}
