import BasePreferences from '$/entities/lib/BasePreferences';
import { Field }       from '$/entities/lib/JSONable';

export enum LandlordInterestTypes {
	RecoverDebt   = 'recoverDebt',
	BuildCredit   = 'buildCredit',
	PullCredit    = 'pullCredit',
	RecordKeeping = 'recordKeeping',
	EnsureRent    = 'ensureRent',
}

export class LandlordInterests extends BasePreferences {

	@Field()
	[LandlordInterestTypes.RecoverDebt]?: boolean;

	@Field()
	[LandlordInterestTypes.BuildCredit]?: boolean;

	@Field()
	[LandlordInterestTypes.PullCredit]?: boolean;

	@Field()
	[LandlordInterestTypes.RecordKeeping]?: boolean;

	@Field()
	[LandlordInterestTypes.EnsureRent]?: boolean;

}
