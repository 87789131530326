import Moment       from 'moment';
import { LRUCache } from 'lru-cache';

import { Country }             from '$/lib/Address';
import Validate, { nameChars } from '$/lib/Validate';
import { Index, Unique }       from '$/lib/typeormExt';
import env                     from '$/lib/env';
import { ValidationWarning }   from '$/lib/validation/ValidationIssue';

import Permissions, { Context }        from '$/entities/lib/Permissions';
import { TenantEmailPrefs }            from '$/entities/lib/BasePreferences';
import { getFullName }                 from '$/entities/User';
import { BaseOrgEntity, Organization } from '$/entities/Organization';
import { RolePermission }              from '$/entities/roles/RolePermission';
import { TenantSearch }                from '$/entities/tenantScreening/TenantSearch';
import { Agreement }                   from '$/entities/Agreement';
import { Landlord }                    from '$/entities/roles/Landlord';
import { NationalID }                  from '$/entities/NationalID';
import { Email }                       from '$/entities/emails/Email';
import { LeaseBalanceStatus }          from '$/entities/LeaseBalanceExt';
import type { Renter }                 from '$/entities/roles/Renter';
import type { Lease }                  from '$/entities/Lease';
import type { Comment }                from '$/entities/Comment';
import type { TenantMetro2 }           from '$/entities/reporting/TenantMetro2';
import { Column, ManyToOne, OneToMany, CommonEntity, getEntityClass, OneToOne, EntityID } from '$/entities/BaseEntity';

// One Hour cache for Landlord entities
const landlordCache: LRUCache<EntityID, Landlord[]> = new LRUCache({ ttl : Moment.duration(1, 'hour').asMilliseconds(), max : 1000 });

export function sanitizeTenantName(name: string) {
	return name.replace(/\(.*\)/, '').replace(/\./g, ' ').replace(/\s\s+/g, ' ').trim();
}

// Equifax Canada requested that tenants be unable to withdraw consent after giving it
// This date is when that change was made to the copy to specify that. Anyone who consented before that date didn't agree to indefinite consent
export const tenantCannotWithdrawConsentDate = Moment('2023-08-03').toDate();

/**
 * A Tenant represents a person renting a specific property from a landlord for a period of time.
 * These entities are created by landlords.
 */
@CommonEntity()
@Unique([ 'externalIdUnique' ])
export class Tenant extends BaseOrgEntity {

	@Column({ type : 'varchar' })
	@Validate({ required : true, email : true, trimmed : true, custom : value => value ? Email.validateAddress(value) : '' })
	@Permissions({ read : securityFreezeCheck })
	@Index()
	email: EmailAddress = '';

	@Column()
	@Validate({ required : true, allowChars : { value : nameChars }, trimmed : true })
	firstName: string = '';

	@Column()
	@Validate({ allowChars : { value : nameChars }, trimmed : true })
	@Permissions({ read : securityFreezeCheck })
	middleName: string = '';

	@Column()
	@Index()
	@Validate({ required : true, allowChars : { value : nameChars }, trimmed : true, custom : tenantSimilarToLandlordCheck })
	lastName: string = '';

	/**
	 * Used to optionally link tenant record to records in external systems
	 */
	@Column({ type : 'varchar', length : 50 })
	@Validate({ maxLength : 50 })
	externalId: string = '';

	/**
	 * Used to create a DB constraint so that no org can have the same externalId
	 */
	@Column({ asExpression : 'IF (externalId <> "", CONCAT(orgId, ":", externalId), NULL)', nullable : true })
	private externalIdUnique: string = null;

	@Column()
	@Permissions({ read : securityFreezeCheck })
	suffix: string = '';

	@Column({ type : 'date', nullable : true })
	@Validate({ date : true, custom : tenantDateOfBirthCheck })
	@Permissions({ read : securityFreezeCheck })
	dateOfBirth: Date;

	@Column()
	@Validate({
		phoneNumber : {
			strict  : false,
			country : async function() {
				await this.loadRelation('lease.building');
				return this.lease?.building?.country;
			},
		},
	})
	@Permissions({ read : securityFreezeCheck })
	phoneNumber: string = '';

	@Column({ type : 'date', nullable : true })
	@Validate({ date : true, custom : checkMoveIn })
	@Permissions({ read : securityFreezeCheck })
	moveIn: Date;

	@Column({ type : 'date', nullable : true })
	@Validate({ date : true, custom : checkMoveOut })
	@Permissions({ read : securityFreezeCheck })
	moveOut: Date;

	@Column()
	@Permissions({ read : securityFreezeCheck })
	firstNameAlt: string = '';

	@Column()
	@Permissions({ read : securityFreezeCheck })
	lastNameAlt: string = '';

	@Column()
	@Permissions({ read : securityFreezeCheck })
	coSigner: boolean = false;

	@ManyToOne('Lease', { onDelete : 'CASCADE' })
	lease: Lease = undefined;

	@OneToMany('TenantSearch', 'tenant', { persistence : false })
	@Permissions({ read : securityFreezeCheck, write : Permissions.serverOnly })
	searchHistory: TenantSearch[];

	/**
	 * The Renter (if any) that is this tenant.
	 */
	@ManyToOne('Renter', { onDelete : 'SET NULL' })
	@Permissions({ read : securityFreezeCheck, write : Permissions.serverOnly })
	renter: Renter = undefined;

	@Column({ nullable : true })
	@Permissions({ write : [ Permissions.roleHasPermission(RolePermission.SecurityFreeze), Permissions.stopChecks ] })
	securityFreezeOn: Date = null;

	@Column({ nullable : true })
	@Permissions({ write : [ Permissions.roleHasPermission(RolePermission.TenantDisputes), Permissions.stopChecks ] })
	disputedOn: Date = null;

	/**
	 * Tracks whether or not tenant has consented to be reported to Equifax
	 */
	@Column({ type : 'json', nullable : true, default : () => "('{}')"  })
	@Permissions({
		read  : isRenterTheTenant,
		write : [ isRenterTheTenant, cannotRemoveConsent, Permissions.stopChecks ],
	})
	consentedToReport: Agreement = new Agreement();

	@Column({ type : 'json', nullable : true })
	@Permissions({
		read  : isRenterTheTenant,
		write : [ isRenterTheTenant, Permissions.stopChecks ],
	})
	emailPreferences: TenantEmailPrefs = new TenantEmailPrefs();

	@OneToOne('NationalID', 'tenant')
	@Validate({ custom : tenantNationalIDCheck })
	nationalID: NationalID = undefined;

	/**
	 * A shortcut to setting the national ID of the tenant
	 * Note: setter is asynchronous
	 */
	@Validate({ custom : tenantNationalIDCheck })
	get nationalIDValue() {
		return NationalID.getValue(this as any);
	}
	set nationalIDValue(newValue: string) {
		void NationalID.setValue(this as any, newValue, this.lease?.building?.country || Country.CA);
	}

	/**
	 * SHOULDDO: add a custom OneToMany relationship that uses the Comments 'referenceId,' format
	 * or create child entities for Comments and use the original typeorm OneToMany relationship
	 * NOTE: the OneToMany relation below is for typeorm to register this field as an entity field. DO NOT USE this relation
	*/
	@OneToMany('Comment', 'reference', { persistence : false })
	@Permissions({ write : Permissions.serverOnly, read : securityFreezeCheck })
	comments: Comment[];

	/**
	 * The unique ID for this tenant when being reported to Equifax
	 * Even though this field is 30 chars wide, Equifax USA only stores 20 characters.
	 */
	@Column({ length : '30' })
	@Permissions({ write : Permissions.serverOnly })
	consumerAccountNumber: string = '';

	@OneToMany('TenantMetro2', 'tenant', { persistence : false })
	tenantMetro2s: TenantMetro2[];

	get fullName() {
		return getFullName(this);
	}

	get fullNameAlt() {
		return [ this.firstNameAlt, this.lastNameAlt ].filter(value => !!value).join(' ');
	}

	/**
	 * Returns true if this tenant is for the sample lease (not meant to be saved)
	 */
	get isSample() {
		return false;
	}

	/**
	 * Returns true if this tenant has a verified renter associated with it
	 */
	get isVerified() {
		return (this as any).renterId && this.renter?.isVerified;
	}

	/**
	 * Returns true if this tenant has a renter associated with it
	 */
	get hasSignedUp() {
		return !!(this as any).renterId;
	}

	constructor(renter?: Renter) {
		super();
		if (renter) {
			this.renter = renter;
		}

		if (renter?.user) {
			this.firstName  = renter.user.firstName;
			this.middleName = renter.user.middleName;
			this.lastName   = renter.user.lastName;
			this.email      = renter.user.email;
			if (renter.user.dateOfBirth) {
				this.dateOfBirth = new Date(renter.user.dateOfBirth);
			}
		}
	}

	/**
	 * @returns true if there is another tenant that has very similar name and DoB criteria
	 */
	async hasDuplicate(): Promise<boolean> {
		const where: any = {
			orgId       : getOrgID(this),
			firstName   : this.firstName,
			lastName    : this.lastName,
			dateOfBirth : this.dateOfBirth,
		};

		if (this.middleName) {
			where.middleName = this.middleName;
		}

		return (await (this.constructor as typeof Tenant).find({ where })).some(tenant => (tenant as any).leaseId !== (this as any).leaseId);
	}

	/**
	 * Ensure that tenant isn't the last member on the lease or lease is also archived
	 */
	async archive() {
		await this.loadRelation('lease.tenants', { reload : true });

		// if lease is archived it won't be loaded into this.lease continue with archive
		// if there are more than one tenant on the lease continue with archive
		if (this.lease?.tenants?.length === 1) {
			throw new Error('Cannot archive Tenant. Non-archived Leases should have at least one Tenant');
		}

		await super.archive();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async loadSearchHistory({ force = false } = {}): Promise<TenantSearch[]> {
		throw new Error('not implemented');
	}

	/**
	 * The maximum move in date supported
	 */
	get maxMoveIn() {
		return this.lease?.isFormer ? Moment().toDate() : Moment().add(6, 'month').toDate();
	}

	/**
	 * The minimum age (in years) of tenants.
	 */
	static get minAge() {
		return 18;
	}


	/**
	 * The maximum value of this.dateOfBirth allowed based on the minAge constant.
	 */
	static get dateOfBirthMax(): Date {
		return Moment().subtract(Tenant.minAge, 'years').toDate();
	}

	/**
	 * Returns the sample Tenant.
	 */
	static getSample<T extends Tenant>(this: typeof Tenant, lease?: Lease): T {
		const sample         = new this();
		sample.id            = 'sample';
		sample.ver           = 1;	// prevents the sample from registering as new (via isNew)
		sample.firstName     = 'John';
		sample.middleName    = 'James';
		sample.lastName      = 'Doe';
		sample.dateOfBirth   = new Date(1987, 5, 23);
		sample.email         = 'john.james@email.com';
		sample.phoneNumber   = '+14161234567';
		sample.firstNameAlt  = 'John';
		sample.lastNameAlt   = 'Doe';
		sample.moveIn        = new Date(2018, 0, 1);
		sample.lease         = lease;
		sample.org           = getEntityClass<typeof Organization>('Organization').getSample();
		sample.searchHistory = [
			_.assign(new (getEntityClass('TenantSearch') as typeof TenantSearch)(), { createdOn : new Date(2001, 2, 2), org : new Organization('Doe Inc.') }),
		];
		Object.defineProperty(sample, 'isSample', { get : () => true });
		return sample as T;
	}

	async cannotRemoveConsent() {
		await this.loadRelation('lease.building');
		await this.loadRelation('lease.latestLeaseBalance');

		if (this.lease.building?.address.isCalifornian && this.lease.latestLeaseBalance?.status === LeaseBalanceStatus.PaidOnTime) {
			return false;
		}

		return this.consentedToReport.agreed && Moment(this.consentedToReport.date).isAfter(tenantCannotWithdrawConsentDate);
	}

}

async function checkMoveIn(this: Tenant, value) {
	if (!value) {
		return '';
	}

	if (Moment(value).isAfter(Moment(this.maxMoveIn), 'day')) {
		return `Date must be equal or earlier than: ${Moment(this.maxMoveIn).format('ll')}`;
	}

	// SHOULD DO change thing to check moveOut instead of leaseEndDate
	await this.loadRelation('lease');
	const endDate = this.lease.endDate;
	if (endDate && Moment(value).isAfter(endDate, 'month')) {
		return `${Moment(value).format('ll')} cannot be after the lease end date of ${Moment(endDate).format('ll')}`;
	}

	return '';
}

async function checkMoveOut(this: Tenant, value) {
	if (!value) {
		return '';
	}

	if (this.moveIn && Moment(value).isBefore(Moment(this.moveIn))) {
		return `Date must be after the move in date of ${Moment(this.moveIn).format('ll')}`;
	}

	return '';
}

function isRenterTheTenant(context: Context, tenant: Tenant) {
	if (context.role.id !== (tenant as any).renterId) {
		return 'you are not the renter associated with this tenant';
	}
}

async function cannotRemoveConsent(context: Context, tenant: Tenant) {
	return (await tenant.cannotRemoveConsent()) ? 'cannot remove consent after it has been given' : '';
}

export function securityFreezeCheck(context: Context, tenant: Tenant): string {
	// No need to redact data for Support
	if (context.role.hasPermission(RolePermission.SecurityFreeze)) {
		return;
	}

	// If tenant was created by this organization (the original Landlord is viewing the Tenant)
	if (context.org.id === tenant.orgId) {
		return;
	}

	if (tenant.securityFreezeOn) {
		return 'security freeze measures in place';
	}
}

async function tenantDateOfBirthOrNationalIDCheck(tenant: Tenant) {
	if (tenant.dateOfBirth) {
		return;
	}

	await tenant.loadRelation('nationalID');
	if (tenant.nationalID?.value) {
		return;
	}

	await tenant.loadRelation('lease', { required : true });
	if (tenant.lease.isCurrent && tenant.lease.rentReporting) {
		return new ValidationWarning('Date of birth or SIN/SSN is required for rent reporting');
	}
	if (tenant.lease.isFormer) {
		if (tenant.lease.debtReporting) {
			return new ValidationWarning('Date of birth or SIN/SSN is required for debt reporting');
		}

		if (tenant.lease.collections) {
			return 'Date of birth or SIN/SSN is required for collections';
		}
	}
}

async function tenantNationalIDCheck(this: Tenant) {
	return tenantDateOfBirthOrNationalIDCheck(this);
}

async function tenantDateOfBirthCheck(this: Tenant, dateOfBirth: Date) {
	return tenantIsOfMinimumAge(dateOfBirth) || tenantDateOfBirthOrNationalIDCheck(this);
}

export function tenantIsOfMinimumAge(value) {
	return !value || Moment(value).isSameOrBefore(Tenant.dateOfBirthMax, 'day') ? '' : `Minimum age on ${env.app.name.short} is: ${Tenant.minAge}`;
}

async function tenantSimilarToLandlordCheck(this: Tenant) {
	let landlords = landlordCache.get(getOrgID(this));
	if (!landlords) {
		landlords = await Landlord.find({ where : { orgId : getOrgID(this) }, relations : [ 'user' ] });
		landlordCache.set(getOrgID(this), landlords);
	}

	const where: any = {
		user : {
			firstName : this.firstName,
			lastName  : this.lastName,
		},
	};

	// use DoB (if available) to prevent accidental name clash between LLs and tenants with common names (eg: John Smith)
	if (this.dateOfBirth) {
		where.user.dateOfBirth = this.dateOfBirth;
	}
	else {
		// use email if DoB not available
		where.user.email = this.email;
	}

	return _.some(landlords, landlord => _.isMatch(landlord, where)) ? 'members of your organization cannot be added as tenants' : '';
}

/**
 * @returns the orgID for the given tenant
 */
// SHOULDDO: get rid of this once the "current" role/org are readily available everywhere or the orgId property can be relied upon even for new entities
function getOrgID(tenant: Tenant): EntityID {
	// new Tenant entities and leases don't always have the orgId readily available
	const orgId = tenant.orgId ?? tenant.lease.orgId ?? tenant.org?.id ?? tenant.lease.org?.id;

	if (!orgId) {
		throw new Error('cannot determine to which org this tenant belongs; set the org property first');
	}

	return orgId;
}
