


























import { Vue, Component, Prop, Watch } from '$/lib/vueExt';
import Validate                        from '$/lib/Validate';

import { User }                           from '$/entities/User';
import { Agreement as AgreementJSONable } from '$/entities/Agreement';

enum AgreementType {
	CheckBox = 'checkbox',
	Modal    = 'modal',
}

@Component({
	inheritAttrs : false,
})
export default class Agreement extends Vue {

	@Prop({ default : undefined })
	readonly agreement: AgreementJSONable;

	@Prop({ default : AgreementType.CheckBox })
	readonly type: AgreementType;

	@Prop({ default : false })
	readonly agreeOnly: boolean;

	@Prop({ default : false })
	readonly required: boolean;

	@Prop({ default : 'You must agree to the above.' })
	readonly requiredValidationMessage: string;

	hasChanged = false;

	private localAgreement: AgreementJSONable;

	@Watch('agreement', { immediate : true })
	onAgreementChange() {
		this.localAgreement = this.agreement ?? new AgreementJSONable(this.$role, User.current, false, new Date());
	}

	@Validate({
		custom : function() {
			// cannot use this.agreed here because it's the old cached value (cached by VueJS)
			return this.required && !this.localAgreement?.agreed ? this.requiredValidationMessage : '';
		},
	})
	get agreed() {
		return !!this.localAgreement?.agreed;
	}
	set agreed(value: boolean) {
		this.hasChanged = true;
		this.localAgreement.update(this.$role, User.current, value);
		this.$emit('update:agreement', this.localAgreement);
	}

}
