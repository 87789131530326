import '$/lib/charts/gauge';

import { getDataURL } from '$/lib/charts/utils';
import { columns, container, outlinedContent, stack, table } from '$/lib/pdfmake';
import { pdfFormats } from '$/screens/App/TenantScreening/components/PdfReport/TenantScreeningReport';

import { CreditReport }        from '$/entities/tenantScreening/CreditReport';
import { EquifaxCreditReport } from '$/entities/tenantScreening/EquifaxCreditReport';

import { TenantCheckPdf } from './TenantCheckPdf';

import EquifaxSVG from '$/assets/equifax/equifaxLogo.svg?raw';

const scoreRanges = [
	{ max : 559, range : '300 - 559', rating : 'Poor',      population : '4%',  width : 40  },
	{ max : 659, range : '560 - 659', rating : 'Fair',      population : '10%', width : 40 },
	{ max : 724, range : '660 - 724', rating : 'Good',      population : '15%', width : 40 },
	{ max : 759, range : '724 - 759', rating : 'Very Good', population : '14%', width : 40 },
	{ max : 900, range : '760 +',     rating : 'Excellent', population : '57%', width : 40 },
];

export class CreditReportPdf extends TenantCheckPdf {

	private report: CreditReport;
	content: any[];

	constructor(report: CreditReport) {
		super();
		this.report  = report;
		this.content = [
			this.sectionTitle('Consumer Credit Report', report instanceof EquifaxCreditReport ? {
				svg              : EquifaxSVG,
				width            : 80,
				alignment        : 'right',
				relativePosition : { x : 0, y : -1 },
			} : null),
		];

		if (this.report.isComplete && this.report.report) {
			this.content.push(
				outlinedContent(stack(
					this.creditScore,
					this.addresses,
					this.employment,
					this.tradelines,
					this.collections,
					this.bankruptcies,
					this.inquiries
				))
			);
		}
		else if (!this.report.report) {
			this.content.push(
				container({ margin : [ 0, 4, 0, 0 ], wrapper : outlinedContent }, { text : 'No results found', layout : 'default' })
			);
		}
		else {
			this.content.push(
				this.error(this.report)
			);
		}
	}

	get creditScore() {
		return container(
			{
				title  : 'Credit Score',
				margin : 8,
			},
			columns(this.chart, this.scoreTable)
		);
	}

	get addresses() {
		return container({ title : 'Addresses' }, {
			layout : 'lightTableWhiteHeader',
			table  : table(this.report.report.addresses, [
				{ key : 'status', width : 120 },
				{ key : 'address', formatter : value => pdfFormats.capitalize(pdfFormats.address(value)) },
			]),
		});
	}

	get employment() {
		return container({ title : 'Employment' }, {
			layout : 'lightTableWhiteHeader',
			table  : table(this.report.report.employers, [
				{ key : 'employer', formatter : pdfFormats.capitalize, width : 120 },
				{ key : 'occupation', formatter : pdfFormats.capitalize },
			]),
		});
	}

	get tradelines() {
		return container({ title : 'Tradelines' }, {
			layout : 'detailTable',
			margin : [ 0, 0, 0, 4 ],
			table  : table(this.report.report.tradelines, [
				{ key : 'creditor', label : 'Credit Grantor', formatter : pdfFormats.capitalize, width : 120 },
				{ key : 'type', label : 'Portfolio Type', width : 120 },
				{ key : 'dateOpened', formatter : pdfFormats.formatMonthYear },
				{ key : 'dateReported', label : 'Last Reported', formatter : pdfFormats.formatMonthYear },
				{ key : 'highCredit', formatter : pdfFormats.formatterCurrency },
				{ key : 'balance', label : 'Balance Amount', formatter : pdfFormats.formatterCurrency },
				{ key : 'pastDue', label : 'Past Due Amount', formatter : pdfFormats.formatterCurrency },
			], { detailFields : [
				{ key : 'rCode', label : 'R-Code' },
				{ key : 'latePayments', label : 'Late Payments 30/60/90+', formatter : pdfFormats.formatterLatePayments },
				{ key : 'paymentStatus' },
			] }),
		});
	}

	get collections() {
		return container({ title : 'Collections' }, {
			layout : 'lightTableWhiteHeader',
			table  : table(this.report.report.collections, [
				{ key : 'status' },
				{ key : 'creditor', label : "Creditor's Name", formatter : pdfFormats.capitalize },
				{ key : 'dateAssigned', formatter : pdfFormats.formatMonthYear },
				{ key : 'originalAmount', formatter : pdfFormats.formatterCurrency },
				{ key : 'balanceAmount', formatter : pdfFormats.formatterCurrency },
			]),
		});
	}

	get bankruptcies() {
		return container({ title : 'Bankruptcies' }, {
			layout : 'lightTableWhiteHeader',
			table  : table(this.report.report.bankruptcies, [
				{ key : 'dateFiled', formatter : pdfFormats.formatDayMonthYear },
				{ key : 'type', label : 'Account' },
				{ key : 'customer', formatter : pdfFormats.capitalize },
				{ key : 'assetAmount', formatter : pdfFormats.formatterCurrency },
				{ key : 'caseNumberAndTrustee', label : 'Case # & Trustee', formatter : pdfFormats.capitalize },
				{ key : 'filer' },
				{ key : 'liabilityAmount', formatter : pdfFormats.formatterCurrency },
				{ key : 'intentOrDisposition' },
			]),
		});
	}

	get inquiries() {
		// Would get a bit of weird spacing on bottom of outlined content without 0 bottom padding
		return container({ title : 'Credit Inquiries to the File', padding : [ 8, 8, 8, 0 ] }, {
			layout : 'lightTableWhiteHeader',
			table  : table(this.report.report.inquiries, [
				{ key : 'date', formatter : pdfFormats.formatDayMonthYear, width : 120 },
				{ key : 'name', label : 'Credit Granter', formatter : pdfFormats.capitalize },
			]),
		});
	}

	get scoreTable() {
		const scoreIndex = scoreRanges.findIndex(range => range.max >= this.report.report.score);

		function cell(text, index) {
			return {
				text,
				alignment : 'center',
				style     : {
					bold  : index === scoreIndex,
					color : '#222',
				},
			};
		}

		return Object.assign(
			stack(
				{
					text  : 'The Credit Score ranges from 300-900. Higher scores are viewed more favorably.',
					style : 'muted',
				},
				{
					layout : 'columnDivider',
					table  : {
						widths : [ '*', ...scoreRanges.map(range => range.width) ],
						body   : [
							[ 'Range', ...scoreRanges.map((range, i) => cell(range.range, i)) ],
							[ '', ...scoreRanges.map((range, i) => cell(range.rating, i)) ],
							[ 'Population', ...scoreRanges.map((range, i) => cell(range.population, i)) ],
						],
					},
				}
			),
			{
				alignment : 'right',
				margin    : [ 0, 25, 0, 0 ],
			}
		);
	}

	get chart() {
		const image = getDataURL({
			type : 'gauge',
			data : {
				labels   : scoreRanges.map(score => score.rating),
				datasets : [ {
					score           : this.report?.report?.score - 300,
					data            : [ 259, 100, 65, 35, 141 ],
					backgroundColor : [
						'#FF4E58',
						'#f49e23',
						'#ffc107',
						'#0e9135',
						'#01492c',
					],
					borderWidth : 0,
				} ],
			},
		});

		return {
			image,
			width  : 150,
			margin : 6,
		};
	}


}
