import { Field } from '$/entities/lib/JSONable';
import BasePreferences, { type Preference, RenterEmailKeys } from '$/entities/lib/BasePreferences';

// SHOULD DO: expose all email types as classes on the common side and use their constructor.name as keys
export class EmailPreferences extends BasePreferences {

	@Field()
	LandlordNoLogin?: Preference; // eslint-disable-line @typescript-eslint/naming-convention

	@Field()
	LandlordMonthlyEmail?: Preference; // eslint-disable-line @typescript-eslint/naming-convention

	@Field()
	[RenterEmailKeys.ConsentReminderEmail]?: Preference; // eslint-disable-line @typescript-eslint/naming-convention

	@Field()
	[RenterEmailKeys.MonthlyRenterConfirmationEmail]?: Preference; // eslint-disable-line @typescript-eslint/naming-convention

	@Field()
	DataContributorSecondEmail?: Preference; // eslint-disable-line @typescript-eslint/naming-convention

}
