import { ChildEntity, CommonEntity } from '$/entities/BaseEntity';
import { Verification }              from '$/entities/verifications/Verification';

export { VerificationStatus } from '$/entities/verifications/Verification';

/**
 * Implementation of identity verification by manual verification by a Support person
 **/
@ChildEntity() @CommonEntity()
export class ManualVerification extends Verification {

	get additionalData() {
		return this.additionalDataValue as {
			roleId?: string;		// the role of the user that manually set the verification status of this user/role (if known)
		};
	}

}
