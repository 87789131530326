import Errors                             from '$/lib/Errors';
import { Answers, Questionnaire, Result } from '$/lib/equifax/KountClient';

import { ChildEntity, CommonEntity } from '$/entities/BaseEntity';
import { Verification }              from '$/entities/verifications/Verification';

export { VerificationStatus } from '$/entities/verifications/Verification';

/**
 * Implementation of identity verification using Equifax's Kount verification service (knowledge-based test)
 */
@ChildEntity() @CommonEntity()
export class KountVerification extends Verification {

	get additionalData() {
		return this.additionalDataValue as any;		// massive Kount data that is too complicated to write types for
	}

	static getQuestions(): Promise<Questionnaire | Result> {
		throw new Errors.NotImplemented();
	}

	static submitAnswers(answers: Answers) { // eslint-disable-line @typescript-eslint/no-unused-vars
		throw new Errors.NotImplemented();
	}

	static getURL(suffix = ''): string {
		return `/api/user/verification/kount${_.ensureStartsWith(suffix, '/')}`;
	}

}
