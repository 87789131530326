import Moment                           from 'moment';
import { nameChars }                    from '$/lib/Validate';
import { Country }                      from '$/lib/Address';
import { OneToOne, SelectQueryBuilder } from '$/lib/typeormExt';
import { getEmailWithoutSubAddress }    from '$/lib/utils';

import { Column, OneToMany, ManyToOne, getEntityClass } from '$/entities/BaseEntity';
import { BaseOrgEntity, Organization }    from '$/entities/Organization';
import Permissions, { Context }           from '$/entities/lib/Permissions';
import { CommonEntity, Validate }         from '$/entities/BaseEntityExt';
import { TenantCheck, type SearchParams } from '$/entities/tenantScreening/TenantCheck';
import { User, getFullName }              from '$/entities/User';
import { Address }                        from '$/entities/Address';
import { Building }                       from '$/entities/Building';
import { Agreement }                      from '$/entities/Agreement';
import { Email }                          from '$/entities/emails/Email';
import { RolePermission }                 from '$/entities/roles/RolePermission';
import type { NationalID }                from '$/entities/NationalID';
import type { Applicant }                 from '$/entities/roles/Applicant';
import type { Renter }                    from '$/entities/roles/Renter';
import type { CertnCreditReport }         from '$/entities/tenantScreening/CertnCreditReport';
import type { BackgroundCheck }           from '$/entities/tenantScreening/BackgroundCheck';

export enum ApplicationStatus {
	Complete         = 'complete', // does not mean the individual checks are complete, just that the application is fully filled out
	PendingApplicant = 'waitingOnApplicant', // the application requires the applicant to fill it out
}

@CommonEntity()
@Permissions({
	read : ownApplicantRead,
})
export class Application extends BaseOrgEntity {

	@Column({ default : '' })
	@Permissions({ write : Permissions.serverOnly })
	status: ApplicationStatus;

	@Column()
	@Permissions({ write : ownApplicantWrite })
	@Validate({ required : true, allowChars : { value : nameChars }, trimmed : true })
	firstName: string = '';

	@Column()
	@Permissions({ write : ownApplicantWrite })
	@Validate({ allowChars : { value : nameChars }, trimmed : true })
	middleName: string = '';

	@Column()
	@Permissions({ write : ownApplicantWrite })
	@Validate({ required : true, allowChars : { value : nameChars }, trimmed : true })
	lastName: string = '';

	@Column({ type : 'date', nullable : true })
	@Permissions({ write : ownApplicantWrite })
	@Validate({
		required : true,
		custom   : value => value > User.dateOfBirthMax ? `Minimum age is: ${User.minAge}` : '',
	})
	dateOfBirth: Date = null;

	@Column({ type : 'varchar' })
	@Validate({ email : true, trimmed : true, custom : applicationEmailValidator })
	email: EmailAddress = '';

	/**
	 * national ID (IMPORTANT: deleted once tenant checks have finished)
	 */
	@Permissions({ write : ownApplicantWrite })
	@OneToOne('NationalID', 'application')
	nationalID: NationalID = undefined;

	@Column({ type : 'json', default : () => "('{}')" })
	@Permissions({ write : ownApplicantWrite })
	@Validate({
		required  : true,
		recursive : {
			street   : { required : true },
			city     : { required : true },
			province : { required : true },
			country  : { required : true },
			county   : { required : function(this: Address) {
				return this.isAmerican;
			} },
		},
	})
	address: Address = new Address();

	@ManyToOne('Building', { onDelete : 'SET NULL' })
	@Validate({
		required : true,
		custom   : applicationBuildingValidator,
	})
	building: Building = undefined;

	@Column()
	@Validate({ trimmed : true })
	unit: string = '';

	@Column({ asExpression : "address->>'$.street'", nullable : true })
	get street() {
		return this.address.street;
	}
	set street(value: string) {
		this.address.street = value;
	}

	@Column({ asExpression : "address->>'$.city'", nullable : true })
	get city() {
		return this.address.city;
	}
	set city(value: string) {
		this.address.city = value;
	}

	@Column({ asExpression : "address->>'$.province'", nullable : true })
	get province() {
		return this.address.province;
	}
	set province(value: string) {
		this.address.province = value;
	}

	@Column({ asExpression : "address->>'$.country'", nullable : true, length : '2' })
	get country() {
		return this.address.country;
	}
	set country(value: Country) {
		this.address.country = value;
	}

	get isCanadian() {
		return this.address.isCanadian;
	}

	get isAmerican() {
		return this.address.isAmerican;
	}

	@OneToMany('TenantCheck', 'application', { persistence : false })
	@Permissions({ write : Permissions.serverOnly })
	tenantChecks: TenantCheck[] = undefined; // needs to be explicitly set to undefined in order to be Vue reactive

	@ManyToOne('BaseRole', { nullable : true, onDelete : 'SET NULL' })
	applicant: Applicant | Renter;

	@Column({ type : 'json', nullable : true, default : () => "('{}')"  })
	@Permissions({
		write : [
			(context, application: Application) => application.consumerReportAccessAgreement?.agreed ? 'you have previously already agreed to these terms' : '',
			ownApplicantWrite,
		],
	})
	consumerReportAccessAgreement: Agreement = new Agreement();

	@Column({ type : 'json', default : () => "('{}')"  })
	@Permissions({
		write : [
			(context, application: Application) => application.accurateInformationAgreement?.agreed ? 'you have previously already agreed to these terms' : '',
			ownApplicantWrite,
		],
	})
	accurateInformationAgreement: Agreement = new Agreement();

	@Column({ type : 'json', default : () => "('{}')"  })
	@Permissions({
		write : [
			(context, application: Application) => application.applicantAgreement?.agreed ? 'you have previously already agreed to these terms' : '',
			ownApplicantWrite,
		],
	})
	applicantAgreement: Agreement = new Agreement();

	get fullName() {
		return getFullName(this);
	}

	get fullAddress() {
		return this.address.format();
	}

	get isComplete() {
		return this.status === ApplicationStatus.Complete;
	}

	get isWaitingOnApplicant() {
		return this.status === ApplicationStatus.PendingApplicant;
	}

	constructor(initialValues: Partial<Application> = {}) {
		super();
		this.mergeColumns(initialValues);
	}

	/**
	 * Looks for a similar application to this one that already exists
	 */
	async findSimilarApplications(): Promise<Application[]> {
		const applications = await Application.find({
			where : {
				orgId : this.orgId,
				..._.pick(this, [ 'firstName', 'lastName', 'dateOfBirth', 'city', 'province', 'country' ]),
			},
			relations : [ 'tenantChecks' ],
		});

		return applications.filter(application => application.id !== this.id && application.tenantChecks.some(check => !check.isExpired));
	}

	static getSample(org: Organization, country: Country) {
		const sample       = new this();
		sample.id          = 'sample';
		sample.ver         = 1;
		sample.org         = org;
		sample.firstName   = 'Peter';
		sample.lastName    = 'Pratt';
		sample.dateOfBirth = new Date('1992-10-29');
		sample.nationalID  = null;
		sample.country     = country;
		sample.building    = Building.getSample();

		if (country === Country.CA) {
			sample.street   = '123 AllenStreet';
			sample.city     = 'Toronto';
			sample.province = 'ON';
		}
		else if (country === Country.US) {
			sample.street            = '683 W. Helen Street';
			sample.city              = 'Brooklyn';
			sample.province          = 'NY';
			sample.building.country  = Country.US;
			sample.building.province = 'NY';
		}
		return sample;
	}

	async toSearchParams(): Promise<SearchParams> {
		await this.loadRelation('building');
		await this.loadRelation('nationalID');
		return {
			..._.pick(this, [ 'firstName', 'middleName', 'lastName', 'dateOfBirth', 'address', 'building' ]),
			nationalID : await this.nationalID?.decrypt() ?? '',
		};
	}

}

export const TestUsers = {
	'Equifax - Test Users' : {
		'Good test user'   : makeUser('Jean', '', 'Fontaine Richard', '875 Rue Virginie Laflamme', 'Serb', 'QC', Country.CA, '1975-01-16'),
		'Bad test user'    : makeUser('Martin', '', 'Donald', '33 Yonge St', 'Toronto', 'ON', Country.CA, '1942-01-01'),
		'Locked test user' : makeUser('Sam', '', 'Palabi', '38 Bridge ave', 'Dartmouth', 'NS', Country.CA, '1965-12-31'),
	},
	'Certn - Test Users' : {
		'Good American test user' : makeUser('Patricia', '', 'Eerat',  '3601 West Belleview Avenue', 'Littleton', 'CO', Country.US,  '1965-09-29'),
		'Bad American test user'  : makeUser('Will',     '', 'Nettke', '360 West Belleview Avenue',  'Littleton', 'CO', Country.US,  '1965-09-29'),
	},
	'Background Check - Test Users' : {
		'Good test user' : makeUser('Andrew', '', 'McLeod',   '123 Whatever St.', 'Toronto', 'ON', Country.CA,  '1990-01-01'),
		'Bad test user'  : makeUser('Daniel', '', 'Faulkner', '123 Whatever St.', 'Toronto', 'ON', Country.CA, '1990-01-01'),
	},
};

function makeUser(
	firstName: string, middleName: string, lastName: string, street: string, city: string, province: string, country: Country, dob: string
) {
	return { firstName, lastName, street, city, province, country, dateOfBirth : Moment(dob).toDate() };
}

async function applicationBuildingValidator(this: Application, building: Building) {
	await this.loadRelation('tenantChecks');
	const CertnCreditReportClass = getEntityClass('CertnCreditReport') as typeof CertnCreditReport;
	const BackgroundCheckClass   = getEntityClass('BackgroundCheck')   as typeof BackgroundCheck;

	if (
		this.tenantChecks.some(tc => tc instanceof CertnCreditReportClass || tc instanceof BackgroundCheckClass)
		&& this.address.isCanadian
		&& building.address.isAmerican) {
		return 'Background checks are only available for Canadian Properties';
	}
	return '';
}

async function applicationEmailValidator(this: Application, email: EmailAddress) {
	if (!email) {
		return '';
	}

	const validFormatResult = await Email.validateAddress(email);
	if (validFormatResult) {
		return validFormatResult;
	}

	await this.loadRelation('org.members');
	// Strip out anything after the + to get the base emails for each org member
	const orgEmails = await _.map(this.org.members, member => getEmailWithoutSubAddress(member.user.email));

	if (orgEmails.includes(getEmailWithoutSubAddress(email))) {
		return 'Cannot invite an applicant that belongs to your organization';
	}
	return '';
}

function ownApplicantRead(context: Context, entity: Application, query: SelectQueryBuilder<Application>): string {
	if (context.role.hasPermission(RolePermission.CrossOrgRead)) {
		return; // role can read any organization
	}

	// Allow applicants to read their own application
	if (query) {
		const alias = query.expressionMap.mainAlias.name;
		query.andWhere(`(${alias}.applicantId = :applicantId OR ${alias}.orgId = :orgId)`, { applicantId : context.role.id, orgId : context.org.id });
		context.stopChecks();
	}
	else if ((entity as any)?.applicantId === context.role.id) {
		context.stopChecks();
		return;
	}
}

async function ownApplicantWrite(context: Context, application: Application): Promise<string> {
	// allow Applicants to update their own application as long as it hasn't been completed yet
	if ((application as any).applicantId === context.role.id && application.isWaitingOnApplicant) {
		context.stopChecks();
		return;
	}
}
