
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"id":"Group_2997","data-name":"Group 2997","xmlns":"http://www.w3.org/2000/svg","width":"40.773","height":"40","viewBox":"0 0 35.773 35"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"id":"Path_7597","data-name":"Path 7597","d":"M-9264.716,12658.539s1.224-.334,1.744,0,.337,1.334.337,1.334l-2.911,17.8a2.643,2.643,0,0,1-1.095,1.408,3.193,3.193,0,0,1-1.9,0l-8.179-5.748a1.637,1.637,0,0,1-.344-.9,2.27,2.27,0,0,1,.344-1.014l7.719-6.988s.562-.758.353-.959-1.181.15-1.181.15l-10.153,6.82a1.648,1.648,0,0,1-1.091.566,9.951,9.951,0,0,1-2.147,0l-3.987-1.467s-.853-.459-.853-.934.853-.967.853-.967Z","transform":"translate(9291.64 -12650.178)","fill":"#fff"}})])
          )
        }
      }
    