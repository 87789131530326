





















































































































import { Vue, Component, Prop, Watch } from '$/lib/vueExt';
import { Building }                    from '$/entities/Building';

import BuildingSelector from '$/screens/App/Leases/components/BuildingSelector.vue';

export interface FilterField {
	// the label for the field, defaults to startCase of the field name
	label?: string;

	// the component for the input (defaults to b-form-input or b-form-select if options are provided)
	type?: 'text' | 'select' | 'toggle' | 'date' | 'month' | 'building-selector' | 'currency';

	size?: { cols?: number; sm?: number; md?: number; lg?: number; xl?: number };

	options?: { value: any; text: string; disabled?: boolean }[];

	/**
	 * Whether the filter should always be visible (not hidden in the collapsible filters section).
	 * 'always' means it's always visible and not in the collapsed section
	 * 'never' means it's always hidden and no UI widget will be rendered for it
	 */
	visibility?: 'always' | 'never';

	/**
	 * Optional attributes to be passed to the component.
	 */
	attrs?: Dictionary<any>;

	/**
	 * Optional transformer function that returns the field's filter value to be supplied to the underlying input element.
	 */
	toWidget?: (filterValue) => any;

	/**
	 * Optional transformer function that takes the input widget's value and returns the field's filter value.
	 */
	fromWidget?: (widgetValue) => any;
}

@Component({ components : { BuildingSelector } })
export default class SearchInput extends Vue {

	@Prop()
	readonly filter: Dictionary<any>;

	@Prop({ default : null })
	readonly filterFields: Dictionary<FilterField>;

	@Prop({ default : 'Search' })
	readonly placeholder: string;

	/**
	 * The debounce time for the search input.
	 */
	@Prop({ default : 500 })
	readonly debounce: number;

	isFilterVisible = false;

	/**
	 * The initial values of the filters which are used when resetting/clearing the filters.
	 */
	initialFilter: Dictionary<any> = {};

	localFilter: Dictionary<any> = {};

	@Watch('filter', { deep : true, immediate : true })
	onFilterChange() {
		this.localFilter = _.cloneDeep(this.filter);
	}

	mounted() {
		this.initialFilter = _.cloneDeep(this.filter);
	}

	get localFilterFields() {
		const localFilter = this.localFilter;

		return _(this.filter)
			.mapValues((filterValue, filterName) => {
				const userFilterField = this.filterFields?.[filterName] ?? {};
				if (userFilterField.visibility === 'never' || filterName === '$search') {	// omit the $search filter since that's at the top already
					return;
				}

				// try guessing the input type if not explicitly specified
				let inputType   = userFilterField.type;
				inputType     ||= userFilterField.options ? 'select' : null;
				inputType     ||= typeof filterValue === 'boolean' ? 'toggle' : null;
				inputType     ||= 'text';

				// special logic for the building selector
				if (inputType === 'building-selector') {
					userFilterField.toWidget = (filterValue: string) => {
						if (filterValue) {
							return (this.$refs.buildingSelector as BuildingSelector).findBuilding(filterValue);
						}
						return filterValue === '' ? '' : null;
					};
					userFilterField.fromWidget = (widgetValue: Building | '') => widgetValue === '' ? '' : widgetValue.id;
				}

				return {
					type      : inputType,
					component : {
						'text'              : 'b-form-input',
						'select'            : 'b-form-select',
						'toggle'            : 'b-form-checkbox',
						'building-selector' : 'building-selector',
						'date'              : 'b-form-datepicker',
						'month'             : 'fl-month-picker',
						'currency'          : 'fl-form-currency',
					}[inputType],
					label      : userFilterField.label ?? this.$format.startCase(filterName),
					options    : userFilterField.options,
					visibility : userFilterField.visibility,
					size       : userFilterField.size,
					attrs      : userFilterField.attrs,
					get value() {
						return userFilterField.toWidget ? userFilterField.toWidget(localFilter[filterName]) : localFilter[filterName];
					},
					set value(newValue) {
						localFilter[filterName] = userFilterField.fromWidget ? userFilterField.fromWidget(newValue) : newValue;
					},
				};
			})
			// @ts-ignore - figure out how to declare chainable methods in lodashExt
			.compactObject()
			.valueOf();
	}

	get localFilterFieldsAlwaysVisible() {
		return _.pickBy(this.localFilterFields, filterField => filterField.visibility === 'always');
	}

	get localFilterFieldsExtra() {
		return _.pickBy(this.localFilterFields, filter => filter.visibility === undefined);
	}

	get filterSummary() {
		return _(this.filter)
			.mapValues((fieldValue, filterName) => {
				const filterField     = this.localFilterFields[filterName];
				const userFilterField = this.filterFields?.[filterName] ?? {};
				if (userFilterField.visibility || filterName === '$search' || [ null, undefined, '', false ].includes(fieldValue)) {
					return;
				}

				fieldValue = userFilterField.toWidget ? userFilterField.toWidget(fieldValue) : fieldValue;

				switch (filterField.type) {
					case 'building-selector':
						return `${filterField.label}: ${fieldValue?.address?.format({ region : false })}`;
					case 'toggle':
						return filterField.label;
					case 'select':
						return `${filterField.label}: ${filterField.options.find(option => option.value === fieldValue)?.text}`;
					case 'date':
						return `${filterField.label}: ${this.$format.date(fieldValue, 'LL')}`;
					default:
						return `${filterField.label}: ${fieldValue}`;
				}
			})
			// @ts-ignore - figure out how to declare chainable methods in lodashExt
			.compactObject()
			.valueOf();
	}

	get searchFieldLabel() {
		if (_.size(this.localFilterFieldsAlwaysVisible) === 0) {
			return undefined;
		}
		return typeof this.filterFields?.$search === 'object' ? this.filterFields?.$search?.label : this.placeholder;
	}

	getInputFieldAttributes(filterField) {
		return {
			ref     : filterField.component === 'building-selector' ? 'buildingSelector' : undefined,
			options : filterField.options,
			switch  : filterField.type === 'toggle' ? true : undefined,
			block   : true,
			...filterField.attrs,
		};
	}

	applyFilter(toggle = true, extraValues: Dictionary<any> = {}) {
		this.$emit('update:filter', {
			...this.filter,
			..._.pickBy(this.localFilter, (value, key) => !!this.filterFields?.[key]),
			... extraValues,
		});

		if (toggle) {
			this.toggleFilter();
		}
	}

	onSearchChange(value) {
		this.$emit('update:filter', {
			...this.filter,
			$search : value,
		});
	}

	toggleFilter() {
		this.isFilterVisible = !this.isFilterVisible;
		if (this.isFilterVisible) {
			// Reset filter each time the filters are opened
			this.localFilter = { ...this.filter };
		}
	}

	clearFilter(fieldName?: string) {
		this.localFilter = fieldName
			? { ...this.localFilter, [fieldName] : this.initialFilter[fieldName] }
			: { ...this.initialFilter }
		;
		this.applyFilter(false);
	}

}
