/**
 * Various misc utilities and helper functions that don't have any better place to go.
 */
import Moment from 'moment';
import _      from '$/lib/lodashExt';
import env    from '$/lib/env';

/**
 * Mixin decorator for classes.
 *
 * Example:
 * class Bar {}
 *
 * @mixin(Bar)
 * class Foo {}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Mixin(...mixinClasses: Class[]) {
	return function(targetClass: Class) {
		mixinClasses.forEach(mixinClass => {
			// copy over prototype properties (ie instance members)
			Object.getOwnPropertyNames(mixinClass.prototype).forEach(name => {
				if (!targetClass.prototype.hasOwnProperty(name)) {
					Object.defineProperty(targetClass.prototype, name, Object.getOwnPropertyDescriptor(mixinClass.prototype, name));
				}
			});

			// copy over class properties (ie. static members)
			Object.getOwnPropertyNames(mixinClass).forEach(name => {
				if (!targetClass.hasOwnProperty(name)) {
					Object.defineProperty(targetClass, name, Object.getOwnPropertyDescriptor(mixinClass, name));
				}
			});
		});

		const existingMixinClasses = Reflect.getMetadata('class:mixins', targetClass) as Class[];
		if (existingMixinClasses) {
			existingMixinClasses.push(...mixinClasses);
		}
		else {
			Reflect.defineMetadata('class:mixins', mixinClasses, targetClass);
		}
	};
}

/**
 * Returns true if the mixinClass has been mixed into clazz.
 * @param includeAncestors if false, only examines the given clazz otherwise also examines all ancestors classes
 */
export function hasMixin(clazz: Class, mixinClass: Class, { includeAncestors = true } = {}): boolean {
	do {
		if ((Reflect.getMetadata('class:mixins', clazz) || []).includes(mixinClass)) {
			return true;
		}
		clazz = getParentClass(clazz);
	} while (clazz && includeAncestors);

	return false;
}

/**
 * Returns all classes that are ancestors of the given class.
 * @param {Function} clazz
 * @param {Object}   [options]
 * @param {Boolean}  [options.includeThis=true] if false, does not add clazz to the result
 */
export function getAncestorClasses(clazz: Class, { includeClass = true } = {}): Class[] {
	const result     = [];
	let currentClass = includeClass ? clazz : getParentClass(clazz);

	while (currentClass) {
		result.push(currentClass);
		currentClass = getParentClass(currentClass);
	}

	return result;
}

/**
 * Returns the parent class for the given class.
 */
export function getParentClass(clazz: Class): Class {
	return clazz ? Object.getPrototypeOf(clazz) : null;
}

/**
 * Returns true if possibleClass is or is a base class of possibleSubclass.
 */
export function isSubclass(possibleBase: Class, possibleSubclass: Class, strictSubclass = false): boolean {
	return possibleBase && possibleSubclass && ((!strictSubclass && possibleBase === possibleSubclass) || possibleBase.isPrototypeOf(possibleSubclass));
}

/**
 * Replaces a method of the given object with a new method but provides the old method to be called.
 */
export function chain<T extends Object, K extends keyof T>(object: T, methodName: K, replacer: (oldMethod: T[K], ...args: any[]) => any) {		// eslint-disable-line @typescript-eslint/ban-types
	const oldMethod = object[methodName];

	if (typeof oldMethod !== 'function') {
		throw new Error(`method not a function: ${String(methodName)}`);
	}

	object[methodName] = function(...args) {
		return replacer.call(this, oldMethod, ...args);
	} as any;

	// add an unchain method to restore the original method
	(object[methodName] as any).unchain = function() {
		object[methodName] = oldMethod;
	};

	return oldMethod;
}

/**
 * Like Object.getOwnPropertyDescriptor but follows the prototype chain.
 */
export function getPropertyDescriptor(obj, property: string): PropertyDescriptor {
	let desc  = Object.getOwnPropertyDescriptor(obj, property);
	let clazz = obj?.constructor;

	while (clazz?.prototype && !desc) {
		desc  = Object.getOwnPropertyDescriptor(clazz.prototype, property);
		clazz = getParentClass(clazz);
	}

	return desc;
}

/**
 * A deferred Promise is a Promise object that exposes the promises' resolve & reject functions
 * allowing the Promise to be fulfilled by an external called.
 */
export class DeferredPromise<T> {

	promise: Promise<T>;

	// resolves the promise with the given value
	resolve: (value: T | PromiseLike<T>) => void;

	// rejects the promise with the given reason
	reject:  (reason?: any) => void;

	constructor() {
		this.promise = new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.reject  = reject;
		});
	}

}

/**
 * Decorator that marks a property as deprecated and not to be used.
 */
export function Deprecated() {
	return function(clazz, property) {
		const deprecations = Reflect.getMetadata('deprecatedProperties', clazz.constructor) || [];
		deprecations.push(property);
		Reflect.defineMetadata('deprecatedProperties', deprecations, clazz.constructor);
	};
}

/**
 * @returns true if the given property of the given class has been decorated with the Deprecated decorator.
 */
export function isDeprecated(clazz: Class, property: string) {
	return (Reflect.getMetadata('deprecatedProperties', clazz) || []).includes(property);
}

export function getSampleNameEmail(): HasName & HasEmail {
	return {
		firstName : 'John',
		lastName  : 'Doe',
		email     : `johndoe@dev.${env.domain}`,
	};
}

/**
* @returns all of the parameters of the given function
*/
export function getFunctionParams(func: Function): string[] { 			// eslint-disable-line @typescript-eslint/ban-types
	// HACK: parse them out of the toString() of the function
	const params = func.toString().match(/^(?:async )?(?:function ?[a-z0-9A-Z_$]*? ?)?\((.*?)\)/);
	return params ? params[1].split(',').map(param => param.trim()).filter(param => !!param) : [];
}

/**
 * Options object for the doWithRetries function.
 * @param {number} retries Number of retries to attempt before failing.
 * Note that, with exponential delay and an initial delay of 1ms, 30 iterations is a delay of a 12 days
 * @param {string} errorMessage The error message to use if the function returns a falsy result.
 * @param {number} retryDelay The delay, in ms, between each retry.
 * @param {boolean} exponentialDelay Whether or not to use exponentially increasing delays.
 * If true, will double the delay each retry as well as adding an extra delay of 0-1000ms each retry.
 * @param {boolean} endOnError If true, will immediately stop if the function throws an error, and passes that error up
 */
export interface RetriesOptions {
	retries: number;
	errorMessage: string;
	retryDelay: number;
	exponentialDelay: boolean;
	endOnError: boolean;
}

/**
 * Makes multiple attempts to call the provided function.
 * If the function throws an error or the returns falsy, retries the function call.
 * @param {Function} func The function to call.
 * @param {RetriesOptions} options A set of optional parameters. Defaults to 3 retries, no delay, exponentialDelay disabled, and endOnError disabled.
 * @returns The result of calling func.
 */
export async function doWithRetries<T>(func: (...args: any[]) => T | Promise<T>, options?: Partial<RetriesOptions>): Promise<T> {
	options = _.assign({
		retries          : 3,
		errorMessage     : 'No result',
		retryDelay       : 0,
		exponentialDelay : false,
		endOnError       : false,
	}, options);

	let lastError;

	for (let i = 0; i <= options.retries; i++) {
		try {
			const result = await func();

			// If we got a result, we're done - otherwise keep trying
			if (result !== undefined) {
				return result;
			}
		}
		catch (error) {
			// If we want to end when an error is thrown, throw that error immediately
			if (options.endOnError) {
				throw error;
			}

			// Otherwise, just hold on to that error, to throw later if we hit the retry limit
			lastError = error;
		}

		// Based on https://developers.google.com/analytics/devguides/reporting/core/v3/errors#backoff
		await _.delay(options.exponentialDelay
			? options.retryDelay * Math.pow(2, i) + _.random(0, 1000)
			: options.retryDelay);
	}

	// If the reason we didn't get a result is because an error was thrown, throw that error
	// Otherwise, we just didn't get a result - throw the error message for that
	throw lastError ?? new Error(options.errorMessage);
}


/**
 * Waits until the given async function returns true. Will repeatedly call the function until it returns a truthy value.
 * @param {Function} func Function to wait on
 * @param {number} [interval=100] Time (in ms) to wait between attempts
 * @param {number} [timeout=0] Time (in ms) before stopping (0 indicates no timeout)
 * @returns True if the given function returns truthy, false if the timeout limit is reached
 */
export async function waitFor(func: () => PossiblePromise<any>, { interval = 100, timeout = 0 } = {}) {
	let result      = false;
	const startTime = Date.now();

	while (!result) {
		result = await func();
		if (result) {
			return true;
		}
		if (timeout > 0 && Date.now() - startTime > timeout) {
			return false;
		}

		await _.delay(interval);
	}
}

/**
 * Decorate a class method so that it is debounced but the specified wait period
 * @param wait duration to wait before making actual call
 * @param {Class[]} [constructors] array of additional class/functions of which `this` must be an instance of
 *        This will automatically include this.constructor already.
 *        This exists for cases where the `this` instance is not actually an instance of the class on which @Debounce is used
 */
export function Debounce(wait = 500, { constructors = [] }: { constructors?: Function[] } = {}) {
	return function(target, key: string, descriptor: PropertyDescriptor) {
		constructors = _.castArray(constructors).concat(target.constructor);
		return {
			configurable : true,
			enumerable   : descriptor.enumerable,
			get          : function() {
				if (constructors.some(constructor => this instanceof constructor)) {
					// Attach this function to the instance (not the class)
					const debouncedFunc = _.debounce(descriptor.value.bind(this), wait);
					Object.defineProperty(this, key, {
						configurable : true,
						enumerable   : descriptor.enumerable,
						value        : debouncedFunc,
					});
					return debouncedFunc;
				}

				descriptor.value.descriptor = descriptor;
				return descriptor.value;
			},
		};
	};
}

export class TimeoutError extends Error {

	constructor(message = 'promise has timed out') {
		super(message);
	}

}

/**
 * Returns a promise that will be fulfilled with the result of the given promise.
 * However, if the give promise is not fulfilled within ms milliseconds,
 * the returned promise is rejected with a TimeoutError.
 * @throws TimeoutError
 */
export function promiseTimeout<T>(promiseOrValue: Promise<T> | any, ms: number): Promise<T> {
	if (!(promiseOrValue instanceof Promise)) {
		return Promise.resolve(promiseOrValue);
	}

	const deferred = new DeferredPromise<T>();
	const timeout  = setTimeout(() => deferred.reject(new TimeoutError()), ms);

	promiseOrValue.then(
		value => {
			clearTimeout(timeout);
			deferred.resolve(value);
		},
		error => {
			clearTimeout(timeout);
			deferred.reject(error);
		}
	);

	return deferred.promise;
}

export function daysAgoToDate(days: number): Date {
	return Moment().subtract(days, 'days').toDate();
}

/**
 * Like JSON.stringify but doesn't throw on circular references.
 * Based upon https://github.com/moll/json-stringify-safe
 */
export function stringify(obj, replacer?: (this: any, key: string, value: any) => any, spaces?: string | number, cycleReplacer?): string {
	(stringify as any).serialize = function(replacer, cycleReplacer) {
		const stack = [];
		const keys  = [];

		cycleReplacer ??= function(key, value) {
			return stack[0] === value ? '[Circular ~]' : `[Circular ~.${keys.slice(0, stack.indexOf(value)).join('.')}]`;
		};

		return function(key, value) {
			if (stack.length > 0) {
				const thisPos = stack.indexOf(this);
				~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
				~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
				if (stack.includes(value)) {
					value = cycleReplacer.call(this, key, value);
				}
			}
			 else {
				stack.push(value);
			}

			return replacer == null ? value : replacer.call(this, key, value);
		};
	};

	return JSON.stringify(obj, (stringify as any).serialize(replacer, cycleReplacer), spaces);
}

/**
 * @param { Number } maxPerSecond the maximum number of calls to the callback function, per second
 * @returns a function that has the same calling semantics as callback but will only call callback at most maxPerSecond times per second
 */
export function throttlePerSecond<T>(maxPerSecond: number, callback: (...args) => Promise<T>): (...args) => Promise<T> {
	let lastCallSecond = 0;
	let callCount      = 0;

	if (maxPerSecond < 1) {
		throw new Error('maxPerSecond parameter must be at least 1');
	}

	return async function(...args) {
		let thisSecond = Math.floor(Date.now() / 1000);

		if (callCount >= maxPerSecond) {
			while (thisSecond === lastCallSecond) {
				await _.delay(100);
				thisSecond = Math.floor(Date.now() / 1000);
			}
		}

		if (thisSecond !== lastCallSecond) {
			callCount = 0;
		}

		lastCallSecond = thisSecond;
		callCount++;
		return (callback as Function).apply(this, args);
	};
}

export function getEmailWithoutSubAddress(email: string, subAddressDelimiter = '+') {
	// Use RegExp class to allow inserting the delimiter as a variable
	const regex = `(\\${subAddressDelimiter}.+)?@`;
	return email.replace(new RegExp(regex), '@');
}
