import { File, DocumentType }      from '$/entities/File';
import type { VerificationStatus } from '$/entities/verifications/Verification';

export enum ProgressStatus {
	Incomplete = 'incomplete', // default state
	Submitted  = 'submitted',  // documents submitted
	Rejected   = 'rejected',   // documents rejected
	Approved   = 'approved',   // documents/verification approved
	Fraudulent = 'fraudulent', // fraudulent activity detected, automatically suspends user
}

export enum NameSuffixOptions {
	''     = '',
	Junior = 'Junior',
	Senior = 'Senior',
	Second = 'Second',
	Third  = 'Third',
	Fourth = 'Fourth',
}

export interface BaseVerificationRequirements {

	referenceType: 'user' | 'role.landlord' | 'role.propertyManager' | 'role.realtorOrLeasingAgent' | 'role.lawyerOrParalegal' | 'role.advisor';

	title: string;

	/**
	 * Optional more detailed description of the document.
	 */
	desc?: string;

	optional?: boolean;

	verificationStatus?: VerificationStatus;
}

/**
 * The specification for a verification document required to get through identity verification.
 */
export interface VerificationDoc extends BaseVerificationRequirements {

	type: 'document';

	docType: DocumentType;

	file?: File;

}

export interface VerificationUrl extends BaseVerificationRequirements {

	type: 'url';

	url?: URL;

}

export type VerificationRequirements = VerificationDoc | VerificationUrl;
