import Moment          from 'moment';
import { Validate }    from '$/entities/BaseEntityExt';
import BasePreferences from '$/entities/lib/BasePreferences';
import { Field }       from '$/entities/lib/JSONable';

export enum RenterInterestTypes {
	ImprovingMyCredit         = 'improvingMyCredit',
	EstablishingRentalHistory = 'establishingRentalHistory',
	ViewLeaseRecord           = 'viewLeaseRecord',
	InvitedByLandlord         = 'invitedByLandlord',
	LearnMore                 = 'learnMore',
}

export class RenterInterests extends BasePreferences {

	@Field()
	[RenterInterestTypes.ImprovingMyCredit]?: boolean;

	@Field()
	[RenterInterestTypes.EstablishingRentalHistory]?: boolean;

	@Field()
	[RenterInterestTypes.ViewLeaseRecord]?: boolean;

	@Field()
	[RenterInterestTypes.InvitedByLandlord]?: boolean;

	@Field()
	[RenterInterestTypes.LearnMore]?: boolean;

	@Field()
	@Validate({ date : true })
	insuranceReminderAt?: Date | null;

	static get insuranceReminderMin(): Date {
		return Moment().add(1, 'day').toDate();
	}

	static get insuranceReminderMax(): Date {
		return Moment().add(2, 'years').toDate();
	}

}
