import Axios      from 'axios';
import { Entity } from '$/lib/typeormExt';

import { PersonaVerification as PersonaVerificationCommon } from '$/common/entities/verifications/PersonaVerification';
export *                                                    from '$/common/entities/verifications/PersonaVerification';

@Entity()
export class PersonaVerification extends PersonaVerificationCommon {

	static async checkInquiryStatus(inquiryId: string): Promise<{ status: string; sessionToken: string }> {
		return (await Axios.get(PersonaVerificationCommon.getURL(inquiryId))).data;
	}

}
