






import { Vue, Component, Prop, Inject } from '$/lib/vueExt';
import type Steps                       from './Steps.vue';

/**
 * A group of steps that will be displayed as a single step in the navigation.
 */
@Component
export default class StepGroup extends Vue {

	@Inject({ from : 'stepsComponentInject' })
	readonly stepsComponentInject?: Steps;

	@Prop()
	readonly title: string;

	@Prop({ default : false })
	readonly disabled: boolean;

	get steps() {
		return this.stepsComponentInject ?? null;
	}

	get isActive() {
		return this.steps?.currentStep?.stepGroup === this;
	}

	/**
	 * Make the first step in this group the currently active step.
	 */
	activate() {
		return this.steps?.activateStep(this);
	}

}
