import Axios                from 'axios';
import Vue                  from 'vue';
import { FindConditions }   from 'typeorm';
import { FindOptionsUtils } from 'typeorm/find-options/FindOptionsUtils';

import Errors from '$/lib/Errors';
import { convertFindOperatorToQueryOperator, In, isFindOperator } from '$/lib/typeormExt';

import {
	BaseEntity as BaseEntityCommon, createEntity, FindManyOptions, FindOneOptions, EntityID, defaultFindLimit, getEntityID
}        from '$/common/entities/BaseEntity';
export * from '$/common/entities/BaseEntity';

/**
 * A set of all registered objects that may have properties that reference entities.
 * Whenever an entity is updated, scan the entire set and update any entities that match.
 * The map's keys are the containers and
 * the values are possible properties to check (if undefined, check all enumerable properties)
 */
const entityContainers = new Map<any, string[]>();

/**
 * Base class for all client-side entities.
 * Implements entity methods for communicating with the API.
 */
export class BaseEntity extends BaseEntityCommon {

	async save(): Promise<this> {
		if (!this.isNew) {
			if (!this.isEditable) {
				throw new Error('must call makeEditable() before trying to call save()');
			}
			if (!this.isEdited()) {
				return this;	// no changes made so skip actually saving anything
			}
		}

		const clazz = this.entityClass;
		let result;

		const pojo = clazz.toObject(this, { mustBeEdited : !this.isNew });

		if (this.id) {
			result = (await Axios.put(clazz.collectionUrl(this.id), pojo)).data;
		}
		else {
			result = (await Axios.post(clazz.collectionUrl(), pojo)).data;
			if (!result.id) {
				throw new Error('expected id in result');
			}
			this.id = result.id;
		}

		// refresh this entity since it's now been updated
		this.mergeColumns(result, { commonColumnsOnly : true });
		this.makeEditable({ force : true });
		updateEntityContainers(this, result);

		return this;
	}

	async reload({ includeRelations = [] } = {}): Promise<void> {
		const results = await super.reload.call(this, { includeRelations });
		updateEntityContainers(this, results);
	}

	// #region STATIC MEMBERS

	static async doesExist<T extends BaseEntity>(optionsOrConditions?: string|FindManyOptions<T>): Promise<boolean> {
		const params = processFindOptions(this, optionsOrConditions);
		params.set('limit', '1');
		params.set('select', 'id');		// don't need the full entity
		return !!(await Axios.get(this.collectionUrl(), { params })).data.results[0];
	}

	static async findOneRaw(optionsOrConditions?: string|any|FindOneOptions): Promise<Dictionary<any>> {
		optionsOrConditions ??= {};
		if (typeof optionsOrConditions === 'object') {
			optionsOrConditions.limit = 1;
		}

		const params = processFindOptions(this, optionsOrConditions);
		return (await Axios.get(this.collectionUrl(), { params })).data.results[0];
	}

	/**
	 * Returns zero or one entity that match the given criteria.
	 */
	protected static async findOneActual(optionsOrConditions?: string|any|FindOneOptions): Promise<BaseEntity> {
		try {
			const result = await this.findOneRaw(optionsOrConditions);
			if (!result) {
				return;
			}
			return createEntity(result, { possibleClass : this, makeEditable : optionsOrConditions?.editable });
		}
		catch (error) {
			if (error instanceof Errors.HTTP.NotFound || error.status === 404) {
				return undefined;
			}
			throw error;
		}
	}

	protected static async findActual(optionsOrConditions?: string|any|FindOneOptions): Promise<BaseEntity[]> {
		const cache = [];
		return (await this.findRaw(optionsOrConditions))
			.map(result => createEntity(result, { possibleClass : this, makeEditable : optionsOrConditions?.editable, cache }))
		;
	}

	static async findRawAndCount(optionsOrConditions: FindManyOptions = {}): Promise<[ Dictionary<any>[], number ]> {
		const { results, totalCount, queryResultLength } = (await Axios.get(this.collectionUrl(), {
			params : processFindOptions(this, {
				...optionsOrConditions,
				limit : optionsOrConditions.limit > defaultFindLimit ? defaultFindLimit : optionsOrConditions.limit,
			}),
		})).data;
		let nextBatch = [];

		// if there has been some results but it hasn't reached the limit do another fetch
		// if the second fetch result is empty this cycle will stop
		if (queryResultLength && optionsOrConditions.limit && queryResultLength < optionsOrConditions.limit) {
			const remaining = optionsOrConditions.limit - queryResultLength;
			nextBatch       = (await this.findRawAndCount({
				...optionsOrConditions,
				offset : (optionsOrConditions.offset || 0) + queryResultLength,
				limit  : remaining,
			}))[0];
		}

		return [ [ ...results, ...nextBatch ], totalCount ];
	}

	static async findAndCount<T extends typeof BaseEntity>(this: T, optionsOrConditions?: FindManyOptions<InstanceType<T>>): Promise<[ InstanceType<T>[], number]> {
		const cache                 = [];
		const [ rawResults, count ] = await this.findRawAndCount(optionsOrConditions);
		return [
			rawResults.map(result => createEntity(result, { possibleClass : this, makeEditable : optionsOrConditions?.editable, cache }) as InstanceType<T>),
			count,
		];
	}

	static async findByIds<T extends typeof BaseEntity>(this: T, ids: EntityID[], optionsOrConditions?: FindManyOptions<InstanceType<T>>): Promise<InstanceType<T>[]> {
		return _.isNotEmpty(ids) ? this.find({ ...optionsOrConditions, where : { id : In(ids) } }) : [];
	}

	static async count(optionsOrConditions?: FindManyOptions): Promise<number>;
	static async count(optionsOrConditions?: FindManyOptions & { countBy: string }): Promise<Dictionary<number>>;
	static async count<T extends BaseEntity>(optionsOrConditions?: FindManyOptions<T> & { countBy?: keyof T }): Promise<number | Dictionary<number>> {
		let params: URLSearchParams;

		if (typeof optionsOrConditions === 'object') {
			params = processFindOptions(this, _.omit(optionsOrConditions, 'countBy'));
			if (optionsOrConditions.countBy) {
				params.set('countBy', String(optionsOrConditions.countBy));
			}
		}
		else {
			params = processFindOptions(this, optionsOrConditions);
		}

		return (await Axios.get(this.collectionUrl('/count'), { params })).data as number;
	}

	/**
	 * Soft-deletes the given entity or by entity id.
	 */
	static async archive(entities: PossibleArray<BaseEntity | EntityID>) {
		for (const entity of _.castArray(entities)) {
			const id = getEntityID(entity);
			if (!id || entity instanceof BaseEntity && entity.isNew) {
				continue;  // nothing needs to be done
			}
			// COULDDO PERFORMANCE: figure out how to archive multiple ids in one call
			await Axios.delete(this.collectionUrl(id));
			updateEntityContainers(entity instanceof BaseEntity ? entity : { id, constructor : this });
		}
	}

	static async remove(entities: PossibleArray<BaseEntity | EntityID>): Promise<any | any[]> {
		for (const entity of _.castArray(entities)) {
			const id = getEntityID(entity);
			if (!id || entity instanceof BaseEntity && entity.isNew) {
				continue;  // nothing needs to be done
			}
			// COULDDO PERFORMANCE: figure out how to remove multiple ids in one call
			await Axios.delete(this.collectionUrl(id), { params : { hard : true } });
			updateEntityContainers(entity instanceof BaseEntity ? entity : { id, constructor : this });
		}
		return [];
	}

	// #endregion STATIC MEMBERS

}

/**
 * Decorator that searches for entities in the Vue component and keeps them up-to-date.
 * Use on class properties of type BaseEntity or on an entire class (which will then search all properties
 * of the class).  Using this on specific property decorators is more efficient.
 */
export function SyncEntities() {
	return function(componentClass, property?: string) {
		const prototype = typeof componentClass === 'function' ? componentClass.prototype : componentClass;

		// check if it's a Vue component
		if (prototype instanceof Vue) {
			const oldCreated = prototype.created;

			if (oldCreated) {
				prototype.created = function() {
					addProperty(this, property);
					return oldCreated.apply(this, arguments);	// eslint-disable-line prefer-rest-params
				};
			}
			else {
				prototype.created = function() {
					addProperty(this, property);
				};
			}
		}
		// otherwise treat as a regular class
		else {
			addProperty(componentClass, property);
		}
	};

	function addProperty(container, property) {
		let array = entityContainers.get(container);
		if (property) {
			if (array === undefined) {
				array = [ ];
			}
			array.push(property);
		}

		entityContainers.set(container, array);
	}
}

// HELPER FUNCTIONS

function processFindOptions<T extends BaseEntity>(entityClass: typeof BaseEntity, options?: FindManyOptions<T> | FindConditions<T> | EntityID) {
	if (options === undefined || options === null) {
		options = {};
	}
	if (typeof options === 'string') {
		options = { where : { id : options } };
	}
	if (typeof options === 'object') {
		if (!FindOptionsUtils.isFindManyOptions(options)) {
			options = { where : options };
		}
	}
	else {
		throw new Error('unknown param type for find');
	}

	// @ts-ignore ignore weird "Excessive stack depth comparing types" error
	if (!options.where) {
		options.where = {};
	}

	if (!(options.where as any).$class) {
		(options.where as any).$class = entityClass.name;
	}

	const params: any = {};
	params.where      = JSON.stringify(processWhere(options.where));
	if (options.relations) {
		params.relations = _.castArray(options.relations).join(',');
	}
	if (!_.isNil((options as FindManyOptions<T>).limit)) {
		params.limit = (options as FindManyOptions<T>).limit;
	}
	if (!_.isNil((options as FindManyOptions<T>).offset)) {
		params.offset = (options as FindManyOptions<T>).offset;
	}
	if (options.order) {
		params.order = _.map(options.order, (dir, prop) => `${typeof dir === 'string' && dir.toLowerCase() === 'desc' ? '-' : '+'}${prop}`).join(',');
	}
	if (options.select) {
		params.select = _.castArray(options.select).join(',');
	}
	if (options.withDeleted) {
		params.withDeleted = 'true';
	}
	if ((options as FindManyOptions<T>).withDeletedRelations) {
		params.withDeletedRelations = 'true';
	}

	return new URLSearchParams(_.compactObject(params));
}

// replaces any references to Entity with the entity's ID and converts FindOperators to QueryOperators
export function processWhere(obj) {
	for (const property of Object.keys(obj)) {
		const value = obj[property];
		if (value instanceof BaseEntity) {
			obj[property] = value.id;
		}
		else if (isFindOperator(value)) {
			obj[property] = convertFindOperatorToQueryOperator(value);
		}
		else if (value && value.constructor === Object) {
			processWhere(value);
		}
		else if (Array.isArray(value)) {
			value.forEach(processWhere);
		}
	}

	return obj;
}

function updateEntityContainers(source: BaseEntity | { id: string; constructor: typeof BaseEntity }, newData?: any) {
	const id    = source.id;
	const ver   = source instanceof BaseEntity ? source.ver : undefined;
	const clazz = source.constructor as typeof BaseEntity;

	const entitiesToReload   = new Set<BaseEntity>();
	const containersToRemove = new Set();

	entityContainers.forEach((properties, container) => {
		// check if a container is a deleted Vue instance
		if (container instanceof Vue && container._isDestroyed) {
			containersToRemove.add(container);
			return;
		}

		if (!properties) {
			properties = Object.getOwnPropertyNames(container);
		}

		properties.forEach(property => {
			_.castArray(container[property]).forEach(possibleEntity => {
				if (possibleEntity instanceof BaseEntity
					&& possibleEntity.id === id
					&& possibleEntity.constructor === clazz
					&& possibleEntity.ver !== ver
				) {
					if (newData) {
						possibleEntity.mergeColumns(newData);
					}
					else {
						entitiesToReload.add(possibleEntity);
					}
				}
			});
		});
	});

	// SHOULDDO PERFORMANCE: de-dupe entities into the same entity/id to avoid fetching the same entity more than once
	entitiesToReload.forEach(entity => entity.reload());

	containersToRemove.forEach(container => entityContainers.delete(container));
}
