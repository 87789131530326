/**
 * All possible file status states
 */
export enum FileStatus {
	Uploading = 'uploading',
	Saved     = 'saved',
	Error     = 'error',
	Cancelled = 'cancelled',
}

/**
 * All possible types of documents that users can upload
 */
export enum DocumentType {
	BuildingOwnership            = 'buildingOwnership', // role verification, also attaches to building and needed for reporting for small landlords
	UserPhotoID                  = 'userPhotoID', // for user verification
	PMBusinessCard               = 'pmBusinessCard', // role verification
	PMManagementAgreement        = 'pmManagementAgreement', // role verification
	OrgCompanyProof              = 'orgCompanyProof', // role verification
	RealtorOrLeasingAgentProof   = 'realtorOrLeasingAgentProof', // role verification
	LeaseAgreement               = 'leaseAgreement', // needed for reporting for small landlords, also for collections
	CollectionsRentalLedger      = 'collectionsRentalLedger', // needed for collections
	CollectionsRentalApplication = 'collectionsRentalApplication', // needed for collections
	CollectionsOrderJudgement    = 'collectionsOrderJudgement', // needed for collections
	CollectionsTenantID          = 'collectionsTenantID', // needed for collections
	AdvisorLicenseProof          = 'advisorLicenseProof', // role verification
	Unknown                      = 'unknown' // some existing files have weird names and can't be categorized properly. SHOULDDO: fix these
}

/**
 * An array of all the document types used for role verification
 */
export const RoleVerificationDocumentTypes = [
	DocumentType.BuildingOwnership,
	DocumentType.PMBusinessCard,
	DocumentType.PMManagementAgreement,
	DocumentType.OrgCompanyProof,
];

/**
 * An map of various files types, their extensions, and mime types.
 */
export const FileTypes: Dictionary<Dictionary<FileType>> = {
	Image : {
		PNG : {
			name       : 'Portable Network Graphics',
			extensions : [ 'png' ],
			mimeTypes  : [ 'image/png', 'image/x-png' ],
		},
		JPG : {
			name       : 'Joint Photographic Experts Group',
			extensions : [ 'jpg', 'jpeg', 'jpe', 'jfif' ],
			mimeTypes  : [ 'image/jpeg', 'image/pjpeg' ],
		},
		GIF : {
			extensions : [ 'gif' ],
			mimeTypes  : [ 'image/gif' ],
		},
		TIFF : {
			name       : 'Tagged Image File Format',
			extensions : [ 'tif', 'tiff' ],
			mimeTypes  : [ 'image/tiff' ],
		},
		HEIC : {
			name       : 'High Efficiency Image Format',
			extensions : [ 'heic' ],
			mimeTypes  : [ 'image/heic', 'image/heif' ],
		},
		BMP : {
			extensions : [ 'bmp' ],
			mimeTypes  : [ 'image/bmp' ],
		},
		WEBP : {
			name       : 'Web Picture Format',
			extensions : [ 'webp' ],
			mimeTypes  : [ 'image/webp' ],
		},
	},
	Video : {
		MP4 : {
			extensions : [ 'mp4' ],
			mimeTypes  : [ 'video/mp4' ],
		},
	},
	Document : {
		PDF : {
			name       : 'Portable Document Format',
			extensions : [ 'pdf' ],
			mimeTypes  : [ 'application/pdf' ],
		},
		XLSX : {
			name       : 'Microsoft Excel',
			extensions : [ 'xlsx', 'xls' ],
			mimeTypes  : [ 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ],
		},
		DOCX : {
			name       : 'Microsoft Word',
			extensions : [ 'docx', 'doc' ],
			mimeTypes  : [ 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ],
		},
		RTF : {
			name       : 'Rich Text Format',
			extensions : [ 'rtf' ],
			mimeTypes  : [ 'application/rtf' ],
		},
	},
	// dangerous file types that can be used to execute code
	Blacklist : {
		Executable : {
			extensions : [ 'exe', 'com', 'bat', 'sh', 'dmg', 'dll', 'jar' ],
			mimeTypes  : [
				'application/exe',
				'application/x-msdownload',
				'application/x-exe',
				'application/dos-exe',
				'application/x-winexe',
				'application/msdos-windows',
				'application/x-msdos-program',
				'application/vnd.microsoft.portable-executable',
				'application/java-archive',
				'application/x-apple-diskimage',
				'vms/exe',
				'application/x-sh',
			],
		},
	},
};

export interface FileType {
	name?: string;
	extensions: string[];
	mimeTypes: string[];
}

export type FileUploadProgress = (uploadProgressPercentage: number) => void
