
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"22.117","height":"26.795","viewBox":"0 0 22.117 26.795"}, attrs),
              ...rest,
            },
            children.concat([_c('g',{attrs:{"id":"Group_2785","data-name":"Group 2785","transform":"translate(0.75 0.75)"}},[_c('path',{attrs:{"id":"Path_7481","data-name":"Path 7481","d":"M-8625,7957.635v-16.148l10.451-9.146,10.166,9.146v16.148Z","transform":"translate(8625 -7932.34)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}}),_c('g',{attrs:{"id":"Group_2774","data-name":"Group 2774","transform":"translate(6.138 6.739)"}},[_c('path',{attrs:{"id":"Path_7482","data-name":"Path 7482","d":"M2.172,0A2.13,2.13,0,0,1,4.343,2.085,2.13,2.13,0,0,1,2.172,4.171,2.13,2.13,0,0,1,0,2.085,2.13,2.13,0,0,1,2.172,0Z","transform":"translate(1.695 0)","fill":"#152951"}}),_c('path',{attrs:{"id":"Path_7480","data-name":"Path 7480","d":"M-8213.905,8179.066h2.936a.727.727,0,0,0,.3-.077.753.753,0,0,0,.171-.189l.5-3.55h.993a1.588,1.588,0,0,0,.51-.1.436.436,0,0,0,.23-.257l-.116-5.324a1.148,1.148,0,0,0-.487-.934,3.159,3.159,0,0,0-1.13-.442h-4.968a2.8,2.8,0,0,0-1.1.442,1.557,1.557,0,0,0-.54.934v5.324a.565.565,0,0,0,.2.257.618.618,0,0,0,.435.1h1.08l.506,3.55a.43.43,0,0,0,.173.189A.849.849,0,0,0-8213.905,8179.066Z","transform":"translate(8216.608 -8163.283)","fill":"#152951"}})])])])
          )
        }
      }
    