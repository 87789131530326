import { Chart, ChartConfiguration } from 'chart.js';

export function getDataURL(config: ChartConfiguration) {
	const canvas  = document.createElement('canvas');
	const wrapper = document.createElement('div');
	wrapper.setAttribute('style', 'max-width: 400px');
	wrapper.appendChild(canvas);
	document.body.appendChild(wrapper);

	const chart = new Chart(canvas, _.merge(config, {
		options : {
			animation : false,
		},
	}));

	const dataURL = chart.toBase64Image();

	wrapper.remove();

	return dataURL;
}
