import { BaseRole }                  from '$/entities/roles/BaseRole';
import { RolePermission }            from '$/entities/roles/RolePermission';
import { ChildEntity, CommonEntity } from '$/entities/BaseEntity';

/**
 * This Support role is intended for company employees to get access to back-end admin functionality.
 */
@ChildEntity() @CommonEntity()
export abstract class Support extends BaseRole {

	hasPermission(permission: RolePermission): boolean {
		switch (permission) {
			case RolePermission.RoleAssumeOther:
			case RolePermission.LogsRead:
			case RolePermission.IdentityVerification:
			case RolePermission.OrgView:
			case RolePermission.OrgManageInvitations:
			case RolePermission.CrossOrgSetPackage:
			case RolePermission.EmailRead:
			case RolePermission.Metro2Read:
			case RolePermission.CrossOrgRead:
			case RolePermission.RoleCrossOrgWrite:
			case RolePermission.ReportingRead:
			case RolePermission.UserSuspend:
			case RolePermission.SecurityFreeze:
			case RolePermission.HubSpotAccess:
			case RolePermission.TenantDisputes:
			case RolePermission.CouponRead:
			case RolePermission.AffiliateInternalRead:
			case RolePermission.RoleAdditionalPermissionsRead:
				return true;
		}
		return super.hasPermission(permission);
	}

}

/**
 * SupportAdmin has various edit permissions for updating LL and T information
 */
@ChildEntity() @CommonEntity()
export class SupportAdmin extends Support {

	hasPermission(permission: RolePermission): boolean {
		switch (permission) {
			case RolePermission.CrossOrgWrite:
			case RolePermission.CrossOrgDelete:
			case RolePermission.EquifaxCustomerInfo:
			case RolePermission.CouponWrite:
			case RolePermission.AffiliateWrite:
			case RolePermission.CrossOrgImport:
			case RolePermission.RoleDelete:
				return true;
		}
		return super.hasPermission(permission);
	}

}

/**
 * SuperAdmin has unrestricted access to the backend features
 */
@ChildEntity() @CommonEntity()
export class SuperAdmin extends SupportAdmin {

	hasPermission(permission: RolePermission): boolean {
		switch (permission) {
			case RolePermission.RoleCreate:
			case RolePermission.PackageWrite:
			case RolePermission.ReportingWrite:
			case RolePermission.CreateApiKey:
			case RolePermission.CouponWrite:
			case RolePermission.Metro2Write:
				return true;
		}
		return super.hasPermission(permission);
	}

}

/**
 * SupportSales is intended for sales team in case they needed to update a specific user's information
 */
@ChildEntity() @CommonEntity()
export class SupportSales extends Support {

}

/**
 * This role is intended for the marketing team for things they need to do.
 */
@ChildEntity() @CommonEntity()
export class SupportMarketing extends Support {

	hasPermission(permission: RolePermission) {
		switch (permission) {
			case RolePermission.CouponWrite:
			case RolePermission.AffiliateWrite:
				return true;
		}
		return super.hasPermission(permission);
	}

}
