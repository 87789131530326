import env                     from '$/lib/env';
import externalLinks           from '$/lib/externalLinks';
import { Country, HasCountry } from '$/lib/Address';

import { BaseRole }              from '$/entities/roles/BaseRole';
import { TenantScreeningReport } from '$/screens/App/TenantScreening/components/PdfReport/TenantScreeningReport';

export const CLOUDINARY = 'https://res.cloudinary.com/landlordcreditbureau-com';
export const IMAGE      = `${CLOUDINARY}/image/upload`;
export const VIDEO      = `${CLOUDINARY}/video/upload`;

export const apolloBannerLogo = `${CLOUDINARY}/v1629215430/lcbapp/Marketplace/Landlord/APOLLO/APOLLO_jcral3.png`;

export enum ResourceAction {
	ShowVideo    = 'video',				// references a video file (play in the app without leaving the app)
	ShowDocument = 'showDoc',			// references a document file (show in the app without leaving the app)
	Download     = 'download',			// references a file somewhere on the internet (download to local machine)
	Visit        = 'visit',				// references a page somewhere on the internet (open in new browser tab)
	Function     = 'function',			// references a function to call (open in the app)
	HTML         = 'html',				// references a string of html to display in the app
}

export interface Resource {
	key?: string;
	action: ResourceAction;
	title: string;
	subtitle?: string;
	thumbnail?: string;
	url?: string;
	html?: string;
	function?: () => void;		// When opened, let this handler take care of the actual action of viewing the resource (added to support opening modals that aren't resources i.e: pdfs or docs)
}

/* eslint-disable max-len */
export const resources = {
	howToStartRentReporting : {
		title  : 'How to Start Rent Reporting',
		url    : `${VIDEO}/v1682371990/frontlobby/Videos/How%20To%20Start%20Rent%20Reporting/How_To_Start_Rent_Reporting_tzdgir.mp4`,
		action : ResourceAction.ShowVideo,
	},
	howToAddAProperty : {
		title  : 'How to Add a Property',
		url    : `${VIDEO}/v1655832277/frontlobby/Videos/How%20to%20Add%20a%20Property/How_to_add_a_property_whg4xs.mp4`,
		action : ResourceAction.ShowVideo,
	},
	howToPerformTenantScreening : getResourceByCountry((country: Country) => ({
		title : country === Country.CA ? 'How to Perform Tenant Screening' : 'How to Pull a Credit Report',
		url   : country === Country.CA
			? `${VIDEO}/v1682371932/frontlobby/Videos/How%20to%20do%20a%20Credit%20Check/How_To_Perform_Tenant_Screening_1_nmy1l8.mp4`
			: `${VIDEO}/v1638301158/frontlobby/Videos/How%20to%20do%20a%20Credit%20Check/How_to_do_a_Credit_Check_in_Front_Lobby_firrfm.mp4`,
		action : ResourceAction.ShowVideo,
	})),
	howToRecoverDebts : {
		title  : 'How to Recover Debts from Former Tenants',
		url    : `${VIDEO}/v1682371946/frontlobby/Videos/How%20to%20recover%20debts%20from%20a%20former%20tenant/How_To_Recover_Debts_From_Former_Tenants_1_xjc1la.mp4`,
		action : ResourceAction.ShowVideo,
	},
	howToUpdateLeaseRecord : {
		title  : 'How to Update your Lease Records',
		url    : `${VIDEO}/v1656008333/frontlobby/Videos/How%20to%20create%20a%20Lease%20Record/How_to_update_your_Lease_Records_ostqa6.mp4`,
		action : ResourceAction.ShowVideo,
	},
	navigatingFrontLobby : {
		title  : 'Navigating FrontLobby',
		url    : `${VIDEO}/v1682371925/frontlobby/Videos/Navigating%20FrontLobby/Navigating_FrontLobby_fruwia.mp4`,
		action : ResourceAction.ShowVideo,
	},
	whatFrontLobbyOffers : {
		title  : 'What FrontLobby Offers',
		url    : `${VIDEO}/v1682371965/frontlobby/Videos/What%20FrontLobby%20Offers/What_FrontLobby_Offers_hvgucu.mp4`,
		action : ResourceAction.ShowVideo,
	},
	applicationAndLeaseClauses : getResourceByCountry((country: Country) => ({
		title : `Application and Lease Clauses ${country === Country.US ? 'USA' : 'Canada'}`,
		url   : country === Country.US
			? `${IMAGE}/v1742917782/frontlobby/Resources/Clauses%2C%20Tenant%20Notices%2C%20Legal%20Consent/Application_and_Lease_Clauses_US_kkrxsg.pdf`
			: `${IMAGE}/v1742917781/frontlobby/Resources/Clauses%2C%20Tenant%20Notices%2C%20Legal%20Consent/Application_and_Lease_Clauses_CA_tcejkk.pdf`,
		action : ResourceAction.ShowDocument,
	})),
	legalFramework : getResourceByCountry((country: Country) => ({
		title  : 'Legal Framework',
		url    : externalLinks.legal.legalFramework[country === Country.US ? 'US' : 'CA'],
		action : ResourceAction.Visit,
	})),
	legalDefence : {
		title  : 'Legal Defence',
		url    : 'https://frontlobby.com/en-ca/legal-defence/',
		action : ResourceAction.Visit,
	},
	californiaTenantNotice : {
		title  : 'California Tenant Notice',
		url    : `${IMAGE}/v1741208767/frontlobby/Resources/Clauses%2C%20Tenant%20Notices%2C%20Legal%20Consent/Tenant_Notice_Rent_Reporting_AB2747_1_m6wvhc.pdf`,
		action : ResourceAction.ShowDocument,
	},
	tenantNoticeForMembersToUse : getResourceByCountry((country: Country) => ({
		title : 'Tenant Notice for Members to Use',
		url   : country === Country.US
			? `${IMAGE}/v1742917258/frontlobby/Resources/Clauses%2C%20Tenant%20Notices%2C%20Legal%20Consent/Tenant_Notice_of_Rent_Reporting_US_ahtec3.pdf`
			: `${IMAGE}/v1742917258/frontlobby/Resources/Clauses%2C%20Tenant%20Notices%2C%20Legal%20Consent/Tenant_Notice_of_Rent_Reporting_CA_s7j3dv.pdf`,
		action : ResourceAction.ShowDocument,
	})),
	applicantNoticeOfRentReporting : getResourceByCountry((country: Country) => ({
		title : 'Applicant Notice of Rent Reporting',
		url   : country === Country.US
			? `${IMAGE}/v1742917258/frontlobby/Resources/Clauses%2C%20Tenant%20Notices%2C%20Legal%20Consent/Applicant_Notice_of_Rent_Reporting_US_piov3y.pdf`
			: `${IMAGE}/v1742923742/frontlobby/Resources/Clauses%2C%20Tenant%20Notices%2C%20Legal%20Consent/Notice_to_Applicant_of_Rent_Reporting_CA_b45dy7.pdf`,
		action : ResourceAction.ShowDocument,
	})),
	tenantInsuranceNotice : {
		title  : 'Tenant Insurance Notice',
		url    : `${IMAGE}/v1670701477/frontlobby/Resources/Clauses%2C%20Tenant%20Notices%2C%20Legal%20Consent/FrontLobby_Tenant_Insurance_Notice_iaq0i6.pdf`,
		action : ResourceAction.ShowDocument,
	},
	ontarioLTBGuidance : {
		title  : 'Ontario LTB Guidance',
		url    : `${IMAGE}/v1671642565/frontlobby/Resources/Clauses%2C%20Tenant%20Notices%2C%20Legal%20Consent/Overview_for_LTB_Disputes_-_Ontario_l5j02q.pdf`,
		action : ResourceAction.ShowDocument,
	},
	sampleCreditReportWithLCBTradeline : {
		title  : `Sample Credit Report with ${env.app.name.short} Tradeline`,
		action : ResourceAction.Function,
		function() {
			TenantScreeningReport.getSample(BaseRole.current).open();
		},
	},
	whatDoTenantsWant : {
		title  : 'What Do Tenants Want',
		url    : `${IMAGE}/v1633643476/frontlobby/Resources/Guides%20and%20Checklists/What_Do_Tenants_Want_FrontLobby_yq5z4f.pdf`,
		action : ResourceAction.ShowDocument,
	},
	tipsOnBeingASuccessfulLandlord : {
		title  : 'Tips On Being A Successful Landlord',
		url    : `${IMAGE}/v1633642779/frontlobby/Resources/Guides%20and%20Checklists/Tips_On_Being_A_Successful_Landlord_FrontLobby_hs99zc.pdf`,
		action : ResourceAction.ShowDocument,
	},
	tenantImportTemplate : getResourceByCountry((country: Country) => ({
		title    : 'Tenant Import Template',
		subtitle : 'Once filled out, send to support@frontlobby.com',
		url      : country === Country.US
			? '/assets/TenantImportTemplate_v1.2-US.xlsx'
			: '/assets/TenantImportTemplate_v1.2-CA.xlsx',
		action : ResourceAction.Download,
	})),
	theSuccessfulLandlordsChecklist : getResourceByCountry((country: Country) => ({
		title : country === Country.CA ? "The Successful Landlord's Checklist" :  "The Successful Landlord's Guide",
		url   : country === Country.CA
			? `${IMAGE}/v1633630924/frontlobby/Resources/Guides%20and%20Checklists/Successful_Landlord_s_Checklist_FrontLobby_hf52fi.pdf`
			: `${IMAGE}/v1633707436/frontlobby/Resources/Guides%20and%20Checklists/The_Successful_Landlords_Guide_CND_FrontLobby_wmghrc.pdf`,
		action : ResourceAction.ShowDocument,
	})),
	equifaxDataContributorAgreement : getResourceByCountry((country: Country) => ({
		title : 'Data Contributor Agreement',
		url   : country === Country.US
			? `${IMAGE}/v1689361110/frontlobby/EFX%20Exhibit%20A/Data_Contributor_Agreement_-_USA_i0m2oj.pdf`
			: `${IMAGE}/v1689601911/frontlobby/EFX%20Exhibit%20A/Data_Contributor_Agreement_-_Canada_uxrzpw.pdf`,
		action : ResourceAction.ShowDocument,
	})),
	howToRegisterAPaymentPlan : {
		title  : 'How to Register a Payment Plan',
		url    : 'https://help.frontlobby.com/how-do-i-record-a-payment-plan',
		action : ResourceAction.Visit,
	},
	consumerReportAccessAgreement : {
		title  : 'Consumer Reports Access Agreement',
		url    : `${IMAGE}/v1689777529/lcbapp/Consumer_Reports_Access_Agreement_uqwqwa.pdf`,
		action : ResourceAction.ShowDocument,
	},
	collectionsServiceAgreement : {
		title  : 'Collections Service Agreement',
		url    : `${IMAGE}/v1667506078/lcbapp/Resources/Legal%20Resources/SECCI_-_Collection_Services_Agreement_Form_FrontLobby_uu6j0a.pdf`,
		action : ResourceAction.ShowDocument,
	},
};
/* eslint-enable max-len */

/**
 * @returns A helper function that returns a resource based on the country (which by default takes the current org's country).
 */
function getResourceByCountry(multiCountryResource: (country: Country) => Resource) {
	return (country?: Country | HasCountry) => {
		country ??= BaseRole.current?.org?.country ?? BaseRole.current?.user.country;
		country   = typeof country === 'string' ? country : country?.country;
		return multiCountryResource(country ?? Country.CA);
	};
}


export type Resources = typeof resources[keyof typeof resources];
