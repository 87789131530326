import 'reflect-metadata';
import Moment                     from 'moment';
import { RelationMetadataArgs }   from 'typeorm/metadata-args/RelationMetadataArgs';
import RandomGenerator            from 'typeorm/util/RandomGenerator';
import { QueryDeepPartialEntity } from 'typeorm/query-builder/QueryPartialEntity';
import { RelationType }           from 'typeorm/metadata/types/RelationTypes';
import typeorm, { EntityMetadata, ObjectType, ValueTransformer, ObjectLiteral, ColumnOptions } from 'typeorm';
import {
	PrimaryGeneratedColumn, VersionColumn, CreateDateColumn, UpdateDateColumn, getMetadataArgsStorage, BeforeInsert, BeforeUpdate, Index
} from '$/lib/typeormExt';

import env, { Platform }             from '$/lib/env';
import { DateTransformer }           from '$/lib/columnTransformers';
import Validate, { ValidationMixin } from '$/lib/Validate';
import Errors                        from '$/lib/Errors';
import { getAncestorClasses, isSubclass, getPropertyDescriptor as getNativePropDesc, isDeprecated, hasMixin, getPropertyDescriptor } from '$/lib/utils';

import Permissions, { Context }                from '$/entities/lib/Permissions';
import { Entity, Column, extraEntityMetadata } from '$/entities/BaseEntityExt';
import type { FindOneOptions, FindManyOptions, UpdateOneOptions, ToObjectOptions } from '$/entities/BaseEntityExt';

export * from '$/entities/BaseEntityExt';

/**
 * Snapshots of entity @Column properties at various points in time.
 * For use in comparison for changes when saving.
 */
const snapshots   = new WeakMap();
const newSnapshot = Symbol('isNew');	// special snapshot value that indicates the entity is new

/**
 * Cache for calls to getPropertyDescriptors()
 */
const entityPropertyDescriptors: Map<typeof BaseEntity, PropertyDescriptor[]> = new Map();

/**
 * The type for the ID of an entity.
 */
export type EntityID = string;

/**
 * The type for the $class of an entity
 */
export type EntityClassName = string;

// SHOULDDO: convert to a proper mixin if Typescript ever supports mixins properly
// eslint-disable-next-line
export interface BaseEntity extends ValidationMixin { }

/**
 * The regular expression for entity IDs (UUID v4)
 */
export const entityIDRegExp = /[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/;

/**
 * Common members for client & server BaseEntity.
 */
@Entity({ common : true })
export abstract class BaseEntity extends typeorm.BaseEntity {

	@BeforeInsert()
	@BeforeUpdate()
	private async autoFixValidationBeforeSave() {
		if (hasMixin(this.constructor as Class, ValidationMixin, { includeAncestors : true })) {
			await this.autofixValidationErrors();
		}
	}

	@PrimaryGeneratedColumn('uuid')
	@Permissions({ write : (context: Context, entity) => entity.isNew ? '' : 'id cannot be updated' })		// allow writing of id only on inserts
	@Validate({ required : true, maxLength : 36, regex : entityIDRegExp })
	id: EntityID = newID();

	@VersionColumn()
	@Permissions({ write : systemPermission })
	ver: number = 0;

	@CreateDateColumn({ transformer : DateTransformer })
	@Permissions({ write : systemPermission })
	createdOn: Date;

	@UpdateDateColumn({ transformer : DateTransformer })
	@Permissions({ write : systemPermission })
	updatedOn: Date;

	/**
	 * If this value is non-null, it means the entity has been soft-deleted.
	 * It's still a record in the DB but it will be automatically filtered out of find and update queries.
	 * However, a direct request via ID will still be possible (so as not to break references) and the only
	 * update that can be made is to undelete it by nulling this value.
	 */
	@Column({ nullable : true })
	@Permissions({ write : [
		Permissions.serverOnly,
		(context, entity) => entity.isNew ? 'cannot archive a new entity' : '',
	] })
	@Index()
	archivedOn: Date = null;

	get $class(): EntityClassName {
		return this.constructor.name;
	}

	/**
	 * @returns true if this entity has never been saved
	 */
	get isNew() {
		return !this.ver;
	}

	/**
	 * @returns true if this entity has been soft-deleted.
	 */
	get isArchived() {
		return this.isArchivedOn(new Date());
	}

	/**
	 * @return true if this entity is archived on the given date
	 */
	isArchivedOn(date: Date | Moment.Moment) {
		// round off to nearest second as the milliseconds might be truncated on one of the values
		return !!this.archivedOn && Math.ceil(this.archivedOn.valueOf() / 1000) <= Math.ceil(date.valueOf() / 1000);
	}

	/**
	 * Merges any properties of obj that match Columns of this entity.
	 * @param {Object}   obj
	 * @param {Object}   [options]
	 * @param {boolean}  [options.commonColumnsOnly=false]       if true, only merges Columns from the common model
	 * @param {boolean}  [options.applyTransformers=true]        if false, does not apply Column's transformers
	 * @param {string[]} [options.exclude=[]]                    if populated, skips merging the specified fields
	 */
	mergeColumns(obj, { commonColumnsOnly = false, applyTransformers = true, cache = [], exclude = [] } = {}): this {
		if (!obj || cache.includes(this)) {
			return;
		}

		cache.push(this);

		const clazz = this.entityClass;
		clazz.getPropertyDescriptors().forEach(col => {
			let property = col.property;

			if (property === '$class' || (commonColumnsOnly && !col.isCommon) || exclude.includes(property)) {
				return;
			}

			// ignore properties that are not writable
			const propDesc = getPropertyDescriptor(this, property);
			if (propDesc && propDesc.writable !== true && !propDesc.set) {
				return;
			}

			if (!(property in obj)) {
				// check if the obj has a value under the column name and if there is correspondingly named getter
				const propDesc = getNativePropDesc(clazz, col.column);
				if (!(propDesc && propDesc.get && propDesc.set && col.column in obj)) {
					return;
				}
				property = col.column;
			}

			let value = obj[property];

			if (col.isRelationship) {
				if (typeof value === 'string') {		// assume value is an ID
					this[`${property}Id`] = value;
					if (this[property] && this[property].id === value) {
						return;		// value is an ID and matches the existing reference ID so nothing to do
					}
					value = undefined;
					// COULDDO: find the new entity and set it as the value (would require an async call)
				}
				else if (value instanceof Object) {
					value = createOrUpdate(this[property], value, col.type);
				}
				else if (value === undefined) {
					return;	// undefined means the new value is unknown (could be empty or could be another entity)
				}
				else if (value) {
					throw new Error(`unknown reference value: ${value}`);
				}
			}
			else if (col.isRelationshipInverse && value) {
				const existingEntities: BaseEntity[] = this[property];
				if (Array.isArray(value)) {
					value = value.map(v => createOrUpdate(existingEntities?.find(e => e.id === v.id), v, null));
				}
				else if (value instanceof Object) {
					value = createOrUpdate(this[property], value, null);
				}
			}
			// clone arrays and POJOs
			else if (value && (value.constructor === Object || value.constructor === Array || col.type === Array)) {
				if (col.type === Array && typeof value === 'string' && !col.columnOptions.transformer) {
					value = value.split(',');
				}
				value = _.clone(value);
			}

			if (applyTransformers) {
				const transformer = col.columnOptions.transformer;
				if (transformer) {
					(_.castArray(transformer) as ValueTransformer[]).forEach(transformer => {
						value = transformer.from(value);
					});
				}
			}

			this[property] = value;
		});

		return this;

		function createOrUpdate(entity: BaseEntity, obj, possibleClass) {
			if (entity?.id === obj.id && entity?.$class === obj.$class) {
				entity.mergeColumns(obj, { commonColumnsOnly, applyTransformers, cache });
				return entity;
			}
			return createEntity(obj, { possibleClass, cache });
		}
	}

	/**
	 * This method takes a snapshot of the current @Column fields for later comparison.
	 * Call this before making changes to the entity which might eventually be saved.
	 * @param {Object}  [options]
	 * @param {boolean} [options.force] if true, creates a new snapshot even if one already exists or even if the entity is new
	 */
	makeEditable({ force = false } = {}) {
		if (snapshots.has(this) && !force) {
			return this;
		}
		if (this.isNew && !force) {
			snapshots.set(this, newSnapshot);	// if makeEditable is called on new entities, make it so that all of their properties appear modified
		}
		else {
			const columns = this.entityClass.getPropertyDescriptors().map(desc => desc.property);
			snapshots.set(this, _.cloneDeep(_.pick(this, columns)));
		}
		return this;
	}

	/**
	 * Returns true if all of the given properties has changed since the last call to makeEditable()
	 * or, if no property is specified, if any property has changed on this entity.
	 * `undefined` is returned if the entity is not new and has never had makeEditable called on it
	 * @param propertyNames  name of the property or properties to check against
	 * @param options.only  check only all of the specified properties are edited and nothing else
	 */
	isEdited(propertyNames?: PossibleArray<keyof this>, { only = false, anyOf = false } = {}): boolean {
		const snapshot = snapshots.get(this);
		if (snapshot === newSnapshot || (!snapshot && this.isNew)) {
			return true;
		}
		if (!snapshot) {
			// assert: this.isNew === false
			return undefined;	// can't tell whether anything has or has not been edited but `undefined` is still a falsy value
		}

		const editedProperties = this.getEditedProperties() as (keyof this)[];
		if (!propertyNames) {
			return editedProperties.length > 0;
		}

		propertyNames = _.castArray(propertyNames);

		const editedIncludesSelected = propertyNames[anyOf ? 'some' : 'every'](property => editedProperties.includes(property));
		return editedIncludesSelected && (!only || editedProperties.every(property => (propertyNames as (keyof this)[]).includes(property)));
	}

	/**
	 * Resets all or the selected modified fields to the most recent snapshot
	 */
	resetEdited(propertyName?: keyof this) {
		const snapshot = snapshots.get(this) || (this.isNew ? new (this.entityClass as any)() : undefined);
		if (!snapshot) {
			return; // entity isn't new and has not been edited before
		}

		let columns = propertyName && this.isEdited(propertyName)  ? [ propertyName ] as string[] : this.getEditedProperties();

		// do not reset 'id' in case entity is new and has already used its id as a reference somewhere else
		columns = _.without(columns, 'id');

		_.assign(this, _.cloneDeep(_.pick(snapshot, columns)));
		return this;
	}

	/**
	 * @param {Object}  [options]
	 * @param {boolean} [options.includeRelations=true] if false, excludes regular relationship fields
	 * @returns the names of all edited properties of this entity always excluding inverse-relationship fields.
	 */
	getEditedProperties({ includeRelations = true } = {}): string[] {
		const snapshot = snapshots.get(this);
		if (!snapshot && !this.isNew) {
			return undefined;	// can't tell whether anything has or has not been edited
		}

		const props = this.entityClass.getPropertyDescriptors()
			.filter(prop => !prop.isRelationshipInverse && (includeRelations || !prop.isRelationship));

		if (snapshot === newSnapshot || (!snapshot && this.isNew)) {
			return props.map(desc => desc.property);
		}

		return props.filter(desc => !haveEqualValues(desc, snapshot, this)).map(desc => desc.property);
	}

	/**
	 * @returns the values of all fields before calling makeEditable and updating fields
	 */
	getOldValue(): Record<keyof this, any>;

	/**
	 * @param {string} [propertyName] returns the old value of just this property
	 * @returns the value of a field before calling makeEditable and updating the field
	 */
	getOldValue(propertyName: keyof this): any;

	getOldValue(propertyName?: keyof this) {
		const snapshot = snapshots.get(this);
		if (!snapshot || snapshot === newSnapshot) {
			return undefined;		// new entities do not have old values
		}
		return propertyName ? snapshot[propertyName] : _.clone(snapshot);
	}

	/**
	 * Returns true if this entity has a snapshot.
	 */
	get isEditable(): boolean {
		return snapshots.has(this);
	}

	/**
	 * Creates a new instance of this entity but with all fields the same.
	 * @param {Boolean} [asNew=false] if true, returned clone will have a new ID
	 * @param {string[]} [relations] also creates clones of any entities for the given relations
	 */
	clone({ asNew = false, relations = [] }: { asNew?: boolean; relations?: string[] } = {}): this {
		const newEntity = new (this.constructor as Class)() as this;
		newEntity.mergeColumns(asNew ? _.omit(this, BaseEntity.getPropertyDescriptors().map(desc => desc.property)) : this);

		relations.forEach(relation => {
			const value = newEntity[relation];
			if (!value || value.length === 0) {
				return;
			}

			const desc = this.entityClass.getPropertyDescriptor(relation, { includeSubclasses : true });
			if (desc.isRelationship) {
				newEntity[relation] = value.clone();
			}
			else if (desc.isRelationshipInverse) {
				newEntity[relation] = value.map(entity => entity.clone());
			}
		});

		return newEntity;
	}

	/**
	 * Returns an entity with this same class and id.
	 * If one doesn't exist yet, uses this entity and returns that.
	 * This is useful for distributed processes all making sure that a particular entity exists without coordinating.
	 * @param  {Object} [query=this.id] the unique filter that the entity must satisfy
	 */
	async ensureExists(query: string | ObjectLiteral = { id : this.id }): Promise<this> {
		let existingEntity: BaseEntity;
		let lastError;
		let attempts = 0;

		while (!existingEntity && attempts < 2) {
			existingEntity = await this.entityClass.findOne({ where : query });

			if (!existingEntity) {
				try {
					await this.save();
					existingEntity = this;	/* eslint-disable-line @typescript-eslint/no-this-alias */
				}
				catch (e) {
					lastError = e;
					attempts++;
				}
			}
		}

		if (!existingEntity) {
			throw lastError;
		}

		return existingEntity as this;
	}

	/**
	 * Returns an entity with this same class and id.
	 * If one doesn't exist yet, uses this entity and returns that.
	 * If one does exist, use it and update its values, then return that.
	 * @param  {Object} [query=this.id] the unique filter that the entity must satisfy
	 */
	async sync(query: string | ObjectLiteral = { id : this.id }): Promise<this> {
		let existingEntity = await this.entityClass.findOne({ where : query, editable : true } as any) as BaseEntity;
		if (!existingEntity) {
			existingEntity = this;		/* eslint-disable-line @typescript-eslint/no-this-alias */
		}
		else {
			_.assign(existingEntity, _.omit(this, BaseEntity.getPropertyDescriptors().map(desc => desc.property)));
		}

		return await existingEntity.save() as this;
	}

	/**
	 * Reloads the data in this entity.
	 * @param {Object}   [options]
	 * @param {String[]} [options.includeRelations] a list of relation fields to also reload (by default, relations are not reloaded)
	 */
	async reload({ includeRelations = [] } = {}): Promise<any> {
		if (this.isNew) {
			throw new Error('cannot reload a new entity');
		}

		if (includeRelations.length > 0) {
			includeRelations = _.intersection(
				includeRelations,
				this.entityClass.getPropertyDescriptors().filter(desc => desc.isRelationship || desc.isRelationshipInverse).map(desc => desc.property)
			);
		}

		const results = await this.entityClass.findOneRaw({ where : { id : this.id }, relations : includeRelations });
		this.mergeColumns(results, { commonColumnsOnly : env.platform === Platform.Client });
		if (this.isEditable) {
			this.makeEditable({ force : true });		// force a clean snapshot since fields are refreshed
		}
		return results;
	}

	/**
	 * Soft-deletes the current entity in the database.
	 */
	async archive() {
		return this.entityClass.archive(this);
	}

	/**
	 * Hard deletes the current entity from the database.
	 */
	async remove(): Promise<this> {
		await this.entityClass.remove(this);
		return this;
	}

	/**
	 * Loads the associated relation given the name of the field for the relation.
	 * @param {object}   [options]
	 * @param {boolean}  [options.required=false]   if true, throws an error if the given relation cannot be found
	 * @param {boolean}  [options.reload=false]     if true, forces a reload even if there's already something loaded in the field
	 * @param {boolean}  [options.editable=false]   if true, calls makeEditable on the relation(s) (if found)
	 * @param {boolean}  [options.withDeleted=false] if true, includes any archived entities if the field is a OneToMany relation
	 * @param {string[]} [options.select=undefined] Array of fields to be selected from the related entity
	 */
	async loadRelation<T>(relationFieldName: string, { required = false, reload = false, editable = false, withDeleted = false, select = undefined } = {}): Promise<T> {
		if (relationFieldName === '') {
			return this as unknown as T;
		}

		let subRelationFieldNames = '';
		if (relationFieldName.includes('.')) {
			const relationFieldNames = relationFieldName.split('.');
			relationFieldName        = relationFieldNames.shift();
			subRelationFieldNames    = relationFieldNames.join('.');
		}

		const desc = this.entityClass.getPropertyDescriptor(relationFieldName);
		if (!desc || (!desc.isRelationship && !desc.isRelationshipInverse)) {
			throw new Error('relation field name not found or not a relationship');
		}

		let value = this[relationFieldName];

		// one-to-many and certain one-to-one (other entities reference this one)
		if (desc.isRelationshipInverse) {
			if (_.isNil(value) || reload) {
				value = await (desc.type as unknown as typeof BaseEntity).find({ where : { [desc.inverseSideProperty] : this }, select, withDeleted });
				if (desc.relationshipType === 'one-to-one') {
					value = value[0];	// one-to-one relations only expect one result
				}
				this[relationFieldName] = value;
			}

			if (value !== undefined) {
				for (const entity of _.castArray(value)) {
					entity[desc.inverseSideProperty] = this;
					if (editable) {
						entity.makeEditable();
					}
					await entity.loadRelation(subRelationFieldNames, { required, reload, editable, withDeleted });
				}
			}

			// SHOULDDO: return an array of the targeted entities merged together
			return this[relationFieldName];
		}

		if (desc.isRelationship && (_.isNil(value) || reload)) {
			// many-to-one (this field references up to one other related entity)
			const relID = this[`${relationFieldName}Id`];
			if (!relID) {
				return checkForRequired(relID, this);
			}

			value = await (desc.type as unknown as typeof BaseEntity).findOne({ where : { id : relID }, select, withDeleted : true });

			this[relationFieldName] = checkForRequired(value, this);
		}

		if (value instanceof BaseEntity) {
			if (editable) {
				value.makeEditable();
			}
			value = await value.loadRelation(subRelationFieldNames, { required, reload, editable, withDeleted });
		}

		return value;

		function checkForRequired(value, entity: BaseEntity) {
			if (value) {
				return value;
			}
			if (required) {
				throw new Error(`relationship has no value but one is required: ${entity.constructor.name}.${relationFieldName}`);
			}
			return undefined;
		}
	}

	/**
	 * Returns a POJO for this.
	 */
	toObject(options: ToObjectOptions = {}): Partial<this> {
		return this.entityClass.toObject(this, options) as Partial<this>;
	}

	/**
	 * Tests whether this is an instance of the Entity with the given name.
	 */
	instanceof(className: string): boolean {
		const ancestors = getAncestorClasses(getEntityClass(className) as unknown as Class) as unknown as typeof BaseEntity[];
		// find the first common ancestor
		const commonAncestor = ancestors.find(ancestor => ancestor.isCommonEntity);
		return this instanceof commonAncestor;
	}

	getReferenceString() {
		return this.entityClass.getReferenceString(this);
	}

	/**
	 * Returns the class for this entity, a subclass of BaseEntity.
	 */
	get entityClass(): typeof BaseEntity {
		return this.constructor as typeof BaseEntity;
	}

	// #region STATIC MEMBERS

	/**
	 * Soft-deletes one or more of the given entities in the database.
	 */
	static async archive(entities: PossibleArray<BaseEntity | EntityID>) {		// eslint-disable-line @typescript-eslint/no-unused-vars
		throw new Errors.NotImplemented();
	}

	protected static getPropertyDescriptorsAll() {
		return entityPropertyDescriptors;
	}

	static getPropertyDescriptors(): PropertyDescriptor[] {
		// first check the cache
		let result = entityPropertyDescriptors.get(this);
		if (result) {
			return result;
		}

		const classes = getAncestorClasses(this as unknown as Class) as unknown as typeof BaseEntity[];

		result = getEntityMetadata()
			.filter(metadata => classes.includes(metadata.target as typeof BaseEntity))
			.map(metadata => {
				const propertyName = _.get(metadata, 'propertyName');
				const columnName   = _.get(metadata, 'column.name');
				const metadataRel  = metadata as RelationMetadataArgs;

				const result: PropertyDescriptor = {
					property              : Reflect.getOwnMetadata('propertyAlias', metadata.target, propertyName) || propertyName || columnName,
					column                : _.get(metadata, 'options.name') || columnName || propertyName,
					isCommon              : (metadata.target as typeof BaseEntity).isCommonEntity,
					relationshipType      : metadataRel.relationType,
					isRelationship        : metadataRel.relationType === 'many-to-one' || (metadataRel.relationType === 'one-to-one' && !metadataRel.inverseSideProperty),
					isRelationshipInverse : metadataRel.relationType === 'one-to-many' || (metadataRel.relationType === 'one-to-one' && !!metadataRel.inverseSideProperty),
					inverseSideProperty   : [ 'one-to-many', 'one-to-one' ].includes(metadataRel.relationType) ? metadataRel.inverseSideProperty as string : '',
					type                  : undefined,
					entity                : getEntityClass(this.name),
					columnOptions         : _.get(metadata, 'options', {}),
					isEager               : (metadataRel.options || {}).eager,
				};

				if (!result.property || isDeprecated(metadata.target as Class, result.property)) {
					return;
				}

				if (metadataRel.relationType === 'one-to-many' && typeof result.inverseSideProperty !== 'string') {
					throw new Error(`@OneToMany() decorator requires a string as the 2nd argument: ${this.name}.${result.property}`);
				}

				if (result.isRelationship || result.isRelationshipInverse) {
					const type = (metadata as RelationMetadataArgs).type;
					if (typeof type === 'function') {
						result.type = type();
					}
					else if (typeof type === 'string') {
						result.type = getEntityClass(type) as unknown as Class;
					}
				}
				else {
					result.type = Reflect.getMetadata('design:type', (metadata.target as Class).prototype, result.property) || result.columnOptions.type;
				}

				// special consideration for the property $class
				if (result.property === '$class') {
					result.type     = String;
					result.isCommon = true;
				}

				return result;
			})
			.filter(desc => !!desc)	// filter out blanks
		;

		entityPropertyDescriptors.set(this, result);
		return result;
	}

	static getPropertyDescriptor(propertyName: string, { includeSubclasses = false } = {}): PropertyDescriptor {
		let desc = _.find(this.getPropertyDescriptors(), { property : propertyName });

		// if not found, optionally check subclasses
		if (!desc && includeSubclasses) {
			for (const clazz of this.getDescendantClasses()) {
				desc = clazz.getPropertyDescriptor(propertyName);
				if (desc) {
					break;
				}
			}
		}
		return desc;
	}

	/**
	 * Returns true if this is an Entity defined in the common directory.
	 * Returns false if it's a platform-specific entity (ie. server/client)
	 */
	static get isCommonEntity(): boolean {
		return !!Reflect.getOwnMetadata('isCommonEntity', this);
	}

	/**
	 * Returns the API collection name for this entity.
	 * Defaults to the camel case version of the class name or use the @CollectionName decorator to set custom value.
	 */
	static getCollectionName(): string {
		return Reflect.getMetadata('collectionName', this) || _.camelCase(this.name);
	}

	/**
	 * Returns the URL for an API for this collection.
	 * @param {String} [suffixPath] if provided, appends this to the end of the Url
	 */
	static collectionUrl(suffixPath = ''): string {
		return `/api/${this.getCollectionName()}${_.ensureStartsWith(suffixPath, '/')}`;
	}

	/**
	 * Returns POJOs for the given objects with properties that reflect the Common entity properties for this class.
	 */
	static toObject(objects: any[],              options?: ToObjectOptions,      ancestors?: any[]): Partial<BaseEntity[]>;
	static toObject(objects: any,                options?: ToObjectOptions,      ancestors?: any[]): Partial<BaseEntity>;
	static toObject(objects: PossibleArray<any>, options:  ToObjectOptions = {}, ancestors: any[] = []): Partial<BaseEntity> | Partial<BaseEntity>[] {
		if (!objects) {
			return undefined;
		}

		const {
			fieldNamePrefix = this.name,
			mustBeBase      = true,
			mustBeEdited    = false,
			mustBeCommon    = true,
			excludeRelations = true,
		} = options;

		const wasArray = Array.isArray(objects);
		objects        = _.castArray(objects);

		// HACK: figure out how to better determine whether obj is a RowDataPacket
		const isRowDataPacket = _.get(Array.isArray(objects) ? objects[0] : objects, 'constructor.name') === 'RowDataPacket';
		let descriptors       = this.getPropertyDescriptors();
		if (mustBeCommon) {
			descriptors = descriptors.filter(desc => desc.isCommon);
		}

		const results = objects.map(obj => {
			const result: any = {};
			if (mustBeBase) {
				result.$class = isRowDataPacket ? this.name : obj.$class;
			}

			// check for circular references
			if (ancestors.includes(obj)) {
				result.id  = obj.id;
				result.ver = obj.ver;
				return result;
			}

			descriptors.forEach(desc => {
				let value;

				let propertyKey = desc.property;

				if (isRowDataPacket) {
					// result out of the DB
					if (desc.isRelationship) {
						// check if property represents a sub-entity
						if (obj[`${fieldNamePrefix}__${desc.property}_id`]) {
							value = (desc.type as unknown as typeof BaseEntity).toObject(obj, { ...options, fieldNamePrefix : `${fieldNamePrefix}__${desc.property}` });
						}
					}
					else {
						value = obj[`${fieldNamePrefix}_${desc.column}`];
					}
				}
				// @ts-ignore: desc.property is a string but obj.isEdited requires keyof this class
				else if ((mustBeBase && [ 'id', 'ver' ].includes(desc.property)) || !mustBeEdited || (obj instanceof BaseEntity && obj.isEdited(desc.property))) {
					// serializing entity instance likely to send to/from server
					value = obj[desc.property];

					if (desc.isRelationship || desc.isRelationshipInverse) {
						const isSubEntity = value
							&& isSubclass(BaseEntity as unknown as Class, desc.type)
							&& (
								(desc.isRelationship && typeof value === 'object')
								|| (desc.isRelationshipInverse && Array.isArray(value))
							)
						;

						if (excludeRelations) {
							value       = isSubEntity ? value.id : undefined;
							propertyKey = `${desc.property}Id`;
						}
						else {
							value = isSubEntity ? (desc.type as unknown as typeof BaseEntity).toObject(value, options, [ obj, ...ancestors ]) : undefined;
						}
					}
				}

				if (value !== undefined) {
					result[propertyKey] = value;
				}
			});

			return result;
		});

		return wasArray ? results : results[0];
	}

	/**
	 * @param {boolean} [leafClassesOnly] if true, only returns classes that are not subclassed by any other class
	 * @returns entity classes that are subclasses of this entity
	 */
	static getDescendantClasses<T extends typeof BaseEntity>(this: T, { leafClassesOnly = false } = {}): T[] {
		let classes = _(getEntityMetadata())
			.filter(metadata => this !== metadata.target && isSubclass(this as unknown as Class, metadata.target as Class))
			.map('target')
			.uniq()
			.valueOf() as unknown as typeof BaseEntity[];

		if (leafClassesOnly) {
			// filter out classes that have another class as a subclass
			classes = classes.filter(clazz => clazz.getDescendantClasses().length === 0);
		}

		return classes as T[];
	}

	/**
	 * Returns true if there's an entity given the specified conditions
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static async doesExist<T extends BaseEntity>(optionsOrConditions?: string|FindManyOptions<T>): Promise<boolean> {
		throw new Errors.NotImplemented();
	}

	/**
	 * Returns zero or one POJO that matches the given criteria.
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static async findOneRaw(optionsOrConditions?: string|any|FindOneOptions): Promise<Dictionary<any>> {
		throw new Errors.NotImplemented();
	}

	/**
	 * Returns zero or more POJOs that match the given criteria along with the number of total records for that where clause.
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static async findRawAndCount(optionsOrConditions?: FindManyOptions): Promise<[Dictionary<any>[], number]> {
		throw new Errors.NotImplemented();
	}

	/**
	 * Returns zero or more POJOs that match the given criteria.
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static async findRaw(optionsOrConditions?: FindManyOptions): Promise<Dictionary<any>[]> {
		return (await this.findRawAndCount(optionsOrConditions))[0];
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static async findOne<T extends typeof BaseEntity>(this: T, optionsOrConditions?: string|any|FindOneOptions<InstanceType<T>>): Promise<InstanceType<T>> {
		let result = await this.findOneActual(optionsOrConditions) as InstanceType<T>;
		if (!result && optionsOrConditions?.create) {
			// @ts-ignore: TS doesn't like that `this` is an abstract class but in practice `this` will be a non-abstract subclass
			result = (new this() as BaseEntity).mergeColumns(optionsOrConditions.where, { exclude : [ 'id', 'ver', 'createdOn', 'updatedOn' ] });
		}
		return result;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static async find<T extends typeof BaseEntity>(this: T, optionsOrConditions?: string|any|FindManyOptions<InstanceType<T>>): Promise<InstanceType<T>[]> {
		return this.findActual(optionsOrConditions) as unknown as InstanceType<T>[];
	}

	/**
	 * HACK: this method only exists to resolve Typescript type issues with overriding findOne in client/server subclasses
	 * SHOULDDO: figure out the proper typing, get rid of this, and make findOneActual in subclasses simply override findOne
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected static findOneActual(optionsOrConditions?: string|any|FindOneOptions): Promise<BaseEntity> {
		throw new Errors.NotImplemented();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected static findActual(optionsOrConditions?: string|any|FindOneOptions): Promise<BaseEntity[]> {
		throw new Errors.NotImplemented();
	}

	/**
	 * Updates a single entity given the entityID.
	 */
	static async updateOne<T extends BaseEntity>(
		this: ObjectType<T>,
		id: string,
		partialEntity: QueryDeepPartialEntity<T>,
		options: UpdateOneOptions = { }
	): Promise<T> {
		try {
			if (!id) {
				throw new Error(`invalid id parameter: ${id}`);
			}

			const entity = await (this as typeof BaseEntity).findOne(id) as T;
			if (!entity) {
				throw new Error(`entity not found: ${id}`);
			}

			entity.makeEditable();
			entity.mergeColumns(partialEntity);
			await entity.save();
			return entity;
		}
		catch (err) {
			if (options.ignoreErrors) {
				return undefined;
			}
			throw err;
		}
	}

	static async count(optionsOrConditions?: FindManyOptions): Promise<number>;

	/**
	 * Counts entities that match certain options and also returns counts by the distinct values of the given entity column/field.
	 */
	static async count(optionsOrConditions?: FindManyOptions & { countBy: string }): Promise<Record<string, number>>;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static async count(optionsOrConditions?: FindManyOptions & { countBy?: string }): Promise<number | Record<string, number>> {
		throw new Errors.NotImplemented();
	}

	static getReferenceString(entity: BaseEntity) {
		return `${entity.$class}/${entity.id}`;
	}

	// #endregion STATIC MEMBERS

}

/**
 * Generates a new UUID suitable for entity IDs.
 */
export function newID(): EntityID {
	return RandomGenerator.RandomGenerator.uuid4();
}

/**
 * @returns true if the given parameter is an entity ID (ie. uuid4 string)
 */
export function isEntityID(possibleID): boolean {
	return typeof possibleID === 'string' && entityIDRegExp.test(possibleID);
}

/**
 * Filter out any "duplicate" entities, that is entities that have a common as well as a platform-specific
 * override so that typeorm will always find the correct entity subclass when de-serializing the entity.
 */
export function adjustTypeormMetadata(entityMetadata: EntityMetadata[]) {
	if (!entityMetadata || entityMetadata.length === 0) {
		return;
	}

	const entitiesToDelete = [];

	_(entityMetadata).groupBy('name').forEach(entities => {
		if (entities.length > 1) {
			entitiesToDelete.push(...entities.filter(entity => _.isFunction(entity.target) && entities.some(entity2 => entity.target.isPrototypeOf(entity2.target))));
		}
	});

	// since entityMetadatas is marked as "readonly", must adjust the array in place
	_.pullAll(entityMetadata, entitiesToDelete);

	// sanity check
	_(entityMetadata).groupBy('name').forEach(entities => {
		if (entities.length > 1) {
			throw new Error(`multiple Entities declared using the same name: ${(entities[0].target as Function).name}`);
		}
	});

	// adjust propertyPaths to account for Alias decorator
	entityMetadata.forEach(metadata => {
		metadata.columns.forEach(column => {
			if (column.target) {
				const alias = Reflect.getMetadata('propertyAlias', column.target, column.propertyName);
				if (alias) {
					column.propertyAliasName = column.propertyName = column.propertyPath = alias;
				}
			}
		});
	});

	// also adjust any child metadata
	entityMetadata.forEach(entity => adjustTypeormMetadata(entity.childEntityMetadatas));
}

function getEntityMetadata() {
	const metaData = getMetadataArgsStorage();
	return [ ...metaData.columns, ...metaData.relations, ...metaData.inheritances, ...metaData.tables, ...extraEntityMetadata ]
		.filter(col => typeof col.target === 'function');
}

/**
 * @param {Object}       [options]
 * @param {Class}        [options.possibleClass] if obj doesn't have a $class property, use this class
 * @param {BaseEntity[]} [options.cache] a cache of already created BaseEntities to re-use instead of creating new ones
 */
export function createEntity(obj: any, { possibleClass = null, makeEditable = false, cache = [] } = {}) {
	if (obj instanceof BaseEntity) {
		return obj;
	}

	let $class = getEntityClass(obj.$class);

	if (!$class && possibleClass && possibleClass.name === obj.$class) {
		$class = possibleClass;
	}

	if (!$class) {
		console.warn(`Unknown entity class: ${obj.$class}`);
		return;
	}

	// search the cache first
	const cachedItem = cache.find(entity => entity.constructor === $class && entity.id === obj.id);
	if (cachedItem) {
		return cachedItem;
	}

	// instantiate any entities reference in obj
	$class.getPropertyDescriptors().forEach(desc => {
		if (desc.isRelationship && obj[desc.property] && typeof obj[desc.property] === 'object') {
			obj[desc.property] = createEntity(obj[desc.property], { cache, possibleClass : desc.type as unknown as typeof BaseEntity });
		}
		else if (desc.isRelationshipInverse && Array.isArray(obj[desc.property])) {
			obj[desc.property] = obj[desc.property].map(rawEntity => createEntity(rawEntity, { cache, possibleClass : desc.type as unknown as typeof BaseEntity }));
		}
		else if (desc.columnOptions.type === 'simple-array' && typeof obj[desc.property] === 'string') {
			obj[desc.property] = obj[desc.property].split(',');
		}
	});

	const entity: BaseEntity = new ($class as unknown as Class)();
	entity.mergeColumns(obj, { cache });
	cache.push(entity);

	if (makeEditable) {
		entity.makeEditable();
	}

	return entity;
}

/**
 * Returns the platform-specific entity class given the entity class name.
 */
export const getEntityClass = _.memoize(function<T extends typeof BaseEntity>(entityClassName: string): T {
	const entities = getEntityMetadata()
		.map(col => col.target)
		.filter(target => typeof target === 'function' && target.name === entityClassName);

	// filter out any entities that are base classes of one of the other entities
	return entities.filter(baseEntity => entities.every(entity => !baseEntity.isPrototypeOf(entity)))[0] as T;
});

/**
 * Populate global namespace with all entities to make them easy to access in the console/REPL.
 */
export function loadEntityClasses(global?) {
	// @ts-ignore window is not always available on the server
	global = typeof globalThis === 'object' ? globalThis : window;

	BaseEntity.getDescendantClasses().forEach(clazz => {
		// ensure that the global entry is a subclass of any previous entity of the same name
		if (!global[clazz.name] || isSubclass(global[clazz.name], clazz as unknown as Class)) {
			global[clazz.name] = clazz;
		}
	});
}

/**
 * Helper function that extracts the id from obj (if any)
 */
export function getEntityID(obj: BaseEntity | EntityID | ObjectLiteral): EntityID {
	return obj === null || obj === undefined || typeof obj === 'string' ? obj : obj.id;
}

export function haveEqualValues(desc: PropertyDescriptor, obj1, obj2) {
	if (desc.isRelationship) {
		const obj1Value = obj1[desc.property] !== undefined ? obj1[desc.property] : obj1[`${desc.property}Id`];
		const obj2Value = obj2[desc.property] !== undefined ? obj2[desc.property] : obj2[`${desc.property}Id`];
		return getEntityID(obj1Value) == getEntityID(obj2Value);
	}
	// apply any DB transformers into order to the values as they would be stored in the DB
	const transformer = _.get(desc, 'columnOptions.transformer.to', _.identity);
	return _.isEqual(transformer(obj1[desc.property]), transformer(obj2[desc.property]));
}

function systemPermission() {
	return 'this field cannot be modified; the system writes this field automatically';
}

/**
 * Data structure that describes the meta data for a property/column of an Entity.
 **/
export interface PropertyDescriptor {
	/**
	 * The name of the property as it exists on the Entity's class definition.
	 */
	property: string;

	/**
	 * The name of the column in the database.
	 */
	column: string;

	/**
	 * True if the field comes from a common Entity (thus generally available to clients)
	 * The entity is decorated with the CommonEntity decorator.
	 */
	isCommon: boolean;

	/**
	 * The data type of the field.
	 */
	type: Class;

	relationshipType: RelationType;

	/**
	 * True if this field defines a relationship between two entities (many-to-one or one-to-one).
	 */
	isRelationship: boolean;

	/**
	 * True if this field defines an inverse relationship (that is a one-to-many)
	 */
	isRelationshipInverse: boolean;

	/**
	 * True if this field is a relationship/inverse relationship AND is eagerly loaded.
	 */
	isEager: boolean;

	/**
	 * If this field defines a one-to-many relationship between entities,
	 * then this value is the name of the property on the other entity that points to this one.
	 */
	inverseSideProperty: string;

	/**
	 * The Entity class in which this property exists. If the class inherits from another class, this will be the child class, not the base class.
	 */
	entity: typeof BaseEntity;

	/**
	 * Any options defined in the @Column decorator
	 */
	columnOptions: ColumnOptions;
}
