import { BaseRole }        from '$/entities/roles/BaseRole';
import { RenterInterests } from '$/entities/roles/RenterInterests';
import { CommonEntity, Column, ChildEntity, EntityID } from '$/entities/BaseEntity';
import Permissions         from '$/entities/lib/Permissions';

/**
 * Renters are users whose role is that of renting properties from landlords.
 */
@ChildEntity() @CommonEntity()
export class Renter extends BaseRole {

	@Column({ type : 'json', default : () => "('{}')", nullable : false })
	interests: RenterInterests = new RenterInterests();

	@Column({ type : 'simple-array', default : () => "('')", nullable : false })
	@Permissions({ write : Permissions.serverOnly })
	potentialMatches: EntityID[] = [];

}
