import Errors from '$/lib/Errors';

import { File }                             from '$/entities/File';
import { VerificationDoc }                  from '$/entities/User';
import { Verification, VerificationStatus } from '$/entities/verifications/Verification';
import { ChildEntity, CommonEntity, EntityID } from '$/entities/BaseEntity';

export { VerificationStatus } from '$/entities/verifications/Verification';

/**
 * Identity verification by uploading authoritative-issued documents (eg: driver's license, passport, etc)
 */
@ChildEntity() @CommonEntity()
export class DocumentsVerification extends Verification {

	get additionalData() {
		return this.additionalDataValue as { files: FileResult[] };
	}
	set additionalData(value: { files: FileResult[] }) {
		this.additionalDataValue = value;
	}

	get files(): FileResult[] {
		return this.additionalData?.files;
	}
	set files(files: FileResult[]) {
		this.additionalData = { files };
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static upload(docs: VerificationDoc[], progressUpdate?: (uploadProgressPercentage: number) => void): Promise<VerificationDoc[]> {
		throw new Errors.NotImplemented();
	}

	getFileResultById(fileId: string): FileResult {
		return this.additionalData.files.find(file => file.fileId === fileId);
	}

	async getFilesWithEntities(): Promise<FileResultFull[]> {
		const fileEntities = await File.findByIds(this.files.map(file => file.fileId));
		return this.files.map(file => ({ ...file, file : fileEntities.find(fileEntity => fileEntity.id === file.fileId) }));
	}

	rejectFile(fileIdOrFile: EntityID | FileResult, rejectionReason: string) {
		const fileToReject  = typeof fileIdOrFile === 'string' ? this.getFileResultById(fileIdOrFile) : fileIdOrFile;
		fileToReject.status = VerificationStatus.Rejected;
		fileToReject.reason = rejectionReason;
	}

	approveFile(fileIdOrFile: EntityID | FileResult) {
		const fileToApprove  = typeof fileIdOrFile === 'string' ? this.getFileResultById(fileIdOrFile) : fileIdOrFile;
		fileToApprove.status = VerificationStatus.Approved;
	}

}

export interface FileResult {
	fileId: string;
	status: VerificationStatus;
	reason?: string;
	lastUpdatedBy?: EntityID;
}

export interface FileResultFull extends FileResult {
	file: File;
}
