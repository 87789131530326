import pluralize      from 'pluralize';
import { columns, container, lightContent, outlinedContent, pdfColors, stack, table } from '$/lib/pdfmake';
import { pdfFormats } from '$/screens/App/TenantScreening/components/PdfReport/TenantScreeningReport';

import { BackgroundCheck }               from '$/entities/tenantScreening/BackgroundCheck';
import { CriminalAlert, CriminalResult } from '$/entities/tenantScreening/lib/BackgroundCheckData';

import { TenantCheckPdf } from './TenantCheckPdf';

import softcheckExplanationImage from '$/assets/tenantScreening/Softcheck.svg?raw';
import possibleMatchImage        from '$/assets/tenantScreening/PossibleMatch.svg?raw';
import likelyMatchImage          from '$/assets/tenantScreening/LikelyMatch.svg?raw';
import CertnSVG                  from '$/assets/certn-logo.svg?raw';

export class BackgroundCheckPdf extends TenantCheckPdf {

	private backgroundCheck: BackgroundCheck;
	content: any[];

	constructor(backgroundCheck: BackgroundCheck) {
		super();
		this.backgroundCheck = backgroundCheck;
		this.content         = [
			this.sectionTitle('Background Check', {
				svg              : CertnSVG,
				width            : 70,
				alignment        : 'right',
				relativePosition : { x : 0, y : -6 },
			}),
		];

		if (this.backgroundCheck.isComplete && this.backgroundCheck.report) {
			this.content.push(
				this.softcheckExplanation,
				outlinedContent(stack(
					this.employmentHistory,
					this.residentialHistory,
					this.educationHistory
				), [ 0, 12, 0, 0 ]),
				this.criminalResultsSection
			);
		}
		else if (!this.backgroundCheck.report) {
			this.content.push(
				container({ margin : [ 0, 4, 0, 0 ], wrapper : outlinedContent }, { text : 'No results found', layout : 'default' })
			);
		}
		else {
			this.content.push(
				this.error(this.backgroundCheck)
			);
		}
	}

	get employmentHistory() {
		return container({ title : 'Employment History' }, {
			layout : 'lightTableWhiteHeader',
			table  : table(this.backgroundCheck.report.riskInfo.employers, [
				{ key : 'employer' },
				{ key : 'details' },
				{ key : 'startDate', formatter : pdfFormats.formatMonthYear },
				{ key : 'endDate', formatter : pdfFormats.formatMonthYear },
			]),
		});
	}

	get residentialHistory() {
		return container({ title : 'Residential History' }, {
			layout : 'lightTableWhiteHeader',
			table  : table(this.backgroundCheck.report.riskInfo.addresses, [
				{ key : 'address' },
				{ key : 'city' },
				{ key : 'provinceState', label : 'Province/State' },
				{ key : 'country' },
				{ key : 'postalCode' },
			]),
		});
	}

	get educationHistory() {
		// Would get a bit of weird spacing on bottom of outlined content without 0 bottom padding
		return container({ title : 'Education History', padding : [ 8, 8, 8, 0 ] }, {
			layout : 'lightTableWhiteHeader',
			table  : table(this.backgroundCheck.report.riskInfo.educations, [
				{ key : 'institution' },
				{ key : 'details' },
				{ key : 'startDate', formatter : pdfFormats.formatMonthYear },
				{ key : 'endDate', formatter : pdfFormats.formatMonthYear },
			]),
		});
	}

	get softcheckExplanation() {
		return container(
			{
				wrapper : outlinedContent,
				padding : 0,
			},
			columns(
				container(
					{
						title   : 'Softcheck™',
						padding : [ 12, 16, 24, 12 ],
					},
					`Softcheck™ is a real-time public information search that utilizes over 110,000 databases from over 240 countries
					looking for criminal records, court decisions, negative press, social profiles, public biographies, past employment,
					and more. When we complete our search it's possible we may receive results for more than one individual that could
					correspond to the Applicant being searched. Therefore we attempt to give each one of these individual records a
					Similarity Score so you know which record is most likely to correspond to your Applicant.`
				),
				container(
					{
						wrapper      : lightContent,
						otherOptions : {
							width : 'auto',
						},
						padding : 12,
					},
					{
						svg   : softcheckExplanationImage,
						width : 150,
					}
				)
			)
		);
	}

	get criminalResultsSection() {
		const matches = this.backgroundCheck.report.criminalResults.length;

		if (!matches) {
			return stack(new CriminalResultPdf(new CriminalResult()).content);
		}

		// Get the pdf content for each criminal result
		const criminalResults = this.backgroundCheck.report.criminalResults.map(result => stack(new CriminalResultPdf(result).content));

		return stack(
			this.sectionTitle(`${pluralize('Match', matches, true)} Found`),
			stack(criminalResults)
		);
	}

}

class CriminalResultPdf extends TenantCheckPdf {

	private result: CriminalResult;
	content: any[];

	constructor(result: CriminalResult) {
		super();
		this.result  = result;
		this.content = [
			outlinedContent(stack(
				this.confidence,
				this.criminalAlertsTable('Criminal Scan', this.result.criminalAlerts ?? []),
				this.criminalAlertsTable('Fraud Scan', this.result.fraudAlerts ?? []),
				this.criminalAlertsTable('Sex Offender Scan', this.result.sexOffenderAlerts ?? []),
				this.criminalAlertsTable('Known Affiliations Scan', this.result.affiliationsAlerts ?? []),
				this.criminalAlertsTable('Global Sanctions & Enforcement Scan', this.result.ofacAlerts ?? []),
				this.criminalAlertsTable('Public Safety Scan', this.result.publicSafetyAlerts ?? []),
				this.sources,
				this.addresses,
				this.additionalInfo
			), [ 0, 12, 0, 0 ]),
		];
	}

	get confidence() {
		const hasMatches = !_.isEmpty(this.result);

		let confidenceText = hasMatches ? this.result.confidenceLabel.concat(' Match') : 'No Matches Found';
		if (this.result?.names?.length) {
			confidenceText = confidenceText.concat(` - ${this.result.names.map(name => pdfFormats.formatName(name)).join(' / ')}`);
		}

		const description = hasMatches
			? `The criminal identity Similarity Score is a comparison between the Applicant's self-provided information and the
				 corresponding information we find in our database. As a general guideline, if the results of our criminal identity
			   scan has a Similarity Score of 'Likely Match', Certn can confidently predict that the information presented below
				 corresponds to the Applicant being screened. However, if the criminal scan returns the results with a Similarity
				 Score of "Possible Match", the onus falls on the client to accurately verify the results.`.replace(/\s+/g, ' ')
			: 'The Softcheck™ was completed successfully and no records were found for the Applicant.';

		return lightContent(columns(
			container(
				{
					otherOptions : {
						width : 'auto',
					},
					padding : hasMatches ? [ 24, 24, 12, 24 ] : [ 24, 12, 12, 12 ],
				},
				{
					svg   : !hasMatches || this.result.confidenceLabel.toLowerCase() === 'likely' ? likelyMatchImage : possibleMatchImage,
					width : 50,
				}
			),
			container(
				{
					title   : confidenceText,
					padding : hasMatches ? [ 8, 12 ] : [ 8, 20 ],
				},
				description
			)
		));
	}

	get sources() {
		return container({ title : 'Sources' }, {
			layout : 'lightTableWhiteHeader',
			table  : table(this.result.sources, [
				{ key : 'publicationSource' },
				{ key : 'name' },
				{ key : 'url', width : '40%' },
				{ key : 'date', formatter : pdfFormats.formatDayMonthYear },
			]),
		});
	}

	get addresses() {
		return container({ title : 'Addresses' }, {
			layout : 'lightTableWhiteHeader',
			table  : table(this.result.addresses, [
				{ key : 'address' },
				{ key : 'city' },
				{ key : 'provinceState', label : 'Province/State' },
				{ key : 'country' },
				{ key : 'postalCode' },
			]),
		});
	}

	get additionalInfo() {
		const info = [
			{
				type : 'Aliases',
				data : this.result.aliases?.length ? this.result.aliases.join(', ') : 'None found',
			},
			{
				type : 'Dates of birth',
				data : this.result.datesOfBirth?.length ? this.result.datesOfBirth.map(pdfFormats.formatDayMonthYear).join(', ') : 'None found',
			},
			{
				type : 'Other',
				data : this.result.otherAlerts?.[0]?.description ?? 'None found',
				date : this.result.otherAlerts?.[0]?.date ?? 'None found',
			},
		];

		// Would get a bit of weird spacing on bottom of outlined content without 0 bottom padding
		return container({ title : 'Additional Information', padding : [ 8, 8, 8, 0 ] }, {
			layout : 'lightTableWhiteHeader',
			table  : table(info, [
				{ key : 'type', width : '20%' },
				{ key : 'data' },
				{ key : 'date' },
			]),
		});
	}

	criminalAlertsTable(section: string, alerts: CriminalAlert[]) {
		return container({ title : section }, {
			layout : 'lightTableWhiteHeader',
			table  : table(alerts, [
				{ key : 'category' },
				{ key : 'stage' },
				{ key : 'description', width : '40%' },
				{ key : 'date', formatter : pdfFormats.formatDayMonthYear },
			], {
				emptyText      : 'Cleared',
				emptyFillColor : pdfColors.green,
				rowFillColor   : pdfColors.red,
			}),
		});
	}

}
