import Errors                 from '$/lib/Errors';
import { SelectQueryBuilder } from '$/lib/typeormExt';

import { BaseEntity, CollectionName, Column, CommonEntity, ManyToOne } from '$/entities/BaseEntity';
import { RolePermission }       from '$/entities/roles/RolePermission';
import Permissions, { Context } from '$/entities/lib/Permissions';
import type { User }            from '$/common/entities/User';	// common on purpose to avoid TS cast error
import type { BaseRole }        from '$/entities/roles/BaseRole';

export enum VerificationStatus {
	Started   = 'started',
	Submitted = 'submitted',
	Approved  = 'approved',
	Rejected  = 'rejected',
}

/**
 * Base class for all verification types.
 */
@CommonEntity()
@CollectionName('verification')
@Permissions({
	create : [ Permissions.roleHasPermission(RolePermission.IdentityVerification) ],
	read   : onlySelfOrSupportRead,
	write  : [ Permissions.roleHasPermission(RolePermission.IdentityVerification) ],
	delete : [ Permissions.roleHasPermission(RolePermission.IdentityVerification) ],
})
export abstract class Verification extends BaseEntity {

	@Column({ type : 'enum', enum : Object.values(VerificationStatus), default : VerificationStatus.Started })
	status: VerificationStatus = VerificationStatus.Started;

	@ManyToOne('User', { onDelete : 'CASCADE' })
	user: User = undefined;

	@ManyToOne('BaseRole', { onDelete : 'CASCADE' })
	role: BaseRole = undefined;

	/**
	 * Rejection reasons if the verification was not approved.
	 */
	@Column({ type : 'simple-array' })
	reasons: string[] = [];

	@Column({ name : 'additionalData', type : 'json', default : () => "('{}')" })
	additionalDataValue: any;

	constructor(initialValues: Partial<Verification> = {}) {
		super();
		if (_.isNotEmpty(initialValues)) {
			this.mergeColumns(initialValues);
		}
	}

	/**
	 * The max number of attempts allowed for this verification type.
	 */
	static get maxAttempts() {
		return 2;
	}

	static async getAttempts(user: User) {
		return (await this.find({ where : { user } })).filter(result => result.status !== VerificationStatus.Started).length;
	}

	/**
	 * @returns true if the user has reached or exceeded the maximum number of attempts for this verification type
	 */
	static async maxAttemptsReached(user: User) {
		return (await this.getAttempts(user)) >= this.maxAttempts;
	}

	static getURL(suffix?: string): string { // eslint-disable-line @typescript-eslint/no-unused-vars
		throw new Errors.NotImplemented();
	}

}

export async function onlySelfOrSupportRead(context: Context, verification: Verification, query: SelectQueryBuilder<Verification>) {
	if (context.role.hasPermission(RolePermission.CrossOrgRead)) {
		return;
	}

	const userId = context.role.user.id;
	const roleId = context.role.id;

	if (query) {
		query.andWhere(`(${query.alias}.userId = :userId OR ${query.alias}.roleId = :roleId)`, { userId, roleId });
	}
	else {
		const verificationUserId = verification.user?.id || (verification as any).userId;
		const verificationRoleId = verification.role?.id || (verification as any).roleId;

		return verificationUserId !== userId && verificationRoleId !== roleId ? 'insufficient permissions' : '';
	}
}
