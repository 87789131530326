import Moment           from 'moment';
import pluralize        from 'pluralize';
import parsePhoneNumber from 'libphonenumber-js';
import { Country }      from '$/lib/Address';

const format = {
	/**
	 * Returns a date as a time-relative value with a human description.
	 * @example "4 minutes ago"
	 */
	relativeTime(possibleDate: Date | Moment.Moment): string {
		return format.isDate(possibleDate) ? Moment(possibleDate).fromNow() : '';
	},

	daysUntil(possibleDate: Date | Moment.Moment): string {
		if (!format.isDate(possibleDate)) {
			return 'Unknown';
		}
		possibleDate = Moment(possibleDate);
		if (!Moment().isBefore(possibleDate)) {
			return 'Right now';
		}
		const diff = possibleDate.diff(Moment(), 'days');
		return diff === 0 ? '< 1 day' : `${diff} ${pluralize('day', diff)}`;
	},

	isDate(possibleDate: Date | Moment.Moment | string): boolean {
		return Moment(possibleDate).isValid();
	},

	date(possibleDate: Date | Moment.Moment | string, format = 'Y-MM-DD'): string {
		return this.isDate(possibleDate) ? Moment(possibleDate).format(format) : '';
	},

	dateTime(possibleDate: Date | Moment.Moment | string) {
		return this.date(possibleDate, 'LLL');
	},

	startCase(text: string) {
		return _.startCase(text);
	},

	kebabCase(text: string) {
		return _.kebabCase(text);
	},

	/**
	 * @param {number} [maxLength=24] the length of the return string. Default is 24 which looks good on most screens
	 * @returns {string} the text provided truncate down to the maxLength provided using ellipsis in the middle
	 */
	middleEllipsis(text: string, maxLength = 24) {
		if (text && text.length > maxLength) {
			const split = maxLength / 2;
			text        = `${text.substring(0, split)}…${text.substring(text.length - split + 1)}`;
		}
		return text;
	},

	/**
	 * @param {Object}  [options]
	 * @param {String}  [options.locale]
	 * @param {String}  [options.currency] iso 4217, USD, CAD, etc
	 * @param {Boolean} [options.compact] ie 1,000 -> 1K, 12,000 -> 12K, 1M, etc
	 * @param {String}  [options.style] same as the style option in NumberFormat constructor
	 */
	currency(possibleNumber: any, { locale = 'en-CA', currency = 'CAD', compact = false, style = 'currency', maximumFractionDigits = 2 }: CurrencyOptions  = {}): string {
		const num = Number(possibleNumber);

		if (isNaN(num)) {
			return '';
		}

		const options = {
			currency,
			style,
			currencyDisplay : 'narrowSymbol',
			notation        : compact ? 'compact' : 'standard' as 'compact' | 'standard',
			compact         : 'short',
			maximumFractionDigits,
		};

		return Intl.NumberFormat(locale, options).format(num);
	},

	/**
	 * @param num the number to format
	 * @returns the number formatted with commas
	 */
	number(possibleNumber: any): string {
		const num = Number(possibleNumber);
		return isNaN(num) ? '' : Intl.NumberFormat().format(num);
	},

	/**
	 * @param {Object}  [options]
	 * @param {Number}  [options.fractionDigits] Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
	 */
	percentage(possibleNumber: number, { fractionDigits = 0 } = {}): string {
		const num = Number(possibleNumber);
		return isNaN(num) ? '' : `${num.toFixed(fractionDigits)}%`;
	},

	/**
	 * @param {string} possibleNumber
	 * @param {Country} country // Defaults to Canada
	 */
	phoneNumber(possibleNumber: string, country: Country = Country.CA) {
		return parsePhoneNumber(possibleNumber, country)?.formatNational() ?? possibleNumber;
	},

	pluralize,
};

interface CurrencyOptions {
	locale?: string;
	currency?: string;
	compact?: boolean;
	style?: 'decimal' | 'currency' | 'percent' | 'unit';
	maximumFractionDigits?: number;
}

export default format;
