import Axios                              from 'axios';
import { Entity }                         from '$/lib/typeormExt';
import { Answers, Questionnaire, Result } from '$/lib/equifax/KountClient';

import { KountVerification as KountVerificationCommon } from '$/common/entities/verifications/KountVerification';
export *                                                from '$/common/entities/verifications/KountVerification';

@Entity()
export class KountVerification extends KountVerificationCommon {

	static async getQuestions(): Promise<Questionnaire | Result> {
		return (await Axios.get(KountVerificationCommon.getURL())).data;
	}

	static async submitAnswers(answers: Answers) {
		return (await Axios.post(KountVerificationCommon.getURL(), answers)).data;
	}

}
