export enum CouponIds {
	AffiliateFreeCreditReport         = '08f10607-1391-4f46-b027-563500549b1b',
	AffiliateCreditDebt10Off          = 'e1f4ce11-74bd-4668-8d3d-1f7d36f34932',

	// coupon rewards for referring a friend
	EmailReferralCreditReport50Off    = 'f05bb54c-26e8-4270-9389-1e03e2cfd1dc',
	FacebookReferralCreditReport50Off = 'ae6b933b-7839-4002-941c-2f147187c98f',
}

export enum DiscountType {
	Percentage = 'percentage',
	Fixed      = 'fixed',
}
