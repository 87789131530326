import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFont from 'pdfmake/build/vfs_fonts';

export { TCreatedPdf } from 'pdfmake/build/pdfmake';

export type Margin = number | [number, number] | [number, number, number, number];

interface Field {
	key: string;
	label?: string;
	formatter?: (value) => string;
	width?: number | string;
}

interface TableOptionalParams {
	detailFields?: Field[];
	emptyText?: string;
	emptyFillColor?: string;
	rowFillColor?: string;
	color?: string;
}

interface ContainerOptions {
	title?: string;
	wrapper?: any;
	margin?: Margin;
	padding?: Margin;
	spacing?: number;
	otherOptions?: Record<string, unknown>;
}

export enum ContainerImagePosition {
	Left   = 'left',
	Right  = 'right',
	Top    = 'top',
	Bottom = 'bottom',
}

export const pdfColors = {
	line     : '#aeaeae',
	white    : '#fff',
	light    : '#f4f6f9',
	text     : '#696969',
	textDark : '#222',
	brand    : '#152951',
	red      : '#fdbab4',
	green    : '#cdede6',
};

let tables = [];

// @ts-ignore Documentation told me too
pdfMake.vfs = pdfFont.pdfMake.vfs;

// @ts-ignore Documentation told me too
pdfMake.tableLayouts = {
	outlinedBlock : {
		hLineWidth    : () => 0.5,
		vLineWidth    : () => 0.5,
		paddingLeft   : () => 0,
		paddingRight  : () => 0,
		paddingTop    : () => 0,
		paddingBottom : () => 0,
		hLineColor    : pdfColors.line,
		vLineColor    : pdfColors.line,
	},
	lightBlock : {
		hLineWidth    : () => 0.5,
		vLineWidth    : () => 0.5,
		paddingLeft   : () => 0,
		paddingRight  : () => 0,
		paddingTop    : () => 0,
		paddingBottom : () => 0,
		hLineColor    : pdfColors.light,
		vLineColor    : pdfColors.light,
		fillColor     : () => pdfColors.light,
	},
	default : {
		hLineWidth    : () => 0,
		vLineWidth    : () => 0,
		paddingLeft   : () => 8,
		paddingRight  : () => 8,
		paddingTop    : i => i ? 4 : 2,
		paddingBottom : i => i ? 4 : 2,
	},
	columnDivider : {
		hLineWidth   : () => 0,
		vLineWidth   : i => i === 0 ? 0 : 0.5,
		paddingLeft  : () => 6,
		paddingRight : () => 6,
		hLineColor   : pdfColors.line,
		vLineColor   : pdfColors.line,
	},
	detailTable : {
		hLineWidth    : () => 0,
		vLineWidth    : () => 0,
		paddingLeft   : () => 8,
		paddingRight  : () => 8,
		paddingTop    : (i, node) => detailTablePadding(i, node, true),
		paddingBottom : (i, node) => detailTablePadding(i, node, false),
		fillColor     : (i, node) =>  !i || isDetail(i, node) ? pdfColors.white : pdfColors.light,
	},
	lightTable : {
		hLineWidth    : () => 0,
		vLineWidth    : () => 0,
		paddingLeft   : () => 8,
		paddingRight  : () => 8,
		paddingTop    : i => i ? 4 : 8,
		paddingBottom : i => i ? 4 : 2,
		hLineColor    : pdfColors.light,
		vLineColor    : pdfColors.light,
		fillColor     : () => pdfColors.light,
	},
	lightTableWhiteHeader : {
		hLineWidth    : () => 0,
		vLineWidth    : () => 0,
		paddingLeft   : () => 8,
		paddingRight  : () => 8,
		paddingTop    : i => i ? 4 : 8,
		paddingBottom : i => i ? 4 : 2,
		hLineColor    : pdfColors.light,
		vLineColor    : pdfColors.light,
		fillColor     : i => i ? pdfColors.light : pdfColors.white,
	},
};

function isDetail(i, node) {
	return _.flatten(Object.values(node.table.detailRowsFor)).includes(i);
}

function detailTablePadding(i, node, forTop) {
	// Header row
	if (!i) {
		return 2;
	}

	// Non-detail rows
	if (!isDetail(i, node)) {
		return 4;
	}

	// Add extra padding above the first detail row and below the last detail row
	if ((forTop && !isDetail(i - 1, node)) || (!forTop && !isDetail(i + 1, node))) {
		return 4;
	}

	// Detail rows otherwise have 0 vertical padding
	return 0;
}

export function createPdf(documentDefinition) {
	tables = [];
	return pdfMake.createPdf(_.merge(documentDefinition, {
		styles : {
			pageHeader : {
				fontSize : 16,
				bold     : true,
			},
			pageFooter : {
				fontSize   : 10,
				alignment  : 'center',
				lineHeight : 1,
			},
			title : {
				fontSize : 12,
				bold     : true,
				color    : pdfColors.textDark,
			},
			subtitle : {
				fontSize : 10,
				bold     : true,
				color    : pdfColors.textDark,
			},
			bold : {
				bold  : true,
				color : pdfColors.textDark,
			},
			link : {
				bold  : true,
				color : pdfColors.brand,
			},
		},
		defaultStyle : {
			fontSize   : 8,
			color      : pdfColors.text,
			lineHeight : 1.2,
		},
	}));
}

export function table(items, fields: Field[], options?: TableOptionalParams) {
	options = Object.assign({
		emptyText : 'None Found',
	}, options);

	const widths    = fields.map(field => field.width || '*');
	const body: any = [ fields.map(field => ({ text : field.label || _.startCase(_.last(field.key.split('.'))), style : 'bold' })) ];


	// In a table with detail rows, associate the detail rows with the base row to make them easier to find
	const detailRowsFor = {};
	if (!items?.length) {
		const emptyRow = {
			text      : options.emptyText,
			colSpan   : fields.length,
			fillColor : options.emptyFillColor ?? options.rowFillColor,
			color     : options.color,
		};
		body.push([ emptyRow ]);
	}
	else {
		let rowNum = 1; // Start counting at 1 because the header row is index 0
		body.push(...items.flatMap(item => {
			const row = fields.map(field => {
				const { key, formatter } = field;
				const value              = _.get(item, key);
				return { text : formatter?.(value) ?? value ?? '', fillColor : options.rowFillColor, color : options.color };
			});
			const rows             = [ row ];
			const baseRow          = rowNum;
			detailRowsFor[baseRow] = [];
			rowNum++;

			if (options.detailFields) {
				for (const field of options.detailFields) {
					const detailRow = new Array(2).fill('');
					const svg       = {
						svg              : `<svg viewBox="0 0 16 16" fill="${pdfColors.text}"><path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"></path></svg>`,
						height           : 8,
						width            : 8,
						relativePosition : { x : -2 },
					};
					detailRow[0] = { columns : [ svg, { text : field.label || _.startCase(_.last(field.key.split('.'))) } ] };

					const { key, formatter } = field;
					const value              = _.get(item, key);
					detailRow[1]             = { text : formatter?.(value) ?? value ?? '', colSpan : fields.length - 1 };

					rows.push(detailRow);
					detailRowsFor[baseRow].push(rowNum);
					rowNum++;
				}
			}

			return rows;
		}));
	}

	return { widths, body, detailRowsFor };
}

export function pageBreakCheck(currentNode, followingNodesOnPage, nodesOnNextPage) {
	if (currentNode.table) {
		tables.push(currentNode.table);
	}

	if (getDetailRows(currentNode)?.some(x => nodesOnNextPage.includes(x))) {
		return true;
	}
}

function getDetailRows(node) {
	for (const table of tables) {
		if (!table.detailRowsFor) {
			continue;
		}

		for (let i = 0; i < table.body.length; i++) {
			const line = table.body[i];
			for (const cell of line) {
				if (cell.nodeInfo === node) {
					// Just grab the first cell of the row. If the first cell is on the next page, so are the others in the row.
					// Also grabbing the nodeInfo, since that's how nodes appear in nodesOnNextPage in the page break check.
					return table.detailRowsFor[i]?.map(index => table.body[index][0].nodeInfo);
				}
			}
		}
	}

	return null;
}

export function outlinedContent(content, margin: Margin = 0) {
	return {
		layout : 'outlinedBlock',
		table  : {
			widths : [ '*' ],
			body   : [ [ content ] ],
		},
		margin,
	};
}

export function lightContent(content, margin: Margin = 0) {
	return {
		layout : 'lightBlock',
		table  : {
			widths : [ '*' ],
			body   : [ [ content ] ],
		},
		margin,
	};
}

export const hr = {
	table : {
		widths : [ '*' ],
		body   : [ [ '' ], [ '' ] ],
	},
	layout : {
		hLineWidth : (i, n) => i === 0 || i === n.table.body.length ? 0 : 0.5,
		vLineWidth : () => 0,
		hLineColor : pdfColors.line,
		vLineColor : pdfColors.line,
	},
	margin : [ 8, 0 ],
};

export const pageBreak = {
	text      : '',
	pageBreak : 'after',
};

export function blank(size = 10) {
	return banner({
		text   : '',
		style  : 'pageFooter',
		margin : [ 0, size, 0, 0 ],
	}, { fillOpacity : 0 });
}

export function banner(content, colorOptions?: { color?: string; fillColor?: string; fillOpacity?: number }) {
	colorOptions = _.assign({
		color       : pdfColors.white,
		fillColor   : pdfColors.brand,
		fillOpacity : 1,
	}, colorOptions);

	return {
		layout : 'noBorders',
		table  : {
			widths : [ '*' ],
			body   : [ [ content ] ],
		},
		style : {
			color       : colorOptions.color,
			fillColor   : colorOptions.fillColor,
			fillOpacity : colorOptions.fillOpacity,
		},
	};
}

export function columns(...content) {
	return {
		columns : [ ...content ],
	};
}

export function stack(...content) {
	return {
		stack : [ ...content ],
	};
}

/**
	 * A container for content on the pdf.
	 * @param options.title Title of the container. Gets the subtitle style applied automatically. Can be omitted, defaults to none.
	 * @param options.wrapper Wrapper for the container's content (e.g. lightContent, outlinedContent). Defaults to none.
	 * @param options.margin Margins for the container. Defaults to 0.
	 * @param options.padding Padding for the container content. Left and right padding is not applied to tables. Defaults to 8.
	 * @param options.spacing Spacing between items. Defaults to 0.
	 * @param options.otherOptions Any other properties to set on the created container.
	 * @param content Can be strings, or any pdfmake objects.
	 * @returns The created container.
	 */
export function container(options: ContainerOptions, ...content) {
	// Defaults
	options = Object.assign({
		margin  : 0,
		padding : 8,
		spacing : 0,
		wrapper : contents => contents,
	}, options);

	let containerContents = [];

	// Add title if one is provided
	if (options.title) {
		containerContents.push({ text : options.title, style : 'subtitle' });
	}

	// Ensure content is in right format and apply margins
	content = content.map(textToObject);
	containerContents.push(...content);
	containerContents = containerContents.map(applyPaddingAndSpacing(options.padding, options.spacing));

	// Wrap the content, apply any other options, and return
	const finalContainer = options.wrapper({ stack : [ ...containerContents ] }, options.margin);
	return Object.assign(finalContainer, options.otherOptions);

	/**
	 * Converts string content items into a pdfmake object, with the string set as the text property.
	 * Doing this allows setting a margin on string content.
	 * @param item String to convert
	 * @returns An object containing the text property, with a value equal to the provided string
	 */
	function textToObject(item) {
		if (typeof item === 'string') {
			// Remove any newlines and tabs. Long strings split over multiple lines in the code will have these
			// For actual line breaks, keep each line as its own string/object with a text property
			item = item.replace(/(\n|\r|\t)/g, '');
			return { text : item };
		}

		return item;
	}

	function applyPaddingAndSpacing(padding, spacing) {
		return (item, index, array) => {
			// If there's only a single item, we don't need to do anything with spacing, so just set it's margins based on the padding
			if (array.length === 1) {
				item.margin = padding;
				return item;
			}

			// Turn the padding into the four number version, to make it easier to work with
			if (typeof padding === 'number') {
				padding = Array(4).fill(padding);
			}
			if (padding.length === 2) {
				padding = [ padding[0], padding[1], padding[0], padding[1] ];
			}

			// If the item is a table, don't add left or right padding - tables already have this
			if (item.table) {
				padding[0] = 0;
				padding[2] = 0;
			}

			// Top padding only applies to first item, bottom padding only to last item
			// Spacing only applied on the bottom of the item, not on both top and bottom otherwise we'd be applying it twice
			if (index === 0) {
				item.margin = [ padding[0], padding[1], padding[2], spacing ];
			}
			else if (index === array.length - 1) {
				item.margin = [ padding[0], 0, padding[2], padding[3] ];
			}
			else {
				item.margin = [ padding[0], 0, padding[2], spacing ];
			}

			return item;
		};
	}
}
