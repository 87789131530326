
















/**
 * Overwrite BFormSelect to override default button variant
 */
import { BButton as BButtonOriginal } from 'bootstrap-vue';
import { Vue, Component, Prop }       from '$/lib/vueExt';

@Component({ components : { BButtonOriginal } })
export default class Button extends Vue {

	@Prop({ default : 'outline-default' })
	readonly variant: string;

	@Prop({ default : '' })
	readonly icon: string;

	@Prop({ default : '' })
	readonly iconVariant: string;

	@Prop({ default : '1' })
	readonly iconFontScale: string;

}
