import Axios      from 'axios';
import { Entity } from '$/lib/typeormExt';

import { UrlVerification as UrlVerificationCommon } from '$/common/entities/verifications/UrlVerification';
export *                                            from '$/common/entities/verifications/UrlVerification';

@Entity()
export class UrlVerification extends UrlVerificationCommon {

	static async postUrl(url: string) {
		return (await Axios.post(UrlVerificationCommon.getURL(), { url })).data;
	}

}
