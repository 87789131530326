import { columns, container, hr, lightContent, outlinedContent, pdfColors, stack, table } from '$/lib/pdfmake';
import { TenantSearch }   from '$/entities/tenantScreening/TenantSearch';
import { pdfFormats }     from './TenantScreeningReport';
import { TenantCheckPdf } from './TenantCheckPdf';

import LCBSVG         from '$/assets/LLCB_logo.svg?raw';
import frontLobbyLogo from '$/assets/FrontLobby_logo_with_title.svg?raw';
import bannerForRR    from '$/assets/tenantScreening/paid-on-time.svg?raw';
import housesSVG      from '$/assets/tenantScreening/houses.svg?raw';
import greenCheckMark from '$/assets/icons/green-check-mark.svg?raw';
import checkMark      from '$/assets/icons/check-mark.svg?raw';
import infoSVG        from '$/assets/icons/info-blue.svg?raw';

const bulletPoints = [
	'Encourage on-time payments and reduce late rent.',
	'Help tenants build credit with every payment.',
	'Create a fairer rental process with added accountability.',
];

export class TenantRecordsPdf extends TenantCheckPdf {

	private record: TenantSearch;
	content: any[];

	constructor(record: TenantSearch) {
		super();
		this.record  = record;
		this.content = [
			this.sectionTitle('Tenant Records', {
				svg              : LCBSVG,
				width            : 82,
				alignment        : 'right',
				relativePosition : { x : 0, y : -6.5 },
			}),
			this.record.report.tenantRecords?.length
				? stack([ this.lcbTenantRecord, outlinedContent(this.leaseRecords, [ 0, 12, 0, 0 ]) ])
				: stack([ this.lcbTenantRecord, this.noMatchesFoundContent, this.frontLobbyPromo ]),
		];
	}

	get noMatchesFoundContent() {
		return stack([
			{
				...this.sectionTitle('No Matches Found'),
			},
			container(
				{
					wrapper : outlinedContent,
					padding : [ 12, 12, 24, 12 ],
				},
				stack([
					columns([
						{
							columns : [
								svgStyle(greenCheckMark),
								{
									text     : 'LCB does not have a Tenant Record that matches the information provided for this applicant.',
									fontSize : 10,
									margin   : [ 0, 4, 0, 0 ],
								},
							],
							columnGap : 8,
							margin    : [ 0, 0, 0, 10 ],
						},
						{
							columns : [
								svgStyle(infoSVG),
								{
									text : [
										'By reporting rent payment history to LCB through ',
										{
											text       : 'FrontLobby.com',
											link       : 'https://frontlobby.com',
											decoration : 'underline',
										},
										', you can help foster accountability and transparency in the rental market by creating a central database of verified Tenant Records.',
									],
									fontSize : 10,
									italics  : true,
									bold     : true,
									margin   : [ 0, 4, 0, 0 ],
								},
							],
							columnGap : 8,
						},
					]),
				])
			),
		]);
	}

	get lcbTenantRecord() {
		return container(
			{
				wrapper : outlinedContent,
				padding : 0,
			},
			columns(
				container(
					{
						padding : [ 12, 12, 24, 7 ],
					},
					stack([
						{
							text   : 'LCB Tenant Record',
							style  : 'subtitle',
							margin : [ 0, 0, 0, 8 ],
						},
						{
							text     : "A Tenant Record with Landlord Credit Bureau (LCB) is a detailed account of a Tenant's rental payment history that has been verified by their actual Housing Provider. The record can include on-time payments, missed payments, and any outstanding debts, along with the contact details of the verified Housing Provider that reported the data. By creating a Tenant Record, Housing Providers and Renters help foster accountability and transparency in the rental market.",
							fontSize : 10,
						},
					])
				),
				container(
					{
						wrapper      : lightContent,
						otherOptions : {
							width : 'auto',
						},
						padding : 12,
					},
					{
						svg   : housesSVG,
						width : 160,
					}
				)
			)
		);
	}

	get leaseRecords() {
		const records = [];
		for (let i = 0; i < this.record.report.tenantRecords.length; i++) {
			const tenantRecord = this.record.report.tenantRecords[i];
			records.push(
				// Would get a bit of weird spacing on bottom of outlined content without 0 bottom padding of the last record
				container({ title : 'Lease Details', padding : [ 8, 8, 8, 0 ] }, {
					layout : 'detailTable',
					table  : table([ tenantRecord ], [
						{ key : 'lease.monthlyRent', formatter : pdfFormats.formatterCurrency, width : 60 },
						{ key : 'lease.moveInDate', formatter : pdfFormats.formatDayMonthYear, width : 60 },
						{ key : 'lease.moveOutDate', formatter : pdfFormats.formatDayMonthYear, width : 60 },
						{ key : 'lease.street', formatter : pdfFormats.capitalize, width : 130 },
						{ key : 'lease.city', formatter : pdfFormats.capitalize },
						{ key : 'lease.region', formatter : pdfFormats.capitalize, width : 30 },
						{ key : 'lease.country', width : 30 },
					], { detailFields : [
						{ key : 'landlord.name', label : 'Landlord', formatter : pdfFormats.capitalize },
						{ key : 'landlord.email', label : 'Contact', formatter : pdfFormats.capitalize },
					] }),
				}, {
					layout : 'lightTable',
					table  : table(tenantRecord?.paymentHistory ?? [], [
						{ key : 'month', formatter : pdfFormats.formatMonthYear, width : 60 },
						{ key : 'balance', label : 'Balance', formatter : pdfFormats.formatterCurrency, width : 60 },
						{ key : 'status', label : 'Status That Month', formatter : _.upperCase },
					]),
				}),
				container({ title : 'Inquiries', padding : [ 8, 8, 8, i === this.record.report.tenantRecords.length - 1 ? 0 : 8 ] }, {
					layout : 'lightTable',
					table  : table(tenantRecord.inquiries ?? [], [
						{ key : 'date', formatter : pdfFormats.formatDayMonthYear, width : 60 },
						{ key : 'name', formatter : pdfFormats.capitalize },
					]),
				})
			);

			if (i !== this.record.report.tenantRecords.length - 1) {
				records.push(hr);
			}
		}

		return records;
	}

	get frontLobbyPromo() {
		return container(
			{
				wrapper : lightContent,
				margin  : [ 0, 12, 0, 0 ],
			},
			columns(
				container(
					{
						padding : [ 5, 4, 5, 6 ],
					},
					stack([
						svgStyle(frontLobbyLogo, 100, [ 0, 0, 0, 16 ]),
						{
							text : [
								{
									text : 'Make Rent Count. ',
									bold : true,
								},
								'Rent reporting creates accountability, builds credit, and supports a fairer rental market.',
							],
							fontSize : 10.5,
							color    : pdfColors.textDark,
							margin   : [ 0, 0, 0, 12 ],
						},
						{
							stack : bulletPoints.map((text, index) => ({
								columns : [
									svgStyle(checkMark),
									{
										text,
										fontSize : 9.5,
										color    : pdfColors.textDark,
										margin   : [ 0, 3, 0, 0 ],
									},
								],
								columnGap : 10,
								margin    : [ 0, 0, 0, index === _.findLastIndex(bulletPoints) ? 0 : 2 ],
							})),
						},
						{
							text : [
								'Start reporting rent payments today with ',
								{
									text       : 'FrontLobby.com',
									link       : 'https: //frontlobby.com',
									decoration : 'underline',
								},
							],
							fontSize : 10.5,
							color    : pdfColors.textDark,
							bold     : true,
							margin   : [ 0, 16, 0, 0 ],
						},
					])
				),
				container(
					{},
					{
						svg              : bannerForRR,
						width            : 150,
						relativePosition : { x : 90, y : 37.5 },
					}
				)
			)
		);
	}

}

function svgStyle(svg: string, width = 14, margin = [ 0, 2, 8, 0 ]) {
	return { svg, width, margin };
}
