import Axios  from 'axios';
import { In } from '$/lib/typeormExt';

import { Entity }                from '$/entities/BaseEntity';
import { User, VerificationDoc } from '$/entities/User';
import { File as FileEntity }    from '$/entities/File';
import { BaseRole }              from '$/entities/roles/BaseRole';

import { DocumentsVerification as DocumentsVerificationCommon } from '$/common/entities/verifications/DocumentsVerification';
export *                                                        from '$/common/entities/verifications/DocumentsVerification';

@Entity()
export class DocumentsVerification extends DocumentsVerificationCommon {

	static async upload(docs: VerificationDoc[], progressUpdate?: (uploadProgressPercentage: number) => void) {
		const formData = new FormData();
		const fileDocs = docs.filter(doc => doc.file.nativeFile);

		fileDocs.forEach(doc => {
			formData.append(doc.docType, doc.file.nativeFile, doc.file.nativeFile.name);
		});

		// determine if this is a role verification upload by checking the referenceType
		const baseEndPoint = fileDocs.some(doc => doc.referenceType.startsWith('role.')) ? 'role' : 'user';

		const uploadedFileIds = (await Axios.post(`/api/${baseEndPoint}/verification/docs`, formData, {
			onUploadProgress : progressEvent => {
				progressUpdate?.call(this, progressEvent.loaded / progressEvent.total);
			},
		})).data;

		// role & user verificationStatus fields get modified by the upload
		await Promise.all([ BaseRole.current.reload(), User.current.reload() ]);

		// return the new (possible modified) verification docs
		const files = _.isNotEmpty(uploadedFileIds) ? await FileEntity.find({ where : { id : In(uploadedFileIds) } }) : [];

		_.forEach(docs, doc => {
			const file = files.find(file => file.documentType === doc.docType);
			if (file) {
				doc.file = file;
			}
		});

		return docs;
	}

}
